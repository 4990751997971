import React, { useState, useEffect } from "react";
import "./GroupMaster.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";


function OfferPackage() {
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  console.log("urllink", urllink);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [hide, setHide] = useState(false);
  // const [selectedMethodId, setSelectedMethodId] = useState(null);
  const [selectedtest, setselectedtest] = useState([])
  console.log("selectedtest", selectedtest);
  const [editMode, setEditMode] = useState(false);
  // const [SelectedFile, setSelectedFile] = useState(null);
  const [groupandtestnames, setgroupandtestnames] = useState([]);
  const testdatas = useSelector((state) => state.userRecord?.OfferPackageData);
  console.log("testdatas", testdatas)
  const [openModal, setOpenModal] = useState(false);
  const [selectedrow, setselectedrow] = useState(null)
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const navigate = useNavigate()


  const [formData, setFormData] = useState({
    PackageName: "",
    PackageCode: "",
    displayName: "",
    gender: "",
    reportType: "",
    departmentName: "",
    testCategory: "",
    logicalCategory: "",
    authorizedUser: "",
    PackageCost: "",

  });
  console.log("formData", formData);



  // const [tableData, setTableData] = useState([]);

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const warnmessage = (wmsg) => {
    toast.warn(`${wmsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  // const errmsg = (errorMessage) => {
  //   toast.error(`${errorMessage}`, {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { marginTop: "50px" },
  //   });
  // };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };






  useEffect(() => {

    axios.get(`${urllink}usercontrol/test_and_group_forpackage`,{
      headers:{'tokenid':'7a5822b9-aaec-4f2c-a672-3e62d537683f',
          'password':'U*5EEA/jxPM/@|,$0B>"',
        },
    })
      .then((res) => {
        console.log(res)
        setgroupandtestnames(res.data)
      })
      .catch((err) => {
        console.error(err);

      })

  }, [urllink])


//   useEffect(() => {

//     axios.get(`${urllink}usercontrol/test_and_group_forpackage_forlocal`)
//       .then((res) => {
//         console.log(res)
//         setgroupandtestnames(res.data)
//       })
//       .catch((err) => {
//         console.error(err);

//       })

// },[urllink])


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      "PackageCode",
      "PackageName",
      "displayName",
      "reportType",
      "gender",
      "reportType",
      "departmentName",
      "testCategory",
      "logicalCategory",
      "authorizedUser",
      "PackageCost"

    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length === 0) {
      try {
        // Determine the endpoint based on editMode
        const endpoint = editMode
          ? `${urllink}usercontrol/update_offerpackage_data`
          : `${urllink}usercontrol/insert_offer_package`;

        // Make a POST request to the appropriate endpoint
        const response = await axios.post(endpoint, formData);

        // Handle the response as needed
        console.log("cgvh", response.data);
        successMsg('Inserted Successfully')
        // navigate('/Home/OfferPackageList')
        // setHide(true);

        // Reset the form data after submission
        setFormData({
          PackageName: "",
          PackageCode: "",
          displayName: "",
          gender: "",
          reportType: "",
          departmentName: "",
          testCategory: "",
          logicalCategory: "",
          authorizedUser: "",
          PackageCost: "",


        });

        console.log(selectedtest)
        if (!editMode) {
          await axios.post(
            `${urllink}usercontrol/insert_offerpackage_testlist`,
            selectedtest
          );

          successMsg('Updated Successfully')
          setselectedtest([])
          navigate('/Home/OfferPackageList')

        }

        // Reset the editMode state after submission
        setEditMode(false);
        setselectedtest([]);
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error scenarios
      }
    }
    else {
      userwarn(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };


  useEffect(() => {
    if (testdatas.length > 0) {
      console.log("testdatas", testdatas);

      setFormData((prevData) => ({
        ...prevData,
        PackageCode: testdatas[0].package_code,
        PackageName: testdatas[0].package_name,
        displayName: testdatas[0].display_name,
        gender: testdatas[0].gender,
        reportType: testdatas[0].report,
        departmentName: testdatas[0].department_name,
        testCategory: testdatas[0].test_category,
        logicalCategory: testdatas[0].logical_category,
        authorizedUser: testdatas[0].authorized_user,
        PackageCost: testdatas[0].Package_Cost,
        offer_package_id: testdatas[0].id
      }));
      const transformedData = testdatas.flatMap(packageData =>
        packageData.tests.map(test => ({
          PackageCode: packageData.package_code,
          PackageName: packageData.package_name,
          TestName: test.test_name,
          Test_Code: test.Test_Code,
          Test_Method: test.Test_Method,
          Test_Details: test.Test_Method === 'group' ? test.test_details : null
        }))
      );
      setselectedtest(transformedData);
      setIsEditMode(true)

    } 
  }, [testdatas, urllink]);

  useEffect(() => {
    if (testdatas.length === 0) {
      axios.get(`${urllink}usercontrol/get_package_code`)
        .then((res) => {
          console.log(res)
          setFormData({
            ...formData,
            PackageCode: res.data.nextoffer_package_id
          });
        })
        .catch((err) => {
          console.error(err);

        })
    }
  }, [testdatas,urllink])


  // const handleEdit = (row) => {
  //   setFormData({
  //     packageCode: row.package_code,
  //     PackageName: row.package_name,
  //     displayName: row.display_name,
  //     gender: row.gender,
  //     reportType: row.report,
  //     departmentName: row.department_name,
  //     testCategory: row.test_category,
  //     logicalCategory: row.logical_category,
  //     authorizedUser: row.authorized_user,
  //     packageCost: row.Package_cost
  //   });

  //   setIsEditMode(true);
  //   setSelectedMethodId(row.offer_package_id); // Assuming `group_master_id` is the identifier
  // };

  // const handleUpdateGroupMaster = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${urllink}usercontrol/update_offerpackage_data`,
  //       {
  //         offer_package_id: selectedMethodId,
  //         PackageCode: formData.PackageCode,
  //         PackageName: formData.PackageName,
  //         displayName: formData.displayName,

  //         gender: formData.gender,
  //         reportType: formData.reportType,
  //         departmentName: formData.departmentName,
  //         testCategory: formData.testCategory,
  //         logicalCategory: formData.logicalCategory,
  //         authorizedUser: formData.authorizedUser,

  //         packageCost: formData.PackageCost,
  //       }
  //     );
  //     console.log(response.data);

  //     setFormData({
  //       PackageName: "",
  //       PackageCode: "",
  //       displayName: "",
  //       gender: "",
  //       reportType: "",
  //       departmentName: "",
  //       testCategory: "",
  //       logicalCategory: "",
  //       authorizedUser: "",
  //       PackageCost: "",


  //     });
  //     setIsEditMode(false);
  //     setSelectedMethodId(null);
  //     // fetchGroupMasterData();
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };

  const handleInsertGroupMasterTestList = () => {
    console.log("kjhgfd", selecttest);

    if (formData.PackageCode !== '' && formData.PackageName !== '' && selecttest !== '') {
      const newvalues = {
        PackageName: formData.PackageName,
        PackageCode: formData.PackageCode,
        TestName: selecttest,
        Test_Code: selecttestcode,
        Test_Method: testmethod
      };

      // Check for duplicates
      const isDuplicate = selectedtest.some(test => test.Test_Code === selecttestcode);

      if (isDuplicate) {
        warnmessage('Duplicate entry: This Test is already added.');
      } else {
        console.log("newvalues", newvalues);
        setselectedtest(prev => [
          ...prev,
          newvalues
        ]);
        setselecttest('');
        setselecttestcode('');
      }
    } else {
      alert('Please Enter Required Values');
    }
  };


  // const handleCsvupload = (type) => {
  //   console.log(SelectedFile);
  //   const formData = new FormData();
  //   formData.append("file", SelectedFile);

  //   if (SelectedFile) {
  //     if (type === "Documents") {
  //       axios
  //         .post(
  //           `${urllink}usercontrol/post_groupmaster_csvfile`,
  //           formData,
  //           { headers: { "Content-Type": "multipart/form-data" } }
  //         )
  //         .then((response) => {
  //           console.log(response);
  //           successMsg("File Processed Successfully");

  //           setSelectedFile(null);
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           errmsg(error);
  //         });
  //     }
  //   }
  // };



  const handleDelete = (index) => {

    const updatedTests = [...selectedtest];


    updatedTests.splice(index, 1);


    setselectedtest(updatedTests);
  };





  const handleTestdata = (testData) => {
    const updatedata = {
      ...formData,
      selectedtest: selectedtest
    }
    console.log(updatedata)
    axios.post(`${urllink}usercontrol/update_offerpackage_data`, updatedata)
      .then((response) => {
        console.log(response.data)
        // setFormData({
        //   PackageCode: "",
        //   PackageName: "",
        //   displayName: "",

        //   gender: "",
        //   reportType: "",
        //   departmentName: "",
        //   testCategory: "",
        //   logicalCategory: "",
        //   authorizedUser: "",

        //   PackageCost: "",
        // });
      })

      .catch((error) => {
        console.log(error)
      })

  };

  const [selecttest, setselecttest] = useState('')
  const [selecttestcode, setselecttestcode] = useState('');

  const [testmethod, setTestMethod] = useState('');

  const handleTestNameChange = (e) => {
    const selectedTestName = e.target.value;
    setselecttest(selectedTestName);
    console.log(selectedTestName)


    const selectedTest = groupandtestnames?.find((item) => item.name === selectedTestName);
    if (selectedTest) {
      if (selectedTest.testmethod === 'Individual') {
        setselecttestcode(selectedTest.Test_Code || '');
        setTestMethod('individual');
      } else if (selectedTest.testmethod === 'Group') {
        setselecttestcode(selectedTest.group_code || '');
        setTestMethod('group');
      }
    } else {
      setselecttestcode('');
      setTestMethod('');
    }
  };


  const handleview = (params) => {
    console.log(params);

    const matchedGroup = groupandtestnames.find((item) => item.group_code === params.Test_Code);

    if (matchedGroup) {
      const selectedTests = matchedGroup.test_details.map(test => ({
        test_name: test.test_name,
        test_code: test.test_code,
      }));

      const updatedParams = {
        ...params,
        selectedTests,
      };

      setselectedrow(updatedParams);
    } else {
      setselectedrow(params);
    }

    setOpenModal(true);
  };



  return (
    <div className="appointment">
      <div className="h_head">
        <h4>OfferPackage</h4>
      </div>
      <div className="RegisFormcon">


        <div className="RegisForm_1">
          <label className="" htmlFor="PackageCode">
            Package Code<span>:</span>
          </label>
          <input
            type="text"
            id="PackageCode"
            name="PackageCode"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            disabled
            onChange={handleInputChange}
            value={formData.PackageCode}
          />
        </div>
        <div className="RegisForm_1">
          <label className="" htmlFor="PackageName">
            Package Name<span>:</span>
          </label>
          <input
            type="text"
            id="PackageName"
            name="PackageName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Group Name"
            required
            onChange={handleInputChange}
            value={formData.PackageName}
          />
        </div>


        <div className="RegisForm_1">
          <label htmlFor="displayName" className="">
            Display Name<span>:</span>
          </label>
          <input
            type="text"
            id="displayName"
            name="displayName"
            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"
            // className="new_clinic_form_inp111"
            // placeholder="Enter your Display Name"
            required
            onChange={handleInputChange}
            value={formData.displayName}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="gender" >
            Gender<span>:</span>
          </label>
          <select
            id="gender"
            name="gender"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.gender}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Both">Both</option>
          </select>
        </div>

        {/* <div><dddd/div> */}

        <div className="RegisForm_1">
          <label htmlFor="report" >
            Report<span>:</span>
          </label>
          <select
            id="reportType"
            name="reportType"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.reportType}
          >
            <option value="">Select</option>
            <option value="Cloud-Based">Cloud-Based</option>
            <option value="HardCopy">Hard Copy</option>

          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="departmentName" className="">
            Department Name<span>:</span>
          </label>
          <select
            id="departmentName"
            name="departmentName"
            required
            // className="new_clinic_form_inp111"
            onChange={handleInputChange}
            value={formData.departmentName}
          >
            <option value="">Select</option>
            <option value="Lab">Lab</option>
            <option value="Imaging">Imaging</option>
            <option value="UltraSound">UltraSound</option>
            <option value="Xray">X-Ray</option>
            <option value="Bone">Bone</option>
            <option value="Blood">Blood</option>
          </select>
        </div>




        <div className="RegisForm_1">
          <label htmlFor="testcategory" className="">
            Test Category<span>:</span>
          </label>

          <select
            id="testCategory"
            name="testCategory"

            onChange={handleInputChange}
            value={formData.testCategory}
          >
            <option value="">Select</option>
            <option value="Special">Special</option>
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="logicalcategory" className="">
            Logical Category<span>:</span>
          </label>

          <select
            id="logicalCategory"
            name="logicalCategory"

            onChange={handleInputChange}
            value={formData.logicalCategory}
          >
            <option value="">Select</option>
            <option value="Numeric">Numeric</option>
            <option value="AlphaNumeric">Alpha Numeric</option>
            <option value="Symbol">Symbol</option>
          </select>
        </div>


        <div className="RegisForm_1">
          <label htmlFor="autoauthorizeduser" className="">
            Auto Authorized User<span>:</span>
          </label>

          <input
            type="text"
            id="authorizedUser"
            name="authorizedUser"

            pattern="[A-Za-z ]+"
            title="Only letters and spaces are allowed"

            required
            onChange={handleInputChange}
            value={formData.authorizedUser}
          />
        </div>



        {/* testname */}
        {/* {!isEditMode && ( */}
        <>
          <div className="RegisForm_1">
            <label htmlFor="groupcost" className="">
              Package Cost<span>:</span>
            </label>

            <input
              type="number"
              id="PackageCost"
              name="PackageCost"
              // className="new_clinic_form_inp111"
              pattern="[A-Za-z ]+"
              title="Only numbers and spaces are allowed"
              // placeholder="Enter your Group Cost"
              required
              onChange={handleInputChange}
              value={formData.PackageCost}
            />
          </div>


          <div className="RegisForm_1">
            <label htmlFor="testname">
              Test Name <span>:</span>
            </label>
            <input
              id="testname"
              name="testname"
              list="browsers1"
              onChange={handleTestNameChange}
              value={selecttest}
              required
            />
            <datalist id="browsers1">
              {groupandtestnames?.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.Test_Code ? item.testmethod === 'Individual' : item.group_code}
                </option>
              ))}
            </datalist>
          </div>
          <div className="RegisForm_1">
            <label>
              {testmethod === 'group' ? 'Group Code' : 'Test Code'} <span>:</span>
            </label>
            <input
              type="text"
              id={testmethod === 'group' ? 'groupcode' : 'TestCode'}
              name={testmethod === 'group' ? 'groupcode' : 'TestCode'}
              onChange={(e) => setselecttestcode(e.target.value)}
              value={selecttestcode}
              required
            />
          </div>

        </>
        {/* )} */}

      </div>



      <div className="Register_btn_con">
        <button
          className="btn_1"
          onClick={handleInsertGroupMasterTestList}
        // onClick={handleSubmit}
        >
          <AddIcon />
        </button>
      </div>


      <div>

        {/* <h4>Table</h4> */}

        {/* Inside your render function */}

        <div className="Selected-table-container ">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Package Code</th>
                <th>Package Name</th>
                <th>Test Name</th>
                <th>Test Code</th>
                <th>Delete</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {console.log(selectedtest)}
              {selectedtest.map((row, index) => (

                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.PackageCode}</td>
                  <td>{row.PackageName}</td>
                  <td>{row.TestName}</td>
                  <td>{row.Test_Code}</td>
                  <td>
                    <button onClick={() => handleDelete(index)}>

                      <DeleteIcon />
                    </button>
                  </td>
                  {row.Test_Method === 'group' ? (
                    <td>
                      <button onClick={() => handleview(row)}>

                        <VisibilityIcon />
                      </button>
                    </td>
                  ) : <td>None</td>}

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="Register_btn_con">

        <button className="RegisterForm_1_btns" onClick={isEditMode ? handleTestdata : handleSubmit}>
          {isEditMode ? 'Update' : 'Submit'}
        </button>

      </div>

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwPopupforreason uwagduaguleaveauto foradvanceview" onClick={(e) => e.stopPropagation()}>
            <div className='appointment'>
              <div className='h_head'>
                <h4>Test Details</h4>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Sl.No</th>
                      <th id="slectbill_ins">Test Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedrow?.selectedTests?.map((test, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{test.test_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={() => setOpenModal(false)}>
                  Close
                </button>
              </div>
            </div>

          </div>
        </div>
      )}
      <ToastContainer />

    </div>
  );
}

export default OfferPackage;
