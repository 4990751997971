import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaPrint } from 'react-icons/fa';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from 'react-modal';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const PatientRequestforLab = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [searchQuery3, setSearchQuery3] = useState('');
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const userRecord = useSelector(state => state.userRecord?.UserData);
  const pageSize = 10;
  const totalPages = Math.ceil(filteredRows.length / pageSize);
  const showdown = filteredRows.length;
  const dispatchvalue = useDispatch()
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const yourStyles = {
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  };

  useEffect(() => {
    axios.get(`${urllink}Billing/getallpatientrequest`)
      .then(response => {
        console.log(response)
        const data = response.data.map((row, index) => ({
          id: index, // Assuming you want to use Patient_Id as id
          ...row,
        }));
        data.sort((a, b) => {
          const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ''), 10);
          const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ''), 10);
          return invoiceB - invoiceA;
        });
        setsumma(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [urllink]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const lowerCaseBarcodeQuery = searchQuery2.toLowerCase();
    const lowerCaseinvQuery = searchQuery3.toLowerCase();


    // Filter data based on all search queries
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name ? row.Patient_Name.toLowerCase() : '';
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : '';
      const lowerCaseBarcode = row.Barcode ? row.Barcode.toLowerCase() : '';
      const lowerCaseinvoiceno = row.Billing_Invoice ? row.Billing_Invoice.toLowerCase() : '';

      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery) &&
        lowerCaseBarcode.includes(lowerCaseBarcodeQuery) &&
        lowerCaseinvoiceno.includes(lowerCaseinvQuery)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, searchQuery2, searchQuery3, summa]);



  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === 'name') {
      setSearchQuery(value);
    } else if (type === 'phone') {
      setSearchQuery1(value);
    } else if (type === 'barcode') {
      setSearchQuery2(value)
    } else if (type === 'invoice') {
      setSearchQuery3(value)
    }
  };

  const dynamicColumns = [
    { field: 'UpdatedAt', headerName: 'Date', width: 100 },
    { field: 'Barcode', headerName: 'Sample Barcode', width: 150 },
    { field: 'Billing_Invoice', headerName: 'Invoice', width: 100 },
    { field: 'Patient_Id', headerName: 'Patient Id', width: 80 },
    { field: 'Visit_Id', headerName: 'Visit Id', width: 80 },
    { field: 'Patient_Name', headerName: 'Patient Name', width: 130 },
    { field: 'Age', headerName: 'Age', width: 60 },
    { field: 'Gender', headerName: 'Gender', width: 80 },
    { field: 'City', headerName: 'Address', width: 100 },
    { field: 'Phone', headerName: 'Phone', width: 110 },
    { field: 'Patient_Group', headerName: 'Patient Group', width: 100 },
    { field: 'Refering_Doctor', headerName: 'Reference Doctor', width: 120 },
    { field: 'Test_Names', headerName: 'Test Names', width: 150 },
    {
      field: 'actions',
      headerName: 'Billing Reprint',
      width: 120,
      renderCell: (params) => (
        <Button className='cell_btn' style={{ height: '35px' }} onClick={() => handleReprint(params.row)}>
          <FaPrint />
        </Button>
      ),
    },
    {
      field: 'Action',
      headerName: 'Barcode Reprint',
      width: 120,
      renderCell: (params) => (
        <Button className='cell_btn' style={{ height: '35px' }} onClick={() => handleBarcodeReprint(params.row)}>
          <FaPrint />
        </Button>
      ),
    },
  ];

  const reprintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => reprintRef.current,
    onBeforePrint: () => {
      console.log('Before');
    },
    onAfterPrint: () => {
      console.log('After');
    }
  });

  const handleReprint = (params) => {
    console.log(params);

    axios
      .get(`${urllink}Billing/get_billing_receipts_rebilling_print?invoice=${params.Billing_Invoice}&location=${userRecord?.location}`)
      .then(response => {
        console.log(response);
        let data = `data:application/pdf;base64,${response.data.receipt}`;
        setModalContent(data);
        setModalIsOpen(true);
      })
      .catch(error => {
        console.log(error);
      });

  };

  const handleBarcodeReprint = (params) => {
    console.log(params)
    dispatchvalue({ type: 'PrintBarcode', value: params })
    //  setsave(!save)
    navigate('/Home/BarcodePrint')
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h3>Patient Request List</h3>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Patient Name :</label>
            <input type="text" value={searchQuery} onChange={(e) => handleSearchChange(e, 'name')} placeholder='Enter the Patient Name' />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone :</label>
            <input type="text" value={searchQuery1} onChange={(e) => handleSearchChange(e, 'phone')} placeholder='Enter the Phone Number' />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Barcode</label>
            <input
                id="input"
                type="text"
                value={searchQuery2}
                onChange={(e) => handleSearchChange(e, 'barcode')}
                placeholder='Enter Barcode Number'
            />
        </div>
        <div className="inp_1">
            <label htmlFor="input">Invoice No</label>
            <input
                id="input"
                type="text"
                value={searchQuery3}
                onChange={(e) => handleSearchChange(e, 'invoice')}
                placeholder='Enter Invoice Number'
            />
        </div>
        </div>
        <div className='grid_1'>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns}
              pageSize={pageSize}
              pageSizeOptions={[pageSize]}
              onPageChange={(newPage) => setPage(newPage)}
              hideFooterPagination
              hideFooterSelectedRowCount
              className='data_grid'
            />
            {showdown > 0 && filteredRows.length > pageSize && (
              <div className='grid_foot'>
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </ThemeProvider>
          {filteredRows.length === 0 && (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
        </div>

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { ...yourStyles } }}>
          <div className="pdf_img_show" ref={reprintRef}>
            {modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
              <>
                <iframe
                  title="PDF Viewer"
                  src={modalContent}
                  style={{
                    width: "100%",
                    height: "435px",
                    border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                  }}
                />
                <div className='background_text_for_reprint'>
                  <h3 className='text_forprint'>
                    REPRINTED
                  </h3>
                </div>
              </>
            ) : (
              <img
                src={modalContent}
                alt="Concern Form"
                style={{
                  width: "80%",
                  height: "75%",
                  marginTop: "20px",
                }}
              />
            )}
            <div className="jhuhhjh">
              <Button
                style={{ color: "white" }}
                className="clse_pdf_img"
                onClick={closeModal}
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "40px",
                    backgroundColor: "#54d854bf",
                    borderRadius: "40px",
                  }}
                />
              </Button>
            </div>
            <div className="jhuhhjh">
              <Button
                style={{ color: "white" }}
                className="clse_pdf_img"
                onClick={handlePrint}
              >
                <FaPrint
                  style={{
                    fontSize: "30px",
                    backgroundColor: "#54d854bf",
                    borderRadius: "40px",
                  }}
                />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PatientRequestforLab;
