import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUser, FaLock, FaEyeSlash } from "react-icons/fa";
import { FaEye, FaLocationDot } from "react-icons/fa6";
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import bgImg2 from "../assets/bg.lab.jpg";
import "../LoginPage/LoginPage.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";
import ConfirmationModal from "./Confirmationmodal";
import Email from "@mui/icons-material/Email";


const LoginPage = () => {
  const [ClinicDetails, setClinicDetails] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const [onemail, setOnemail] = useState("");
  const [locationoptions, setlocationOptions] = useState([]);
  const [type, setType] = useState("Employee");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [userid, setuserid] = useState('')
  // State for Form Display
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // const [otpTimestamp, setOtpTimestamp] = useState(null);
  const [receivedOtp, setReceivedOtp] = useState('');
  const [loadingOtp, setLoadingOtp] = useState(false); // Loader state

  const handleUsernameChange = (event) => {
    const value = event.target.value.trim();
    setUsername(value);
  };




  const handlePasswordChange = (event) => {
    const value = event.target.value.trim();
    setPassword(value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleTogglePassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handlelocationchange = (event) => {
    const value = event.target.value.trim();
    setLocation(value);
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          setClinicDetails(firstClinic);
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, [urllink]);

  useEffect(() => {
    if (username !== "") {
      let timer = setTimeout(() => {
        axios
          .get(
            `${urllink}usercontrol/getlocationdataforlogin?username=${username}`
          )
          .then((response) => {
            const data = response.data;
            if (data) {
              setlocationOptions(data);
            }
          })
          .catch((error) => {
            console.error("Error fetching Location options:", error);
          });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [username, urllink]);

  const handleLoginFormSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${urllink}loginpage/login`, {
        username,
        password,
        location: type !== "Employee" ? null : location,
      })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          successMsg("Login Successful");
          localStorage.setItem("token", data.token);
          const userRecord = localStorage.getItem("token");
          const decodedToken = (token) => {
            const payloadBase64 = token.split(".")[1];
            const decodedPayload = atob(payloadBase64);
            return JSON.parse(decodedPayload);
          };
          const decodedTokenData = decodedToken(userRecord);
          if (decodedTokenData.Patient_id) {
            setTimeout(() => {
              navigate("/Home/PatientView");
            }, 1000);
          } else {
            setTimeout(() => {
              navigate("/Home/ClinicMetrics");
            }, 1000);
          }
        } else {
          userwarn("Incorrect Password or Username.");
        }
      })
      .catch((error) => {
        console.error("Error during login:", error);
        errmsg("An error occurred during login");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    setUsername("");
    setPassword("");
    setLocation("");
  }, [type]);

  // Forgot Password Handlers
  const handleForgotPasswordClick = () => {
    setShowConfirmationModal(true);
  };

  const handleConfirm = () => {
    setIsForgotPassword(true);
    setShowConfirmationModal(false);
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleBackToLoginClick = () => {
    setIsForgotPassword(false);
  };

  const handleGetOtp = (event) => {
    event.preventDefault();
    setLoadingOtp(true); // Start loader
    axios.post(`${urllink}loginpage/get_otp?username=${username}&onemail=${onemail}`)
      .then(response => {
        console.log(response.data)
        setReceivedOtp(response.data.otp)
        setuserid(response.data.userid)
        toast.success(response.data.message);
        setLoadingOtp(false); // Stop loader
        if (response.data.message) {
          setOtpSent(true)
        }
      })
      .catch(error => {
        toast.error('Failed to send OTP. Please try again.');
        setLoadingOtp(false); // Stop loader
      });
  };




  const handleForgotPasswordSubmit = (event) => {

    if (otp !== receivedOtp) {
      userwarn('Enter Correct OTP')
    } else if (newPassword !== confirmPassword) {
      userwarn('Password Mismatch')
      setConfirmPassword('')
    } else {
      event.preventDefault();

      const datatosend = new FormData()

      datatosend.append('username', username)
      datatosend.append('newpassword', newPassword)
      datatosend.append('userid', userid)

      axios.post(`${urllink}loginpage/update_new_password`, datatosend)
        .then(response => {
          toast.success(response.data.message);
          // Reset fields after successful password reset
          setUsername('');
          setOnemail('');
          setOtp('');
          setNewPassword('');
          setConfirmPassword('');

          if (response.data.message) {
            setIsForgotPassword(false)
          }



        })
        .catch(error => {
          toast.error('Failed to reset password. Please try again.');
        });
    }


  };


  return (
    <section className="register-container">
      <div className="register">
        <div className="col-1">
          <img src={bgImg2} alt="" />
        </div>
        <div className="col-2">
          {!isForgotPassword ? (
            <form className="flex-col" onSubmit={handleLoginFormSubmit}>
              <div className="login-logo">
                {ClinicDetails.clinicLogo && (
                  <img
                    src={`data:image/*;base64,${ClinicDetails.clinicLogo}`}
                    alt={ClinicDetails.clinicName}
                    className="rounded-logo-img"
                  />
                )}
              </div>

              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton
                  value="Employee"
                  style={{
                    height: "30px !important",
                    width: "130px !important",
                    backgroundColor:
                      type === "Employee"
                        ? "var(--selectbackgroundcolor)"
                        : "inherit",
                  }}
                  className="togglebutton_container_login"
                >
                  Employee
                </ToggleButton>
                <ToggleButton
                  value="Patient"
                  style={{
                    backgroundColor:
                      type === "Patient"
                        ? "var(--selectbackgroundcolor)"
                        : "inherit",
                    height: "30px !important",
                    width: "130px !important",
                  }}
                  className="togglebutton_container_login"
                >
                  Patient
                </ToggleButton>
              </ToggleButtonGroup>

              <div className="input-group">
                <FaUser className="input-icon" />
                <input
                  type="text"
                  id="username"
                  name="username"
                  onChange={handleUsernameChange}
                  placeholder="Enter your username"
                  required
                  autoFocus
                  value={username}
                />
              </div>

              <div className="input-group">
                <FaLock className="input-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="passd321"
                  placeholder="Enter your Password"
                  required
                />
                <span
                  className="toggle-password"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>

              {type !== "Employee" ? null : (
                <div className="input-group">
                  <FaLocationDot className="input-icon loctn-big" />
                  <select
                    id="location"
                    name="location"
                    onChange={handlelocationchange}
                    required
                  >
                    <option value={""}>Select</option>
                    {locationoptions.map((loc) => (
                      <option key={loc.location_id} value={loc.location_name}>
                        {loc.location_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {type === "Employee" && (
                <div className="loginrr">
                  <h5 onClick={handleForgotPasswordClick}>Forget Password</h5>
                </div>
              )}

              <button type="submit" className="login-btn">
                Login
              </button>
            </form>
          ) : (
            <form className="flex-col" onSubmit={otpSent ? handleForgotPasswordSubmit : handleGetOtp}>
              <h2>Forget Password</h2>
              {!otpSent && (
                <>
                  <div className="input-group">
                    <FaUser className="input-icon" />
                    <input
                      type="text"
                      id="username"
                      name="username"
                      onChange={handleUsernameChange}
                      placeholder="Enter your username"
                      required
                      autoFocus
                      value={username}
                    />
                  </div>
                  <div className="input-group">
                    <Email className="input-icon" />
                    <input
                      type="email"
                      id="onemail"
                      name="onemail"
                      onChange={(e) => setOnemail(e.target.value)}
                      placeholder="Enter your email"
                      value={onemail}
                      required
                    />
                  </div>
                </>
              )}
              {otpSent && (
                <>
                  <div className="input-group">
                    <MobileFriendlyIcon className="input-icon" />
                    <input
                      type="number"
                      id="otp"
                      name="otp"
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      required
                      value={otp}
                    />
                  </div>
                  <div className="input-group">
                    <FaLock className="input-icon" />
                    <input
                      type={showPassword2 ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="passd321"
                      placeholder="Enter new password"
                      required
                      value={newPassword}
                    />
                    <span
                      className="toggle-password"
                      onClick={handleTogglePassword2}
                    >
                      {showPassword2 ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  <div className="input-group">
                    <FaLock className="input-icon" />
                    <input
                      type={showPassword3 ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      className="passd321"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm new password"
                      required
                      value={confirmPassword}
                    />
                    <span
                      className="toggle-password"
                      onClick={handleTogglePassword3}
                    >
                      {showPassword3 ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </>
              )}
              {!otpSent && (
                <button type="submit" className="login-btn" disabled={loadingOtp}>
                  {loadingOtp ? "Sending OTP..." : "Get OTP"}
                </button>
              )}
              {otpSent && (
                <>
                  <button type="submit" className="login-btn">
                    Save
                  </button>
                </>
              )}
              <div className="loginll">
                <h5 onClick={handleBackToLoginClick}>Back to Login</h5>
              </div>
            </form>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          message="Do you want to continue?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <ToastContainer />
    </section>
  );
};

export default LoginPage;