import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
// import { Summarize } from "@mui/icons-material";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

const DueBillinglab = () => {
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const userRecord = useSelector(state => state.userRecord?.UserData);

  const [doctorname, setdoctorname] = useState('');
  const [Billing_date, setBilling_date] = useState(new Date());
  const formattedDate = format(Billing_date, "yyyy-MM-dd");
  const [Expenses_Invoice_No, setExpenses_Invoice_No] = useState(null);
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [Expense_Amount, setExpense_Amount] = useState("");
  const [printshow, setPrintshow] = useState(false);
  const [labname, setLabname] = useState([]);
  const [fromdate, setfromdate] = useState('');
  const [todate, settodate] = useState('');
  const [labcode,  setlabcode] = useState('')
  const [sumalist , setsummalist] = useState([])

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/get_for_outsource_lab_name`)
      .then(response => {
        setLabname(response.data);
      })
      .catch(error => console.error(error));

    
  }, [urllink]);


  useEffect(()=>{
    axios
    .get(`${urllink}Billing/get_client_data_due?labcode=${doctorname}`)
    .then(response => {
      console.log(response);

      setsummalist(response.data)
      
    })
    .catch(error => console.error(error));
    let labcode = labname.filter((p)=> p.Lab_Name === doctorname).map((item)=>(
      item.Labcode
    ))[0]
    console.log('labcode', labcode);
    
    setlabcode(labcode)
  },[doctorname])


  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  useEffect(() => {
    if (doctorname) {
      const time = setTimeout(() => {
        axios.get(`${urllink}Billing/get_transaction_id_for_hospital?doctorname=${doctorname}`)
          .then(response => setExpenses_Invoice_No(response.data.transaction_id))
          .catch(error => console.error(error));
      }, 200);
      return () => clearTimeout(time);
    }
  }, [doctorname, urllink]);

  useEffect(() => {
    if (doctorname && fromdate && todate) {
      const time = setTimeout(() => {
        axios.get(`${urllink}Billing/get_for_refering_clinic_amount?doctorname=${doctorname}&fromdate=${fromdate}&todate=${todate}`)
          .then(response => setExpense_Amount(parseFloat(response.data.TotalAmount)))
          .catch(error => console.error(error));
      }, 200);
      return () => clearTimeout(time);
    }
  }, [doctorname, fromdate, todate, urllink]);

  useEffect(() => {
    axios.get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)
      .then(response => {
        console.log(response, " oiekokeiijiijfjhdjdjkhjjndkkhddkjdjnn");
        
        const data = response.data[0];
        console.log('data',data);
        
        if (data) {
          setClinicDetials(prev => ({
            ...prev,
            ClinicAddress: `${data.doorNo}, ${data.area}, ${data.street}`,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch(error => console.error(error));

    axios.get(`${urllink}usercontrol/getAccountsetting`)
      .then(response => {
        const data = response.data;
        if (data) {
          setClinicDetials(prev => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch(error => console.error(error));
  }, [urllink, userRecord]);

  const handleSubmit = () => {
    // Logic for data posting can be added here if needed

    if(doctorname !== '' && fromdate !=='' && todate !== ''){
      setPrintshow(!printshow);
      setTimeout(() => {
        handlePrint();
      }, 900);
    }else{
      alert("Please Give Required Values....")
    }
  };
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onAfterPrint: async () => {
      const printdata = document.getElementById("reactprintcontent");
      try {
        if (printdata) {
          const pdf = new jsPDF({ unit: "px", format: "a4" });
          
          await pdf.html(printdata, {
            callback: async (pdfInstance) => {
              try {
                const generatedPdfBlob = pdfInstance.output("blob");
                const formData = new FormData();
                formData.append("file", generatedPdfBlob);
                formData.append("transactionid", Expenses_Invoice_No);
                formData.append("doctorname", doctorname);
                formData.append("Billing_date", formattedDate);
                formData.append("fromdate", fromdate);
                formData.append("todate", todate);
                formData.append("paidamount", Expense_Amount);
                formData.append("location", userRecord?.location);
                formData.append("Paidby", userRecord?.username);
                formData.append("labcode", labcode);
  
                await axios.post(`${urllink}/Billing/insert_into_table_refering_hospital`, formData, {
                  headers: { "Content-Type": "multipart/form-data" },

                }
              ).then((response)=>{
                setPrintshow(!printshow)
                setdoctorname('')
                setBilling_date('')
                setExpenses_Invoice_No('')
                setExpense_Amount('')
                setfromdate('')
                settodate('')
                setlabcode('')
              }).catch((error)=>{
                console.log(error);
                
              })
                console.log("Data sent successfully!");
              } catch (axiosError) {
                console.error("Error in axios post request:", axiosError);
              }
            },
            x: 10,
            y: 10,
            width: 190, // A4 width minus margins
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });
  

  const forPrintData = () => (
    <div className="billing-invoice" id="reactprintcontent">
      <div className="New_billlling_invoice_head">
        <div className="new_billing_logo_con">
          <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
        </div>
        <div className="new_billing_address_1">
          <span>{ClinicDetials.ClinicName}</span>
          <div>{ClinicDetials.ClinicAddress}, {ClinicDetials.ClinicCity}, {ClinicDetials.ClinicState}, {ClinicDetials.ClinicCode}</div>
          <div>
            {ClinicDetials.ClinicMobileNo}, {ClinicDetials.ClinicLandLineNo}, {ClinicDetials.ClinicMailID}
          </div>
        </div>
      </div>
      <h2 style={{ display: "flex", justifyContent: "center", fontWeight: 600, padding: "10px" }}>
       Invoice
      </h2>
      <div className="new_billing_address gapgagag">
          <div className="profomainvoice">
            <span>To :</span>
            {sumalist.map((item)=> (
              <div>
                <h4>{item.Lab_Name}</h4>
                <div>
                  {item.Address},{item.Pincode}
                </div>
                <div> 
                  Phone :{item.Phone_No}
                </div>
                <div>Email : {item.Email}</div>
              </div>
            ))}
          </div>
           
           <hr style={{height:'130px'}}></hr>

          <div className="profomainvoice">
            <span>From :</span>
            <h4>{ClinicDetials.ClinicName}</h4>
            <div>{ClinicDetials.ClinicAddress}, {ClinicDetials.ClinicCity}, {ClinicDetials.ClinicState}, {ClinicDetials.ClinicCode}</div>
            <div>Phone : {ClinicDetials.ClinicMobileNo}</div>
            <div>Email : {ClinicDetials.ClinicMailID}</div>
          </div>
        </div>

        <div className="Selected-table-container">
        <table className="selected-medicine-table2">
        <thead>
              <tr>
                <th>S No</th>
                <th>Service Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Investication ({fromdate} to {todate})</td>
                <td>{Expense_Amount}</td>
              </tr>
             
            </tbody>
            <tfoot>
              <tr>
                <td style={{textAlign: 'end'}} colSpan={2}>Total</td>
                <td>{Expense_Amount}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        

          <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
         
          <br />
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  
                }}
              >
                <label>
                  {" "}
                  Amount In Words<span>:</span>
                </label>
                <span style={{ color: "grey", width:'22rem'}}>
                  {numberToWords(Expense_Amount)}{" "}
                </span>
              </div>
             
            </div>
          </div>
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div className="bill_body jkued7">
              <label>
                  {" "}
                  Bill Status<span>:</span>
                </label>
                <span style={{ color: "grey" }}>
                 Unpaid
                </span>
              </div>
             
            </div>
          </div>

          <div className="signature-section909">
            <p className="disclaimer23">
              This page is created automatically without a signature.
            </p>
          </div>
        </div>
     
     
    </div>
  );

  return (
    <div className="appointment">
      {printshow ? forPrintData() : (
        <>
         <div className="h_head">
      <h4>Due Invoice Generate</h4>
    </div>

    <div className="RegisFormcon">
      <div className="RegisForm_1">
        <label htmlFor="Doctor_Name">
          {" "}
          Client Name: <span>:</span>
        </label>
        <input        
          type="text"
          name="Doctor_Name"
          list="concernnamelist"
          value={doctorname}
          onChange={(e)=>{setdoctorname(e.target.value)}}
        //   readOnly
        />
        <datalist id="concernnamelist">
                            {labname?.map((item, index) => (
                              <option key={index} value={item.Lab_Name}>
                                {item.Lab_Name}
                              </option>
                            ))}
                          </datalist>
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          Transaction No: <span>:</span>
        </label>
        <input name="Transaction_No" readOnly value={Expenses_Invoice_No} />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          Transaction No: <span>:</span>
        </label>
        <input name="Transaction_No" readOnly value={Expenses_Invoice_No} />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          From Date: <span>:</span>
        </label>
        <input
          type="Date"
          name="Billing_date"
          value={fromdate}
          onChange={(e)=>{setfromdate(e.target.value)}}
        />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          To Date: <span>:</span>
        </label>
        <input
          type="Date"
          name="Billing_date"
          value={todate}
          onChange={(e)=>{settodate(e.target.value)}}
        />
      </div>
   
    
      <div className="RegisForm_1">
      <label htmlFor="Billing_date">
        Paid Date <span>:</span>
      </label>
      <input
        type="Date"
        name="Billing_date"
        value={Billing_date.toISOString().split("T")[0]} // Convert to ISO string and extract the date part
        onChange={(e) => setBilling_date(e.target.value)} // Set directly as the ISO date string
        readOnly
      />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="Issue_From_id">
          Issue From <span>:</span>
        </label>
        <div className="Issue_input">
          <input
            type="text"
            name="Issue_From_id"
            className="dura_with1"
            value={userRecord?.user_id}
            readOnly
          />
          <input
            name="Issue_From_name"
            className="saxdsed"
            value={userRecord?.username}
            readOnly
          />
        </div>
      </div>
  

    {/* <div className="RegisForm_1">
      <label htmlFor="">
        Expenses Types <span>:</span>
      </label>
      <select
        type="text"
        name="Expenses_Types"
        value={Expense_Type}
        onChange={(e) => {
          setExpense_Type(e.target.value);
        }}
      >
        <option value={""}>Select</option>
        {Expenses_Name_list.map((ele, ind) => (
          <option key={ind} value={ele.Expenses_Name}>
            {ele.Expenses_Name}
          </option>
        ))}
      </select>
    </div> */}

    <div className="RegisForm_1">
      <label htmlFor="">
        Amount <span>:</span>
      </label>
      <input
        type="number"
        name="Given_amount"
        value={Expense_Amount}
        // onChange={handleChange}
      />
    </div>



    {/* <div className="RegisForm_1">
      <span className="Spanletter">
        {" "}
        * Total Balance Amount Is {view_Amount}
      </span>
      <span className="Spanletter">
        {" "}
        * Balance Amount In Cash {view_Amount1}
      </span>
      {/* <span className='Spanletter'> * Balance Amount In Recived Bank {view_Amount2}</span> 
    </div>*/}
    </div> 

    <div className="Register_btn_con">
      <button className="RegisterForm_1_btns" onClick={handleSubmit}>
        Print
      </button>
    </div>
        </>
      )}
    </div>
  );
};

export default DueBillinglab;
