import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";



function RequestListLab() {

  const yourStyles = {
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  };
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [RequestData, setRequestData] = useState([]);
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const totalPages = Math.ceil(filteredRows.length / pageSize);
  const paginatedData = filteredRows.slice(
    page * pageSize,
    (page + 1) * pageSize
  );
  const [selectedtest, setselectedtest] = useState([]);
  const [reportview, setReportView] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const dispatchvalue = useDispatch();
  const [Status, setStatus] = useState("Pending");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_for_external_hospital_request?status=${Status}`
      )
      .then((res) => {
        console.log(res);
        setRequestData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, Status, userRecord]);

  const handleViewReport = (RequestData) => {
    console.log(RequestData.Report[0])
    setModalContent(RequestData.Report[0]);
    
  };


  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };
  const handlenavigate = () => {
    navigate("/Home/Appoinment-Request-Lab");
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = RequestData?.filter((RequestData) => {
      const lowerCaseSupplierName = RequestData?.Patient_Name.toLowerCase();
      const lowerCasePhoneNo = RequestData?.Phone_Number.toString();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery?.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1?.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, RequestData]);

  const handleViewTest = (params) => {
    console.log(params);
    setselectedtest(params.testdetails);
    setOpenModal(true);
  };

  const handlenavigatetobilling = (params) => {
    dispatchvalue({ type: "Outsourcelab", value: params });
    navigate("/Home/ReceptionistBilling");
  };

  return (
    <div className="appointment">
      <div className="h_head h_head_h_2">
        <h4>Request List</h4>
        <div className="doctor_select_1 selt-dctr-nse vcxw2er">
          <label htmlFor="Status"> Product Type :</label>
          <select
            id="Status"
            name="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
      </div>
      <br />
      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">
            Patient Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Enter the First Name"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Phone No <span>:</span>
          </label>
          <input
            type="number"
            id="PhoneNo"
            value={searchQuery1}
            onChange={handleSearchChange}
            placeholder="Enter the Phone No"
          />
        </div>


      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="slectbill_ins">Request ID</th>
              <th id="slectbill_ins">Request Date</th>
              <th id="slectbill_ins">Patient Name</th>
              <th id="slectbill_ins">Phone</th>
              <th id="slectbill_ins">View Test</th>
              
              <th id="slectbill_ins">Report View</th>
              <th id="slectbill_ins">Refering Hospital</th>
              <th id="slectbill_ins">Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((request, index) => (
              <tr key={index}>
                <td>{request.Request_Id}</td>
                <td>{request.DateofRequest}</td>
                <td>{request.Patient_Name}</td>
                <td>{request?.Phone_Number}</td>
                <td>
                  <Button onClick={() => handleViewTest(request)}>
                    <VisibilityIcon style={{ color: "green" }} />
                  </Button>
                </td>
                {request.Status === "Completed" && request?.Report ? (
                  <td>
                    <Button onClick={() => handleViewReport(request)}>
                      <VisibilityIcon style={{ color: "green" }} />
                    </Button>
                  </td>
                ) : <td>
                  -</td>}

                <td>{request.Refering_Hospital}</td>
                {request.Status === "Pending" ? (
                  <td>
                    <Button onClick={() => handlenavigatetobilling(request)}>
                      <ArrowForwardIcon />
                    </Button>
                  </td>
                ) : (
                  <td>Completed</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => setOpenModal(false)}
        >
          <div
            className="newwProfiles newwPopupforreason uwag_ooveauto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th id="slectbill_ins">Sl No</th>

                    <th id="slectbill_ins">Test Name</th>
                    <th id="slectbill_ins">Test Code</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedtest.map((request, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{request.Test_Name}</td>
                      <td>
                        {request.Test_Code}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <>
              <iframe
                title="PDF Viewer"
                src={modalContent}
                style={{
                  width: "100%",
                  height: "435px",
                  border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                }}
              />

            </>
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>

        </div>
      </Modal>
    </div>
  );
}

export default RequestListLab;
