import React, { useState, useEffect } from 'react';
// import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { TbDelta } from "react-icons/tb";
import { GiDrippingTube } from "react-icons/gi";
import './CaptureGroup.css'
import './Navigation.css'
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  XAxis,
  Legend,
} from "recharts";
import { useDispatch } from 'react-redux';

function Navigationple() {
  const [testDetails, setTestDetails] = useState([]);
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log(capturedatas)
  // const [visitid, setvisitid] = useState([])
  const [dataline, setdataline] = useState([]);

  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  // const [value, setvalue] = useState([]);
  const [save, setsave] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const navigate = useNavigate();
  const dispatchvalue = useDispatch()
  console.log('capturedatas', capturedatas);
  useEffect(() => {
    axios.get(`${urllink}Billing/getcapturetestdata?Billinginvoice=${capturedatas?.Billing_Invoice}&gender=${capturedatas?.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}`)
      .then((response) => {
        console.log('response===============', response)
        const data = response.data.map((item, index) => ({
          id: index + 1,
          testcode: item.Test_Code,
          testname: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          Test_Method: item.Test_Method,
          uom: item.UOM,
          department: item.Department,
          status: 'Completed',
          medicalRemark: '',
          technicalRemark: ''
        }));
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink]);

  const handleStatusChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handleRemarksChange = (index, newValue, type) => {
    const updatedTestDetails = [...testDetails];
    if (type === 'technical') {
      updatedTestDetails[index].technicalRemark = newValue;
    } else if (type === 'medical') {
      updatedTestDetails[index].medicalRemark = newValue;
    }
    setTestDetails(updatedTestDetails);
  };

  const handlesamplesave = () => {
    // Check if every testDetail item has a status and medicalRemark (if needed)
    const isEveryTestValidated = testDetails.every((detail) => detail.status !== '');

    if (!isEveryTestValidated) {
      alert('Please ensure every test has a status selected and required remarks filled.');
      return; // Stop the function from proceeding further
    }

    const postdata = testDetails.map((detail) => ({
      ...detail,
      Billing_invoice: capturedatas?.Billing_Invoice,
      patientid: capturedatas?.Patient_Id,
      patientname: capturedatas?.Patient_Name,
      visit_id: capturedatas?.Visit_Id,
      location: userRecord?.location,
      createdby: userRecord?.username,
      sampleid:capturedatas?.Barcode,
      patientbarcodeid:capturedatas?.patientbarcode,
      Age:capturedatas?.Age,
      Gender:capturedatas?.Gender,
      dob:capturedatas?.dob
    }));

    console.log(postdata);

    axios.post(`${urllink}Phelobotomist/insersamplecapture`, postdata)
      .then((response) => {
        console.log(response.data);
       
      })
      .catch((error) => {
        console.log(error);
      });

    axios.post(`${urllink}Phelobotomist/updatesamplestatus`, {
      Billinginvoice: capturedatas.Billing_Invoice
    })
      .then((response) => {
        console.log(response);
        navigate('/Home/CaptureSamplelist');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handletrendview = (testname) => {
    axios.get(`${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`)
      .then((response) => {
        console.log(response.data)
         let data = response.data
         setdataline(data)
        setOpenModel(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }



  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate('/Home/CaptureSamplelist')
    }
  });

  const handlebarcodeprint = () => {

    dispatchvalue({ type: 'PrintBarcode', value: capturedatas })
    setsave(!save)
    navigate('/Home/BarcodePrint')
  }

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="h_head">
          <h3>Sample Collection Form</h3>
          <button className='barcodeprintbtn' onClick={handlebarcodeprint}>Print Barcode</button>
        </div>
        {/* <div className="dctr_info_up_head">
  <div className="RegisFormcon ">
    <div className="dctr_info_up_head22">
      <img src={workbenchformData.PatientPhoto || profileimg} alt="Patient Photo" />
      <label>Profile</label>
    </div>  
  </div>

  <div className="RegisFormcon">
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Patient Name <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
      </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Patient ID <span>:</span>
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.PatientID}{" "}
      </span>
    </div> */}
        {/* <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Date of birth <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName">
        {workbenchformData.firstName + " " + workbenchformData.lastName}{" "}
      </span>
    </div> */}
        {/* <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Age <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Age}{" "} */}
        {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName"> */}
        {/* Gender <span>:</span>{" "} */}
        {/* </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Gender}{" "} */}
        {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Primary Doctor <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.DoctorName}{" "} */}
        {/* </span>
    </div>
    <div className="RegisForm_1 ">
      <label htmlFor="FirstName">
        Location <span>:</span>{" "}
      </label>

      <span className="dctr_wrbvh_pice" htmlFor="FirstName"> */}
        {/* {workbenchformData.Location}{" "} */}
        {/* </span>
    </div>
  </div> */}
        {/* </div>  */}

        <div className="patientdetails_lab">
          <div className='patientdata'>

            <label className='phelobotomist_head'><span className='logo_profile'><CgProfile /></span>Patient Name<span>:</span></label>
            <span>{capturedatas?.Patient_Name}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><SlCalender /></span>Date  <span>:</span></label>
            <span>{capturedatas?.UpdatedAt}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><MdPhonelinkRing /></span>Phone <span>:</span></label>
            <span>{capturedatas?.Phone}</span>
          </div>

          <div className='patientdata'>
            {/* <Barcode value={capturedatas?.Barcode || ''} lineColor="black" height={50} width={1.5} /> */}
            <img src={capturedatas?.Sample_Barcode_Image} alt='barcode'/>
          </div>
        </div>

      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Test Name</th>
              <th>Test Code</th>
              <th>Test Method</th>
              <th>Containers</th>
              <th>Specimens</th>
              <th>Capture / Unit</th>
              <th>Status</th>
              <th>Remarks
                <table className='remarktable'>
                  <tbody>
                    <tr>
                      <th>Technical</th>
                      <th>Medical</th>
                    </tr>
                  </tbody>
                </table>
              </th>
              <th>Graph</th>
            </tr>
          </thead>
          <tbody>
            {testDetails.map((detail, index) => (
              <tr key={index}>
                <td>{detail.testname}</td>
                <td>{detail.testcode}</td>
                <td>{detail.Test_Method}</td>
                <td>{detail.Container_Name}<span className='containernamesvg'><GiDrippingTube /></span></td>
                <td>{detail.Specimen_Name}</td>
                <td>{detail.Captured_Unit}{detail.uom}</td>
                <td>
                  <select
                    name="status"
                    id="status"
                    className='Capture_Status_select'
                    value={detail.status}
                    onChange={(e) => handleStatusChange(index, e.target.value)}
                  >
                    {/* <option value="">Select Status</option> */}
                    <option value="Completed">Collected</option>
                    <option value="Pending">Pending</option>
                  </select>
                </td>
                <td>
                  <table className='remarktable'>
                    <tbody>
                      <tr>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            className='table_textarea'
                            value={detail.technicalRemark}
                            onChange={(e) => handleRemarksChange(index, e.target.value, 'technical')}
                          ></textarea>
                        </td>
                        <td>
                          <textarea
                            cols="20"
                            rows="1"
                            className='table_textarea'
                            value={detail.medicalRemark}
                            onChange={(e) => handleRemarksChange(index, e.target.value, 'medical')}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <span className='trends' onClick={() => handletrendview(detail.testcode)}><TbDelta /></span>
                </td>
              </tr>
            ))}
          </tbody>   
        </table>
      </div>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handlesamplesave}>
          Save
        </button>
      </div>


      {openModel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModel(false) }}
        >
          <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>
          <div className="">
      <LineChart
        width={380}
        height={200}
        data={dataline}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        className="chart_linechart"
        style={{ width: '100%', height: '100%' }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="visit" />
        <YAxis className="yaxis_linechart" />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="data"
          name="Delta Values"
          stroke="var(--ProjectColor)"
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </div>
            <div className="Register_btn_con regster_btn_contsai">

              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Navigationple;
