import React, { useEffect, useState } from 'react';
import './Organization.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ExternalLab() {
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const dispatchExternalLabdata = useSelector(state => state.userRecord?.ExternalLabdata)
  const [IsEditMode, setIsEditMode] = useState(false)
  const navigate = useNavigate();

  
  // Initialize the state with default values for each field
  const [externalLabData, setExternalLabData] = useState({
    labName: '',
    registerNo: '',
    labCode: '',
    address: '',
    email: '',
    phoneNumber: '',
    pincode: '',
    referenceCode: '',
    source: '',
    location: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value); // Get the input name and its new value
    setExternalLabData({
      ...externalLabData, // Spread the current state
      [name]: value, // Update the changed value
    });
  };

  const handlesubmit = () => {
    console.log(externalLabData)
    const allInputsFilled = Object.values(externalLabData).every(value => value.trim() !== '');

    if (allInputsFilled) {
      axios.post(`${urllink}usercontrol/insertexternallab`, externalLabData)
        .then((response) => {
          console.log(response.data);
          navigate('/Home/ExternalLabmasterList')
          // alert(response.data.message);
          // axios.post(`${urllink}mainddepartment/alter_table_for_outsource`, externalLabData)
          //   .then((response) => {
          //     console.log(response);
          //   })
          //   .catch((error) => {
          //     console.log(error)
          //   })
          // Resetting state might need adjustment, setting to initial state instead of empty string
          setExternalLabData({
            labName: '',
            registerNo: '',
            labCode: '',
            address: '',
            email: '',
            phoneNumber: '',
            pincode: '',
            referenceCode: '',
            source: '',
            location: ''
          });
        })
        .catch((error) => {
          console.log("error :", error);
          alert("An error occurred: " + error.message); // Improved error message
        });

      console.log("externalLabData", externalLabData);
    } else {
      // Alert if not all inputs are filled
      alert('Please enter all inputs');
    }
  };



  useEffect(() => {
    if (dispatchExternalLabdata.length === 0) {
      axios.get(`${urllink}usercontrol/get_lab_code`)
        .then((res) => {
          console.log(res)
          setExternalLabData((prevData) => ({
            ...prevData,
            labCode: res.data.next_id,
          }));
        })
        .catch((err) => {
          console.error(err);

        })
    }
  }, [dispatchExternalLabdata, urllink])


  useEffect(() => {
    if (dispatchExternalLabdata.length > 0) {

      setExternalLabData((prevData) => ({
        ...prevData,
        labName: dispatchExternalLabdata[0].Lab_Name,
        registerNo: dispatchExternalLabdata[0].Register_No,
        labCode: dispatchExternalLabdata[0].Labcode,
        address: dispatchExternalLabdata[0].Address,
        email: dispatchExternalLabdata[0].Email,
        phoneNumber: dispatchExternalLabdata[0].Phone_No,
        pincode: dispatchExternalLabdata[0].Pincode,
        referenceCode: dispatchExternalLabdata[0].Referencecode,
        source: dispatchExternalLabdata[0].SourceType,
        location: dispatchExternalLabdata[0].Location,
      }));
      setIsEditMode(true)

    }
  }, [dispatchExternalLabdata, urllink]);


  return (
    <div className="appointment">
      <div className="h_head">
        <h4>External Lab</h4>
      </div>

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="labCode">Lab Code<span>:</span></label>
          <input
            type="text"
            id="labCode"
            name="labCode"
            required
            disabled={IsEditMode}
            value={externalLabData.labCode}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="labName">Lab Name<span>:</span></label>
          <input
            type="text"
            id="labName"
            name="labName"
            required
            value={externalLabData.labName}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="registerNo">Register No<span>:</span></label>
          <input
            type="text"
            id="registerNo"
            name="registerNo"
            required
            value={externalLabData.registerNo}
            onChange={handleInputChange}
          />
        </div>


        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="address">Address<span>:</span></label>
          <textarea
            id="address"
            name="address"
            required
            value={externalLabData.address}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="email">Email<span>:</span></label>
          <input
            type="email"
            id="email"
            name="email"
            required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
            value={externalLabData.email}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="phoneNumber">Phone <span>:</span></label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            required
            pattern="[0-9]{10}"
            title="Please enter a 10-digit phone number"
            value={externalLabData.phoneNumber}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="pincode">Pincode<span>:</span></label>
          <input
            type="text"
            id="pincode"
            name="pincode"
            required
            pattern="[0-9]+"
            title="Please enter a valid pincode with only numbers"
            value={externalLabData.pincode}
            onChange={handleInputChange}
          />
        </div>

        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="referenceCode">Reference Code<span>:</span></label>
          <input
            type="text"
            id="referenceCode"
            name="referenceCode"
            required
            value={externalLabData.referenceCode}
            onChange={handleInputChange}
          />
        </div>
        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="location"> Location<span>:</span></label>
          <input
            type="text"
            id="location"
            name="location"
            required
            value={externalLabData.location}
            onChange={handleInputChange}
          />
        </div>


        <div className="RegisForm_1">
          <label className="new_form_first" htmlFor="source">Source Type<span>:</span></label>
          <select name="source" onChange={handleInputChange} value={externalLabData.source || ''}>
            <option value="">Select</option>
            <option value="Outsource">Outsource</option>
            <option value="Insource">Insource</option>
          </select>
        </div>

      </div>

      <div className='Register_btn_con'>
        <button className='RegisterForm_1_btns' onClick={handlesubmit}>
          {IsEditMode ? 'Update' : 'Submit'}
        </button>
      </div>
    </div>


  );
}

export default ExternalLab;
