import React, { useState, useEffect, useCallback } from "react";
import "./GroupMaster.css";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



function GroupMasterList() {
    const urllink = useSelector(state => state.userRecord?.UrlLink)

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryCode, setSearchQueryCode] = useState('');
    const [page, setPage] = useState(0);
    const [value, setValue] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedrow, setSelectedrow] = useState([]);
    // const [isEditMode, setIsEditMode] = useState(false);
    const pageSize = 10;
    const dispatchvalue = useDispatch();
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const paginatedData = filteredRows.slice(page * pageSize, (page + 1) * pageSize);

    const handleSearchChangeCode = (event) => {
        setSearchQueryCode(event.target.value);
    };


    const fetchgroup_masterlist = useCallback(() => {
        axios.get(`${urllink}usercontrol/getgroupmasterdata`)
            .then((response) => {
                console.log(response.data)
                const data = response.data.map((row) => ({
                    id: row.group_master_id,
                    ...row,
                }));
                setValue(data);
            })
            .catch((error) => {
                console.error('Error fetching test description data:', error);
            });
    }, [urllink])

    useEffect(() => {
        fetchgroup_masterlist()
    }, [fetchgroup_masterlist]);


    const handleEditClick = (params) => {
        console.log(params);

        setSelectedrow((updatedRow) => {
            // Set isEditMode to true and include the original row
            const updatedRowWithEditMode = { ...params, isEditMode: true };

            // Log the updatedRow
            console.log(updatedRowWithEditMode);

            // Dispatch the updatedRow
            dispatchvalue({ type: 'GroupMaster', value: updatedRowWithEditMode });
            console.log("dispatch", dispatchvalue)

            // Navigate to the Test Master component
            navigate('/Home/GroupMaster');

            // Return the updatedRow
            return updatedRowWithEditMode;
        });
    };



    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const lowersearchQueryCode = searchQueryCode.toLowerCase();

        const filteredData = value.filter((row) => {
            const lowerCaseSupplierName = row.group_name ? row.group_name.toLowerCase() : '';
            const lowersearchQueryCode1 = row.group_code ? row.group_code.toLowerCase() : '';


            return (lowerCaseSupplierName.includes(lowerCaseQuery) &&
                lowersearchQueryCode1.includes(lowersearchQueryCode)
            );

        });
        setFilteredRows(filteredData);
    }, [searchQuery, value, searchQueryCode]);


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlenavigate = () => {
        dispatchvalue({ type: 'GroupMaster', value: {} });
        navigate('/Home/GroupMaster')
    }

    console.log(selectedrow);
    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>GroupMaster List</h3>
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Group Name :</label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the GroupName' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Group Code :</label>
                        <input type="text" value={searchQueryCode} onChange={handleSearchChangeCode} placeholder='Enter the GroupCode' />
                    </div>
                    <button className='btn_1' type='submit' onClick={handlenavigate}>
                        <AddCircleOutlineIcon />
                    </button>
                </div>

                <div className="Selected-table-container">
                    <table className="selected-medicine-table2">
                        <thead>
                            <tr>
                                <th id="slectbill_ins">S.No</th>
                                <th id="slectbill_ins">Group Name</th>
                                <th id="slectbill_ins">Gender</th>
                                <th id="slectbill_ins">Report Type</th>
                                <th id="slectbill_ins">Department Name</th>
                                <th id="slectbill_ins">Test Category</th>
                                <th id="slectbill_ins">Logical Category</th>
                                <th id="slectbill_ins">Lonic Code</th>
                                <th id="slectbill_ins">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((lab, index) => (
                                <tr key={index}>
                                    <td>{lab.group_master_id}</td>
                                    <td>{lab.group_name}</td>
                                    <td>{lab.gender}</td>
                                    <td>{lab.report}</td>
                                    <td>{lab.department_name}</td>
                                    <td>{lab.test_category}</td>
                                    <td>{lab.logical_category}</td>
                                    <td>{lab.lonic_code}</td>
                                    <td>
                                        <Button
                                            size="small"
                                            onClick={() => handleEditClick(lab)}
                                            startIcon={<EditIcon />}
                                        >

                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <div className="grid_foot">
                        <button
                            onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                            }
                            disabled={page === 0}
                        >
                            Previous
                        </button>
                        Page {page + 1} of {totalPages}
                        <button
                            onClick={() =>
                                setPage((prevPage) =>
                                    Math.min(prevPage + 1, totalPages - 1)
                                )
                            }
                            disabled={page === totalPages - 1}
                        >
                            Next
                        </button>
                    </div>
                )}

            </div>

        </>
    );
}
export default GroupMasterList;