import React, { useEffect, useState } from "react";

// import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { DataGrid} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const EmployeeQueList = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 85 },
    { field: "employeeid", headerName: "Employee ID", width: 130 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "phone", headerName: "Phone No", width: 150 },
    { field: "Designation", headerName: "Designation", width: 180 },
    { field: "DateofJoining", headerName: "Date of Joining", width: 150 },

    {
      field: "action ",
      headerName: "View",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "Edit ",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button>
        </>
      ),
    },
    {
      field: "views ",
      headerName: "Action",
      width: 80,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList1(params)}>
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  const handleList = (params) => {
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `${urllink}HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )
      .then((response) => {
        dispatchvalue({ type: "foremployeedata", value: response.data });
      })
      .catch((error) => {
        console.error(error);
      });

    navigate("/Home/Employee-Profile");
  };

  const handleList1 = (params) => {
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `${urllink}HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )

      .then((response) => {
        dispatchvalue({
          type: "foruserregisteremployeedata",
          value: response?.data || [],
        });
        navigate("/Home/Register-User"); // Navigate to userregister page
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditList = (params) => {
    const employeeid = params.row.employeeid;
    const location = params.row.location;

    axios
      .get(
        `${urllink}HRmanagement/get_for_employeeprofile?employeeid=${employeeid}&location=${location}`
      )

      .then((response) => {
        dispatchvalue({
          type: "foredituserregisteremployeedata",
          value: response?.data,
        });
        navigate("/Home/Register-User"); // Navigate to userregister page
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails?location=${userRecord.location}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data)
        const Records = data.map((userdata, index) => ({
          id: index + 1,
          employeeid: userdata.EmployeeID,
          employeename: userdata.EmployeeName,
          phone: userdata.PhoneNumber,
          Designation: userdata.Designation,
          location: userdata.Locations,
          DateofJoining: userdata.DateofJoining,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord, urllink]);

  const navigate = useNavigate();

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "Employeeid") {
      setSearchQuery1(value);
    }
  };

  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.employeename.toLowerCase();
      const lowerCasePhoneNo = row.employeeid.toString().toLowerCase();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows, urllink]);

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Employee Queue List</h4>
      </div>
      <div className="con_1 ">
      <div className="inp_1">
          <label htmlFor="input">
            Employee ID <span>:</span>
          </label>
          <input
            type="text"
            id="Employeeid"
            value={searchQuery1}
            onChange={handleSearchChange}
            placeholder="Enter the Emplyee ID"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">
            Employee Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            onChange={handleSearchChange}
            value={searchQuery}
            placeholder="Enter the First Name"
          />
        </div>
       
      </div>
      <ThemeProvider theme={theme}>
        <div className="grid_1">
          <DataGrid
            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="data_grid"
          />
          {showdown > 0 && filteredRows.length > 10 && (
            <div className="grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() =>
                  setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                }
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>
      {filteredRows.length !== 0 ? (
        ""
      ) : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}{" "}
    </div>
  );
};

export default EmployeeQueList;
