import React, { useState, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const Doctorspayoutlist = () => {
  const urllink = useSelector(state => state.userRecord?.UrlLink);
  const [locationoptions, setlocationOptions] = useState([]);
  const userRecord = useSelector(state => state.userRecord?.UserData);
  const [filteredRows, setFilteredRows] = useState([]);
  const [formData, setFormData] = useState({
    BranchName: userRecord?.location,
    DoctorName: '',
    DateType: '',
    CurrentDate: '',
    FromDate: '',
    ToDate: ''
  });

  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / pageSize);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy-MM-dd");
      if (value === "Current") {
        setFormData(prevState => ({
          ...prevState,
          CurrentDate: formattedDate,
          [name]: value,
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          CurrentDate: "",
          [name]: value,
        }));
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const dynamicColumns = [
    { field: "id", headerName: "S.No", width: 160 },
    { field: "Transaction_Id", headerName: "Transaction Id", width: 160 },
    { field: "Paid_Date", headerName: "Date", width: 80 },
    { field: "Paid_By", headerName: "Issued By", width: 140 },
    { field: "Doctor_Name", headerName: "Doctor Name", width: 140 },
    { field: "Location", headerName: "Branch Name", width: 140 },
    { field: "Paid_Amount", headerName: "Issued Amount ", width: 140 },
  ];

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData(prevState => ({
      ...prevState,
      date: formattedDate,
    }));
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then(response => {
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch(error => {
        console.error("Error fetching Location options:", error);
      });
  }, [urllink]);

  const handleToSearch = () => {
    if (formData.DoctorName !== '' && formData.DateType !== '') {
      const params = {
        BranchName: formData.BranchName,
        DateType: formData.DateType,
        DoctorName: formData.DoctorName,
        CurrentDate: formData.CurrentDate,
        FromDate: formData.FromDate,
        ToDate: formData.ToDate,
      };
      axios.get(`${urllink}Billing/get_for_doctors_payout_report`, { params })
        .then(response => {
          const data = response.data
          const d_data = data.map((p, index) => ({
            id: index + 1,
            ...p,
          }))
          setFilteredRows(d_data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Transaction Id", dataKey: "Transaction_Id" },
        { header: "Date", dataKey: "Paid_Date" },
        { header: "Issued By", dataKey: "Paid_By" },
        { header: "Doctor Name", dataKey: "Doctor_Name" },
        { header: "Branch Name", dataKey: "Location" },
        { header: "Issued Amount", dataKey: "Paid_Amount" },
      ];

      const header = columns.map(col => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map(row => columns.map(col => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, `${formData.DoctorName}Report.csv`);
    } else {
      alert("No Data to Save");
    }
  };

  const handlePDF = () => {
    if (filteredRows.length !== 0) {
      const doc = new jsPDF();
      const columns = [
        { header: "ID", dataKey: "id" },
        { header: "Transaction Id", dataKey: "Transaction_Id" },
        { header: "Date", dataKey: "Paid_Date" },
        { header: "Issued By", dataKey: "Paid_By" },
        { header: "Doctor Name", dataKey: "Doctor_Name" },
        { header: "Branch Name", dataKey: "Location" },
        { header: "Issued Amount", dataKey: "Paid_Amount" },
      ];

      const columnStyles = {
        id: { width: 10 },
        Transaction_Id: { width: 30 },
        Paid_Date: { width: 30 },
        Paid_By: { width: 30 },
        Doctor_Name: { width: 30 },
        Location: { width: 30 },
        Paid_Amount: { width: 30 },
      };

      
     
      // const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(12);

      
     

      doc.autoTable({ columns, body: filteredRows, startY: 30, styles: columnStyles });

      const pdfBlob = doc.output('blob');
      saveAs(pdfBlob, `${formData.DoctorName}data.pdf`);
    } else {
      alert('No Data to save')
    }
  };


//   const handlePrintToPDF = () => {
//     if (filteredRows.length !== 0) {

//         const doc = new jsPDF();
//         const columns = [
//             { header: "ID", dataKey: "id" },
//             { header: "Transaction Id", dataKey: "Transaction_Id" },
//             { header: "Date", dataKey: "Paid_Date" },
//             { header: "Issued By", dataKey: "Paid_By" },
//             { header: "Doctor Name", dataKey: "Doctor_Name" },
//             { header: "Branch Name", dataKey: "Location" },
//             { header: "Issued Amount", dataKey: "Paid_Amount" },
//           ];
    
//           const columnStyles = {
//             id: { width: 10 },
//             Transaction_Id: { width: 30 },
//             Paid_Date: { width: 30 },
//             Paid_By: { width: 30 },
//             Doctor_Name: { width: 30 },
//             Location: { width: 30 },
//             Paid_Amount: { width: 30 },
//           };
    
//         // const pageWidth = doc.internal.pageSize.getWidth();
//         doc.setFontSize(12);

        

//         doc.autoTable({ columns, body: filteredRows, startY: 30, styles: columnStyles });

//         // Get the data URI of the PDF
//         const pdfDataUri = doc.output('datauristring');

//         // Open the PDF in a new window for printing
//         const printWindow = window.open();
//         printWindow.document.write(`<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`);
//     } else {
//         alert('No Data to Print')
//     }
// };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Doctor Payout Report</h4>
      </div>
      <br />
      <div className="RegisFormcon">

        <div className="RegisForm_1">
          <label htmlFor="">
            Branch Name <span>:</span>
          </label>

          <select
            name="BranchName"
            value={formData.DateBranchNameType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {locationoptions.map((location, index) => (
              <option key={index} value={location.location_name}>
                {location.location_name}
              </option>
            ))}
          </select>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="DoctorName">
            Doctor Name <span>:</span>
          </label>
          <input
            type="text"
            name="DoctorName"
            id="DoctorName"
            value={formData.DoctorName}
            onChange={handleChange}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="DateType">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={formData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
            {/* <option value="All">All</option> */}
          </select>
        </div>

        {formData.DateType === "Customize" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="FromDate">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={formData.FromDate}
                onChange={handleChange}
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="ToDate">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={formData.ToDate}
                onChange={handleChange}
              />
            </div>
          </>
        )}
      </div>
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleToSearch}>
          Search
        </button>
      </div>
      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage(prevPage => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage(prevPage =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          <button onClick={handleExportToExcel}>Save Excel</button>
          <button onClick={handlePDF}>Save PDF</button>

        </div>
      )}
    </div>
  );
};

export default Doctorspayoutlist;
