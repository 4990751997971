import React, { useState, useEffect } from "react";
// import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import axios from "axios";
import TestMaster from "./TestMaster";
import AgeSetUp from "./AgeSetUp";
// import ReferanceRange from "./ReferanceRange";
// import Remarks from "./Remarks";
import AddInterpretation from "./AddInterpretation";
import Commercial from "./Commercial";
// import RuleBased from "./RuleBased";
import ReflexMaster from "./ReflexMaster";
// import Outsourceratecard from "./Outsourceratecard";

function TestMasterNavigation() {

  const [activeTab, setActiveTab] = useState("TestMaster");
  const [isToggled, setIsToggled] = useState(false);
  // const urllink = useSelector(state => state.userRecord?.UrlLink)


  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".new-kit")) {
        closeToggle();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });



  return (
    <>

      <div className="appointment">
        <div className="h_head">
          <h4>Test Master</h4>
        </div>
        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange("TestMaster")}>
                Test Master
              </button>
              |
              <button onClick={() => handleTabChange("AgeSetUp")}>
                Age SetUp
              </button>
              |
              {/* <button onClick={() => handleTabChange("ReferanceRange")}>
                Referance Range
              </button>
              | */}
              <button onClick={() => handleTabChange("Commercial")}>
                Commercial
              </button>
              |
              {/* <button onClick={() => handleTabChange("RuleBased")}>
                Outsource Ratecard
              </button>
              | */}
              <button onClick={() => handleTabChange("ReflexMaster")}>
                Reflex Master
              </button>
              |
              <button onClick={() => handleTabChange("AddInterpretation")}>
                Add Interpretation
              </button>
              {/* |
              <button onClick={() => handleTabChange("Remarks")}>
                Remarks
              </button> */}
              {/* <div class="Lab_dropdown">
                <button class="Lab_button">Reflex Master</button>
                <div class="Lab_dropdown_content">
                  <button onClick={() => handleTabChange("ReflexMaster")}>
                  Reflex Master
                  </button>
                 
                </div>
              </div> */}
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("TestMaster")}>
                      Test Master
                    </button>
                    |
                    <button onClick={() => handleTabChange("AgeSetUp")}>
                      Age SetUp
                    </button>
                    |
                    <button onClick={() => handleTabChange("ReferanceRange")}>
                      Referance Range
                    </button>
                    |
                    {/* <button onClick={() => handleTabChange("Commercial")}>
                      Commercial
                    </button>
                    | */}
                    {/* <button onClick={() => handleTabChange("RuleBased")}>
                    Outsource Ratecard
                    </button>
                    | */}
                    <div class="Lab_dropdown">
                      <button class="Lab_button">Lab</button>
                      <div class="Lab_dropdown_content">
                        <button onClick={() => handleTabChange("ReflexMaster")}>
                          Reflex Master
                        </button>
                        |
                        <button
                          onClick={() => handleTabChange("AddInterpretation")}
                        >
                          Add Interpretation
                        </button>
                        {/* |
                        <button onClick={() => handleTabChange("Remarks")}>
                          Remarks
                        </button> */}
                      </div>
                    </div>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "TestMaster" && (
        <TestMaster />
      )}
      {activeTab === "AgeSetUp" && (
        <AgeSetUp />
      )}
      {/* {activeTab === "ReferanceRange" && (
        <ReferanceRange
          formData={formData}
          userRecord={userRecord}
          isSidebarOpen={isSidebarOpen}
        />
      )} */}
      {activeTab === "Commercial" && (
        <Commercial />
      )}
      {/* {activeTab === "RuleBased" && (
        <Outsourceratecard />
      )} */}
      {activeTab === "ReflexMaster" && (
        <ReflexMaster />
      )}
      {activeTab === "AddInterpretation" && (
        <AddInterpretation />
      )}
      {/* {activeTab === "Remarks" && (
        <Remarks />
      )} */}
    </>
  );
}

export default TestMasterNavigation;



