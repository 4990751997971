import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { format } from 'date-fns';
const theme = createTheme({
  
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const DuepayHistoryList = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);
  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)
  const navigate = useNavigate();
  const [selectedShow, setSelectedShow] = useState(false);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const [Rowdata, setRowdata] = useState([]);
  const [searchQuerypopup, setSearchQuerypopup] = useState('')
  const [searchQuery, setSearchQuery] = useState("");
  const [client , setclient] = useState('')
  const [summa, setsumma] = useState([]);
  const [Rowdatapopup, setRowdatapopup] = useState([]);
    const dispatchvalue = useDispatch();
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;
  const totalPages1 = Math.ceil(Rowdata.length / 10);
  const handleRequestEdit = (params) => {
    const index = params.row.Labcode
    const name = params.row.Lab_Name
    setclient(name)
    axios.get(`${urllink}Billing/get_report_for_view?location=${userRecord.location}&labcode=${index}`)
      .then((response) => {
        const data = response.data;
        console.log('out', data);
      setSelectedShow(true);
        setRowdata([
          ...data.map((row, ind) => ({
            id: ind + 1,
            ...row,
          }))
        ]);
      })
      .catch((error) => {
          console.error('Error fetching patients data:', error);
      });
      
  };

  const handlemovetopay=(params)=>{
      
    const Supplierpay=params.row
    dispatchvalue({type : 'Clientpay' , value :(Supplierpay)})
    // console.log(datas)
    navigate('/Home/Duehospitalpayout')
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  useEffect(() => {

    const lowerCaseQuery = searchQuerypopup.toLowerCase();
    const filteredData = Rowdata.filter((row) => {
        const lowerCaseSupplierName = row?.Client_Name.toLowerCase();

        return (
            lowerCaseSupplierName.includes(lowerCaseQuery) 
        );
    });

    setRowdatapopup(filteredData);
}, [searchQuerypopup,Rowdata]);

  useEffect(() => {
    if(Object.keys(userRecord).length !==0){
    axios
      .get(
        `${urllink}Billing/get_report_for_refering_hospital?location=${userRecord.location}`
      )
      .then((response) => {
      
        const Datas = response.data;

        console.log("Daaa",Datas)
      
        setsumma(
          Datas.map((row,ind) => ({
            id: ind+1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [userRecord.location,urllink]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.Lab_Name.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "id", headerName: "S No", width: 200 },
    { field: "Lab_Name", headerName: "Client Name", width: 200 },
    { field: "Labcode", headerName: "Client Code", width: 200 },
    { field: "Location", headerName: "Location", width: 200 },
    { field: "total_balanceamount", headerName: "Balance Amount", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
   
  ];

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };


  const dynamicColumns1 =[
    { field: 'Transaction_Id', headerName: 'Invoice No', width: 150 },
    // { field: 'PatientID', headerName: 'Patient Id', width: 150 },
    { field: 'Client_Name', headerName: 'Client Name', width: 150 },
    { field: 'From_Date', headerName: 'From Date', width: 120 },
    { field: 'To_Date', headerName: 'To Date', width: 120 },
    { field: 'Invoice_Amount', headerName: 'Invoice Amount', width: 120 },     
    { field: 'Invoice_Date', headerName: 'Invoice Date', width: 120 },        
    { field: 'Paid_Amount', headerName: 'Paid Amount', width: 120 },        
    { field: 'Balance_Amount', headerName: 'Balance Amount', width: 120 },        
    { field: 'Status', headerName: 'Status', width: 120 },      
    { field: 'Location', headerName: 'Location', width: 120 } ,
    {
      field: "pay",
      headerName: "Payment",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handlemovetopay(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
]


  return (
    <>
      
      <div className="appointment">
        <div className="h_head">
          <h4>Due Billing List</h4>
      
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Client Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Item Name"
            />
          </div>
          <button className="btn_1" type="submit">
            <SearchIcon />
          </button>
          
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns = {dynamicColumns }

                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}


           {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>{client} List</h3>
                </div>
                <div className="con_1 ">

                <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Invoive No :</label>
                            <input type="text" value={searchQuerypopup} onChange={(e)=>setSearchQuerypopup(e.target.value)} placeholder='Enter the Invoice No' />
                        </div>

                
                </div>
                <div className='IP_grid'>
                  <ThemeProvider theme={theme}>
                    <div className='IP_grid_1'>
                      <DataGrid
                        rows={Rowdatapopup.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                        columns={dynamicColumns1} // Use dynamic columns here
                        pageSize={10}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        onPageChange={handlePageChange1}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className='data_grid'
                      />
                      {showdown1 > 0 && Rowdatapopup.length > 10 && (
                        <div className='grid_foot'>
                          <button
                            onClick={() => setPage1((prevPage) => Math.max(prevPage - 1, 0))}
                            disabled={page1 === 0}
                          >
                            Previous
                          </button>
                          Page {page1 + 1} of {totalPages1}
                          <button
                            onClick={() =>
                              setPage1((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                            }
                            disabled={page1 === totalPages1 - 1}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                  {showdown1 !== 0 && Rowdatapopup.length !== 0 ? (
                    ''
                  ) : (
                    <div className='IP_norecords'>
                      <span>No Records Found</span>
                    </div>
                  )}
                </div>
                <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                    }}

                  >
                    close
                  </button>
                </div>
              </div>


            </div>
          </div>
        )}
        </div>
      </div>
    </>
  );
};
export default DuepayHistoryList;

