import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import "./ClinicDetails.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const ClinicDetails = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [locationOptions, setLocationOptions] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [clinicDetails, setClinicDetails] = useState([]);
  const [formDetails, setFormDetails] = useState({
    concern_id: '',
    ClinicName: '',
    email: '',
    phoneNo: '',
    landline: '',
    gstno: '',
    doorNo: '',
    street: '',
    area: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    location: ''
  });
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = clinicDetails.length;
  const totalPages = Math.ceil(clinicDetails.length / pageSize);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const fetchClinicData = useCallback(() => {
    if (userRecord?.location) {
      
      Axios.get(`${urllink}usercontrol/get_clinic_details?location=${userRecord.location}`)
        .then((response) => {
          const records = response.data;
          console.log(records)
          const formattedRecords = records.map((record, index) => ({
            id: index + 1,
            ...record,
          }));
          setClinicDetails(formattedRecords);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  },[urllink, userRecord.location]);

  useEffect(() => {
    // if (urllink && userRecord) {
      fetchClinicData();
      // console.log(userRecord?.location)
    // }
  },[fetchClinicData]);

  useEffect(() => {
    Axios.get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setLocationOptions(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, [urllink]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "landline" || name === "phoneNo") {
      if (value.length <= 10) {
        setFormDetails((prevDetails) => ({
          ...prevDetails,
          [name]: value,
        }));
      } else {
        alert("Phone No must contain 10 digits");
      }
    } else {
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };
  
  const handleSave = () => {
    const data = {
      concern_id: formDetails.concern_id,
      ClinicName: formDetails.ClinicName,
      email: formDetails.email,
      phoneNo: formDetails.phoneNo,
      landline: formDetails.landline,
      Gst_no: formDetails.gstno,
      doorNo: formDetails.doorNo,
      street: formDetails.street,
      area: formDetails.area,
      city: formDetails.city,
      pincode: formDetails.pincode,
      state: formDetails.state,
      country: formDetails.country,
      location: formDetails.location,
    };


    const fields = [
      'ClinicName',
      'email',
      'phoneNo',
      'landline',
      'Gst_no',
      'doorNo',
      'street',
      'area',
      'city',
      'pincode',
      'state',
      'country',
      'location',
    ];

    for (let field of fields) {
      if (!data[field]) {
        alert(`${field} is required and cannot be empty.`);
        return;
      }
    }

    if (data.concern_id !== null  && data.concern_id !== '') {
      // Update existing clinic detail
      Axios.post(`${urllink}usercontrol/clinicUpdate`, data)
        .then((response) => {
          successMsg(response.data.message);
          fetchClinicData();
          setEditIndex(null);
          setFormDetails({
            concern_id: '',
            ClinicName: '',
            email: '',
            phoneNo: '',
            landline: '',
            gstno: '',
            doorNo: '',
            street: '',
            area: '',
            city: '',
            pincode: '',
            state: '',
            country: '',
            location: ''
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      // Insert new clinic detail
      Axios.post(`${urllink}usercontrol/clinicInsert`, data)
        .then((response) => {
          successMsg(response.data.message);
          fetchClinicData();
          setFormDetails({
            concern_id: '',
            ClinicName: '',
            email: '',
            phoneNo: '',
            landline: '',
            gstno: '',
            doorNo: '',
            street: '',
            area: '',
            city: '',
            pincode: '',
            state: '',
            country: '',
            location: ''
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  const handleEditClinic = (index) => {
    const selectedClinic = clinicDetails[index];
    setFormDetails({
      concern_id: selectedClinic.concern_id ?? '',  // Ensure concern_id is set properly
      ClinicName: selectedClinic.ClinicName,
      email: selectedClinic.email,
      phoneNo: selectedClinic.phoneNo,
      landline: selectedClinic.landline,
      gstno: selectedClinic.gstno,
      doorNo: selectedClinic.doorNo,
      street: selectedClinic.street,
      area: selectedClinic.area,
      city: selectedClinic.city,
      pincode: selectedClinic.pincode,
      state: selectedClinic.state,
      country: selectedClinic.country,
      location: selectedClinic.location
    });
    setEditIndex(index);
  };

  const dynamicColumns = [
    { field: "id", headerName: "S.No", width: 60 },
    { field: "ClinicName", headerName: "Lab Name", width: 200 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNo", headerName: "Phone No", width: 150 },
    { field: "landline", headerName: "Landline No", width: 150 },
    { field: "gstno", headerName: "GST No", width: 150 },
    { field: "doorNo", headerName: "Door No", width: 150 },
    { field: "street", headerName: "Street", width: 150 },
    { field: "area", headerName: "Area", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    { field: "pincode", headerName: "Pincode", width: 150 },
    { field: "state", headerName: "State", width: 150 },
    { field: "country", headerName: "Country", width: 150 },
    { field: "location", headerName: "Location", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <Button
          className="delnamebtn"
          onClick={() => handleEditClinic(params.row.id - 1)}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4> Laborotary Details</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ClinicName">
            Laborotary Name<span>:</span>
            </label>
            <input
              type="text"
              id="ClinicName"
              name="ClinicName"
              value={formDetails.ClinicName}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="email">
              Email<span>:</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formDetails.email}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="phoneNo">
              Phone no<span>:</span>
            </label>
            <input
              type="text"
              id="phoneNo"
              name="phoneNo"
              value={formDetails.phoneNo}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="landline">
              Landline No<span>:</span>
            </label>
            <input
              type="text"
              id="landline"
              name="landline"
              value={formDetails.landline}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="gstno">
              GST No<span>:</span>
            </label>
            <input
              type="text"
              id="gstno"
              name="gstno"
              value={formDetails.gstno}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="doorNo">
              Door No<span>:</span>
            </label>
            <input
              type="text"
              id="doorNo"
              name="doorNo"
              value={formDetails.doorNo}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="street">
              Street<span>:</span>
            </label>
            <input
              type="text"
              id="street"
              name="street"
              value={formDetails.street}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="area">
              Area<span>:</span>
            </label>
            <input
              type="text"
              id="area"
              name="area"
              value={formDetails.area}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="city">
              City<span>:</span>
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formDetails.city}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="pincode">
              PinCode<span>:</span>
            </label>
            <input
              type="text"
              id="pincode"
              name="pincode"
              value={formDetails.pincode}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="state">
              State<span>:</span>
            </label>
            <input
              type="text"
              id="state"
              name="state"
              value={formDetails.state}
              onChange={handleChange}
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="country">
              Country<span>:</span>
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formDetails.country}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
          <label>
            Location <span>:</span>
          </label>
          <select
            name="location"
            value={formDetails.location}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            {locationOptions.map((loc) => (
              <option key={loc.location_id} value={loc.location_name}>
                {loc.location_name}
              </option>
            ))}
          </select>
        </div>

        </div>
        
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            {editIndex !== null ? 'Update' : 'Save'}
          </button>
          <ToastContainer/>
        </div>
      </div>
      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={clinicDetails.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && clinicDetails.length > 10 && (
              <div className="IP_grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && clinicDetails.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ClinicDetails;
