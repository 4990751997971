import React, { useState, useEffect } from 'react';
// import Barcode from 'react-barcode';
import { useSelector } from 'react-redux';
import axios from 'axios';
// import { CgProfile } from "react-icons/cg";
// import { SlCalender } from "react-icons/sl";
// import { MdPhonelinkRing } from "react-icons/md";
// import { useNavigate } from 'react-router-dom'
// import { TbDelta } from "react-icons/tb";
import { useDispatch } from 'react-redux';

function Samplecapture() {
  const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const userRecord = useSelector(state => state.userRecord?.UserData)
  // const [status, setstatus] = useState('')
  // const navigate = useNavigate()
  const dispatchvalue = useDispatch()
  useEffect(() => {
    axios.get(`${urllink}Phelobotomist/getgroupcost?Billinginvoice=${capturedatas.Billing_Invoice}&gender=${capturedatas.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}&Department=${userRecord?.Department}`)
      .then((response) => {
        console.log(response)
        const data = response.data.map((item, index) => ({
          id: index + 1,
          testcode: item.Test_Code,
          testname: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          medicalremark: item.Medicalremaks,
          Capturestatus: 'Completed',
          technicalremark: item.Technicalremarks,
          uom: item.UOM,
          department: item.Department,
          status: 'Approved',
          remarks: ''
        }));
        setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink]);

  const handleStatusChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handleRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].remarks = newValue;
    setTestDetails(updatedTestDetails);
  };

  // const handlesamplesave = () => {
  //   const postdata = testDetails.map(detail => ({
  //     ...detail,
  //     Billing_invoice: capturedatas.Billing_Invoice,
  //     patientid: capturedatas.Patient_Id,
  //     patientname: capturedatas.Patient_Name
  //   }));

  //   console.log(postdata);
  //   axios.post("${urllink}Phelobotomist/insersamplecapture", postdata)
  //     .then((response) => {
  //       console.log(response.data)

  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //   axios.post('${urllink}Phelobotomist/updatesamplestatus', {
  //     Billinginvoice: capturedatas.Billing_Invoice
  //   })
  //     .then((response) => {
  //       console.log(response)
  //       navigate('/Capture Sample')
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //   // Perform your axios POST operations here
  // };

  console.log(filteredRows)
  const hasRecollect = testDetails.some(detail => detail.status === 'Recollect');


  const handleSubmitAnalyseReport = () => {
    // console.log(filteredRows);
    if (testDetails.status === '' && testDetails.remarks === '') {
      alert("please enter values")
    }
    const postdata = {
      ...testDetails,
      Billing_Invoice: capturedatas.Billing_Invoice
    }

    console.log(postdata)


    axios.post(`${urllink}Phelobotomist/updatereportsamplestatus`, postdata)
      .then((response) => {
        console.log(response)
        alert("Submited Successfully");

        dispatchvalue({ "type": "Navigationlab", value: "Reportentry" })



        // handlefunction()
        // setHide(true)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="new-patient-registration-form">
        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
                <th>Test Name</th>
                <th>Capture/Unit</th>
                <th>Status</th>

                <th>
                  <span style={{ marginBottom: '2px' }}>
                    Remarks
                  </span>
                  <div className='table_div_seperate'>
                    <span>Technical</span>
                    <span>Medical</span>
                  </div>

                </th>

                <th>Recived Status</th>
                {hasRecollect && (<th>Remarks</th>)}
                {/* <th>Graph</th> */}
              </tr>
            </thead>

            <tbody>
              {testDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.testname}</td>
                  <td>{detail.Captured_Unit}{detail.uom}</td>
                  <td>{detail.Capturestatus}</td>
                  <td>
                    <div className='table_div_seperate'>
                      <span>
                        <p>{detail.medicalremark}</p>
                      </span>
                      <span>
                        <p>{detail.technicalremark}</p>
                      </span>
                    </div>
                  </td>
                  <td>
                    <select
                      name="status"
                      id="status"
                      className='Capture_Status_select'
                      value={detail.status}
                      onChange={(e) => handleStatusChange(index, e.target.value)}
                    >
                      {/* <option value="">Select Status</option> */}
                      <option value="Approved">Received</option>
                      <option value="Recollect">Recollect</option>
                    </select>
                  </td>
                  {/* Corrected Condition */}
                  {detail.status === "Recollect" && (
                    <td>
                      <textarea
                        cols="20"
                        rows="1"
                        value={detail.technicalRemark}
                        onChange={(e) => handleRemarksChange(index, e.target.value)}
                      ></textarea>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default Samplecapture;



