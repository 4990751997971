import React, { useState, useEffect, useCallback } from "react";
import "./GroupMaster.css";
// import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const OfferPackageList = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryCode, setSearchQueryCode] = useState('');
    const [value, setValue] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    // const [selectedRow, setSelectedRow] = useState([]);
    const dispatchValue = useDispatch();
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const paginatedData = filteredRows.slice(page * pageSize, (page + 1) * pageSize);
    const urllink = useSelector(state => state.userRecord?.UrlLink)



    const handleSearchChangeCode = (event) => {
        setSearchQueryCode(event.target.value);
    };


    const fetchget_offer_package_data = useCallback(() => {
        axios.get(`${urllink}usercontrol/get_offer_package_data`)
            .then((response) => {
                console.log("response", response.data);
                const data = response.data.map((row) => ({
                    id: row.offer_package_id,  // Ensure each row has a unique `id`
                    ...row,
                }));
                setValue(data);
            })
            .catch((error) => {
                console.error('Error fetching test description data:', error);
            });
    },[urllink])

    useEffect(() => {
        fetchget_offer_package_data()
    }, [fetchget_offer_package_data]);



    const handleEditClick = (params) => {
        console.log(params);
        axios.get(`${urllink}usercontrol/get_packagedata_forupdate?package_code=${params?.package_code}`)
            .then((res) => {
                console.log(res)
                dispatchValue({ type: 'OfferPackageData', value: res.data })
                navigate('/Home/OfferPackage')

            })
            .catch((err) => {
                console.error(err);

            })
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = value.filter((row) => {
            const lowerCasePackageName = row.package_name ? row.package_name.toLowerCase() : '';
            return lowerCasePackageName.includes(lowerCaseQuery);
        });
        setFilteredRows(filteredData);
        setPage(0);
    }, [searchQuery, value]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlenavigate = () => {
        dispatchValue({ type: 'OfferPackageData', value: [] })

        navigate('/Home/OfferPackage')
    }

    const hadleeditstatus = (params) => {
        console.log(params)

        let newstatus;
        if (params.Status === 'Active') {
            newstatus = 'Inactive'
        } else if (params.Status === 'Inactive') {
            newstatus = 'Active'
        }

        axios.post(`${urllink}usercontrol/update_packagestatus?package_code=${params.package_code}&newstatus=${newstatus}`)
            .then((res) => {
                console.log(res)
                fetchget_offer_package_data()

            })

            .catch((err) => {
                console.error();

            })
    }


    return (
        <div className="appointment">
            <div className="h_head">
                <h3>Offer Package List</h3>
            </div>
            <div className="con_1 ">
                <div className="inp_1">
                    <label htmlFor="input">Package Name :</label>
                    <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the GroupName' />
                </div>
                <div className="inp_1">
                    <label htmlFor="input">Package Code :</label>
                    <input type="text" value={searchQueryCode} onChange={handleSearchChangeCode} placeholder='Enter the GroupCode' />
                </div>
                <button className='btn_1' type='submit' onClick={handlenavigate}>
                    <AddCircleOutlineIcon />
                </button>
            </div>
            {/* <div className='grid_1'>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                        columns={dynamicColumns}
                        pageSize={pageSize}
                        onPageChange={(newPage) => setPage(newPage)}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className='data_grid'
                    />
                    {showdown > 0 && filteredRows.length > pageSize && (
                        <div className='grid_foot'>
                            <button
                                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                disabled={page === 0}
                            >
                                Previous
                            </button>
                            Page {page + 1} of {totalPages}
                            <button
                                onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                disabled={page === totalPages - 1}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </ThemeProvider>
                {filteredRows.length === 0 && (
                    <div className='IP_norecords'>
                        <span>No Records Found</span>
                    </div>
                )}
            </div> */}
            <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                    <thead>
                        <tr>
                            <th id="slectbill_ins">S.No</th>
                            <th id="slectbill_ins">Package Name</th>
                            <th id="slectbill_ins">Gender</th>
                            <th id="slectbill_ins">Report Type</th>
                            <th id="slectbill_ins">Department Name</th>
                            <th id="slectbill_ins">Auto Authorized User</th>
                            <th id="slectbill_ins">Package Cost</th>
                            <th id="slectbill_ins">Status</th>
                            <th id="slectbill_ins">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.map((employee, index) => (
                            <tr key={index}>
                                <td>{employee.offer_package_id}</td>
                                <td>{employee.package_name}</td>
                                <td>{employee.gender}</td>
                                <td>{employee.report}</td>
                                <td>{employee.department_name}</td>
                                <td>{employee.authorized_user}</td>
                                <td>{employee.Package_Cost}</td>
                                <td>
                                    <button
                                        onClick={() => hadleeditstatus(employee)}
                                        className="Addnamebtn_pt2"
                                    >
                                        {employee.Status}
                                    </button>
                                </td>
                                <td>
                                    <Button
                                        size="small"
                                        onClick={() => handleEditClick(employee)}
                                        startIcon={<EditIcon />}
                                    >

                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <div className="grid_foot">
                    <button
                        onClick={() =>
                            setPage((prevPage) => Math.max(prevPage - 1, 0))
                        }
                        disabled={page === 0}
                    >
                        Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                        onClick={() =>
                            setPage((prevPage) =>
                                Math.min(prevPage + 1, totalPages - 1)
                            )
                        }
                        disabled={page === totalPages - 1}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default OfferPackageList;
