import React, { useState, useEffect,useRef } from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import axios from "axios";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { TbDelta } from "react-icons/tb";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  XAxis,
  Legend,
} from "recharts";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Select, MenuItem } from '@mui/material';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function Reportverify() {
  // const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  console.log(testDetails);
  // const [visitids, setvisitids] = useState([]);
  const [dataline, setdataline] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [page, setPage] = useState(0);
  const [content, setcontent] = useState("");
  const [openpreview, setopenpreview] = useState(false);
  const [openpreview1, setopenpreview1] = useState(false);
  const [openeditpreview, setopeneditpreview] = useState(false)
  const [formatData, setFormatData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [editData, seteditData] = useState("")
  const [editMode, setEditMode] = useState(false);
  // const [value, setvalue] = useState([]);
  const [culturevalue, setculturevalue] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  // const [visitid, setvisitid] = useState();
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [report, setreport] = useState({
    Collected: "",
    Received: "",
    Reported: "",
    Head: "",
    Detail: "",
  });
  const [summa, setsumma] = useState([]);
  // const [isedited, setisedited] = useState('')
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState("");
  const [department, setDepartment] = useState([]);

  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log("capturedatas", capturedatas);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isedited = useSelector((state) => state.userRecord?.iseditedsenior);

  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const senioredit = useSelector((state) => state.userRecord?.senioreditdoc);
  const dispatchvalue = useDispatch();

  const navigate = useNavigate();
  const pageSize = 10;
  const totalPages = Math.ceil(culturevalue.length / pageSize);
  const showdown = culturevalue.length;


  const contentRef = useRef(null);
  const [selectedTestCode, setSelectedTestCode] = useState(null);
  const [testFormats, setTestFormats] = useState({});




  const handleSensitivityChange = (id, value) => {
    const updatedRows = culturevalue.map((row) =>
      row.id === id ? { ...row, Sensitivetype: value } : row
    );
    setculturevalue(updatedRows);
  };


  const dynamicColumns = [
    { field: "id", headerName: "S.No", width: 150 },
    { field: "antibiotic", headerName: "Antibiotic Name", width: 150 },
    { 
      field: "Sensitivetype", 
      headerName: "Sensitive Type", 
      width: 150,
      renderCell: (params) => (
        editMode ? (
          <Select
            value={params.row.Sensitivetype}
            onChange={(e) => handleSensitivityChange(params.row.id, e.target.value)}
            fullWidth
          >
            <MenuItem value="Sensitive">Sensitive</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Resistant">Resistant</MenuItem>
          </Select>
        ) : (
          params.row.Sensitivetype
        )
      ),
    },
    { field: "Oraganism", headerName: "Organism", width: 150 },
  ];

  // const handlestatuscahngeretest = (index) => {
  //   const updatedTestDetails = [...testDetails];
  //   updatedTestDetails[index].status = '';
  //   setTestDetails(updatedTestDetails);
  // }

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=ALL`)
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas?.Patient_Name}`
      )
      .then((response) => {
        console.log(response);
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    urllink,
    capturedatas?.Barcode_id,
    capturedatas?.Patient_Id,
    capturedatas?.Patient_Name,
  ]);

  // useEffect(() => {
  //   axios.get(`${urllink}Billing/getcompletedsample?Billinginvoice=${capturedatas.Billing_Invoice}`)
  //     .then((response) => {
  //       console.log('=========================================================================================================================',response.data)
  //       const data = response.data.map((item, index) => ({
  //         id: index + 1, // Assigning a unique id based on the item index
  //         testcode: item.Test_Code,
  //         testname: item.Test_Name,
  //         testnames: item.Test_Name,
  //         Captured_Unit: item.Captured_Unit,
  //         Container_Name: item.Container_Name,
  //         Specimen_Name: item.Specimen_Name,
  //         UpdateAt: capturedatas.UpdatedAt,
  //         paniclow: item.PanicLow,
  //         panichigh: item.PanicHigh,
  //         patient_name: capturedatas.Patient_Name,
  //         Billing_Invoice: capturedatas.Billing_Invoice,
  //         barcodedata: capturedatas.Barcode_id,
  //         patient_id: capturedatas.Patient_Id,
  //         uom: item.UOM,
  //         capturevalue: '',
  //         medicalremark: '',
  //         technicalremark: ''
  //       }));
  //       setFilteredRows(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data: ", error);
  //     });
  // }, [capturedatas?.Billing_Invoice, urllink]);

  // const hasRecollect = testDetails.some(detail => detail.panichigh <= detail.Capture_Value);



  const handleupdatesencitivity = () => {
    
    const postdata = {
      ...culturevalue,
      invoice : capturedatas?.Billing_Invoice,
      visitid :capturedatas?.Visit_Id
    }
  console.log('postdata', postdata);
   axios.post(`${urllink}Phelobotomist/culture_sencitivity_type_update`, postdata)
   .then((response)=>{
    console.log(response);
    setEditMode(!editMode);
   })
   .catch((error)=>{
    console.log(error);
   })

  };


  const handleStatusChange = (index, newValue, depart) => {
    const updatedTestDetails = [...testDetails];
    let updateddetail = updatedTestDetails.filter(
      (i) => i.department === depart
    );
    updateddetail[index].status = newValue;
    setTestDetails(updatedTestDetails);
  };

  // const handlemedicalRemarksChange = (index, newValue) => {
  //   const updatedTestDetails = [...testDetails];
  //   updatedTestDetails[index].medicalremark = newValue;
  //   setTestDetails(updatedTestDetails);
  // };

  // const handletecnicalRemarksChange = (index, newValue) => {
  //   const updatedTestDetails = [...testDetails];
  //   updatedTestDetails[index].technicalremark = newValue;
  //   setTestDetails(updatedTestDetails);
  // };

  // console.log(filteredRows)

  // useEffect(() => {
  //   axios.get(`${urllink}Billing/getforvisitid?patientphn=${capturedatas.Phone}&patientname=${capturedatas.Patient_Name}`)
  //     .then((response) => {
  //       console.log(response.data)
  //       const data = response.data.nextVisitId
  //       setvisitid(data)
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // })

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_record_from_lab_techy?Billinginvoice=${capturedatas?.Billing_Invoice}&Visitid=${capturedatas?.Visit_Id}`
      )
      .then((response) => {
        console.log("response.data", response.data);
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testname: item.Test,
          Test_Code: item.Test_Code,
          Capture_Value: item.Capture_Value,
          Status: item.Status,
          Sample_Specimen: item.Sample_Specimen,
          Patient_Name: capturedatas.Patient_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.Panic_Low,
          panichigh: item.Panic_High,
          Medical_Remark: item.Medical_Remark,
          Technical_Remark: item.Technical_Remark,
          patient_id: capturedatas.Patient_Id,
          Billing_Invoice: item.Billing_invoice,
          visitid: capturedatas.Visit_Id,
          Culturetest: item.Culturetest || "",
          colony_count: item.Colony_Count || "",
          Capture_Oragnism: item.Capture_Oragnism || "",
          status: "Verified",
          location: userRecord?.location,
          createdby: userRecord?.username,
          department: item.Department || "",
          Content: item.Content || "",
          reference: item.Reference,
          referencedata: item.Reference_Range,
          Notes: item.Notes || "",
          Microscopy_Data: item.Microscopy_Data || "",
          Report_Type: item.Report_Type || "",
          Culture_Report: item.Culture_Report || "",
          inputtype: item.Input_Type || "",
          Report_Status: item.Report_Status || "",
          micronumber : item.micronumber || ""
        }));
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [
    capturedatas?.Billing_Invoice,
    urllink,
    capturedatas?.Visit_Id,
    userRecord,
    capturedatas,
  ]);

  const handleverifydata = async () => {
    if (isedited === "Edited") {
      setTestDetails((prevDetails) =>
        prevDetails.map((item) =>
          item.department === "HISTOPATHOLOGY"
            ? { ...item, content: senioredit } // Overwrite the Content value
            : item
        )
      );
    }

    // Use setTimeout to ensure state update has completed
    setTimeout(() => {
      // Separate the testDetails into groups based on their status
      const recollectTests = testDetails.filter(
        (detail) => detail.status === "Recollect"
      );
      const retestTests = testDetails.filter(
        (detail) => detail.status === "Retest"
      );
      const verifiedTests = testDetails.filter(
        (detail) => detail.status === "Verified"
      );
      const approveTests = testDetails.filter(
        (detail) => detail.status === "Approved"
      );
      // Define a function for handling API calls
      const performApiCall = (url, data) => {
        return axios
          .post(url, data)
          .then((response) => {
            console.log(response);
            alert(response.data.message);
            navigate("/Home/VerifyReportEntry");
          })
          .catch((error) => {
            console.error(error);
          });
      };

      if (testDetails.some((detail) => detail.status !== "")) {
        console.log("Verified Tests:", verifiedTests);

        // Process Recollect Tests
        if (recollectTests.length > 0) {
          performApiCall(
            `${urllink}Phelobotomist/seniorsampleaprove`,
            recollectTests
          );
        }

        // Process Retest Tests
        if (retestTests.length > 0) {
          performApiCall(
            `${urllink}Phelobotomist/updateretestforsenior`,
            retestTests
          );
        }

        // Process Verified Tests
        if (verifiedTests.length > 0) {
          performApiCall(
            `${urllink}Phelobotomist/insertseniorverifyreport`,
            verifiedTests
          );
        }
        if (approveTests.length > 0) {
          performApiCall(
            `${urllink}Phelobotomist/insert_or_update_approve_status_without_path`,
            approveTests
          );
        }
      } else {
        alert("Please Select Verification Status");
      }
    }, 600); // Adjust the delay as needed
  };

  const handletrendview = (testname) => {
    axios
      .get(
        `${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`
      )
      .then((response) => {
        console.log(response.data);
        let data = response.data;
        setdataline(data);
        setOpenModel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleTextareaChange = (e, name) => {
    const { value } = e.target;
  
    setFormatData((prev) => {
      // Map directly over prev since formatData is at the top level
      const updatedFormatData = prev.map((item) => {
        if (item.name === name) {
          return { ...item, value: value }; // Update the value for the matching field
        }
        return item; // Keep other fields unchanged
      });
  
      return updatedFormatData; // Return the updated array
    });
  };
  
  


  // const culturetests = testDetails.some((item) => item.Culturetest === 'Yes');
  const reportnogrowth = testDetails.some((p) => p.Report_Type === "NoGrowth");
  const reportot = testDetails.some((p) => p.Report_Type === "Ot");

  const handleshowculturetest = () => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_culture_report_completion?Billinginvoice=${capturedatas?.Billing_Invoice}&Visitid=${capturedatas?.Visit_Id}`
      )
      .then((response) => {
        console.log(response);
        const data = response.data.map((p, index) => ({
          id: index + 1,
          ...p,
        }));
        setculturevalue(data);
        setshowmodel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("testDetails...................", testDetails);

  const handleChange = (id, value) => {
    // prompt("You are changing the Capture Value");

    let updatedDetail = [...testDetails];
    let testData = updatedDetail.find((p) => p.id === id + 1);

    if (testData) {
      testData.Capture_Value = value;
      setTestDetails(updatedDetail); // Assuming testDetails is your state variable
    }
  };

  const handleChangemicronumber = (id, value) => {
    // prompt("You are changing the Capture Value");

    let updatedDetail = [...testDetails];
    let testData = updatedDetail.find((p) => p.testcode === id );

    if (testData) {
      testData.micronumber = value;
      setTestDetails(updatedDetail); // Assuming testDetails is your state variable
    }
  };

  // const ishistopathology = testDetails.some((item) => item.department === 'HISTOPATHOLOGY')

  const handlepdfpreview = (params) => {
    console.log(params);

    axios
      .get(
        `${urllink}Phelobotomist/Get_Template?Billing_Invoice=${params?.Billing_Invoice}&patient_id=${params?.patient_id}&visitid=${params?.visitid}&Test_Code=${params?.Test_Code}`
      )

      .then((res) => {
        console.log(res);
        // Assuming res.data.format contains the format array
        setFormatData(res.data.format);
        setopenpreview(true); // Trigger the preview modal to open
      })

      .catch((err) => {
        console.error(err);
      });

    // Accumulate content data
    if (isedited === "Edited") {
      setcontent(senioredit);
      setopenpreview(!openpreview);
    } else {
      console.log(testDetails);

      const accumulatedContent = testDetails
        .filter((item) => item.department === "HISTOPATHOLOGY")
        .map((item) => item.Content)
        .join("\n"); // Assuming item.Content contains strings
      console.log(accumulatedContent);
      // Update content state
      setcontent(accumulatedContent);

      // Toggle openpreview state
      setopenpreview(!openpreview);
    }
  };

  const handleeditpreview = (params) =>{
    
     console.log(params);

     axios
       .get(
         `${urllink}Phelobotomist/Get_Template?Billing_Invoice=${params?.Billing_Invoice}&patient_id=${params?.patient_id}&visitid=${params?.visitid}&Test_Code=${params?.Test_Code}`
       )
 
       .then((res) => {
         console.log(res.data.format);
         // Assuming res.data.format contains the format array
         setFormatData(res.data.format);
         seteditData(params?.Test_Code)
         setopeneditpreview(!openeditpreview)
         autoResizeTextarea()
       })
 
       .catch((err) => {
         console.error(err);
       });
 
  }



  const handlepdfpreview1 = () => {
    // Accumulate content data
    if (isedited === "Edited") {
      setcontent(senioredit);
      setopenpreview1(!openpreview1);
    } else {
      const accumulatedContent = testDetails
        .filter((item) => item.department === "MICROBIOLOGY")
        .map((item) => item.Content)
        .join("\n"); // Assuming item.Content contains strings

      // Update content state
      setcontent(accumulatedContent);
      // Toggle openpreview state
      setopenpreview1(!openpreview1);
    }
  };

  // console.log(content);
  const formatHeading = (format) => {
    const formatMap = {
      histopathologySecondOpinion: "Histopathology (Second Opinion)",
      peripheralSmear: "PeripheralSmear (PS)",
      lbc: "LBC",
      biopsy: "Biopsy",
      fnac: "FNAC",
      pathologyReport: "Pathology Report",
    };

    return formatMap[format] || format; // Fallback to original format if not found
  };


  const autoResizeTextarea = (e) => {
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set it to the scroll height
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageData(file);
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL

      // Update testFormats with the new image data for the selected testcode
      setTestFormats((prev) => ({
        ...prev,
        [selectedTestCode]: prev[selectedTestCode]?.map((field) => {
          if (field.name === "imageData") {
            return { ...field, value: file }; // Update imageData with the selected file
          }
          return field;
        }),
      }));
    }
  };


  useEffect(() => {
    let testname = testDetails[0]?.testname;
    axios
      .get(
        `${urllink}Phelobotomist/get_for_preview_examinations?Visitid=${capturedatas?.Visit_Id}&patientid=${capturedatas.Patient_Id}&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        const groupsData = response.data;
        console.log("groupsData", groupsData);
        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach((group) => {
          const analyseResultDate = new Date(
            group.Updated_At.Sample_Capture_in_Phelobotomist
          );
          const analyseSeniorDate = group.Updated_At.AnalyseInvestigationSenior;
          const approvePathologistDate = new Date(
            group.Updated_At.Approve_Test_Pathologist
          );

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

          if (day.length < 2) day = "0" + day;
          if (month.length < 2) month = "0" + month;
          if (hours.length < 2) hours = "0" + hours;
          if (minutes.length < 2) minutes = "0" + minutes;

          return (
            [day, month, year].join("/") + " " + [hours, minutes].join(":")
          );
        };

        setreport((prev) => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map((group) => {
          return {
            method: group.Method,
            Department: group.Department,
            groupName: group.Group_Name,
            Tests: group.Tests.map((test) => ({
              Test_Name: test.Test_Name || "",
              Method_Name: test.Method_Name || "",
              Department: test.Department || "",
              Sample: test.Specimen || "",
              Samplepicture: test.Samplepic
                ? `data:image/jpeg;base64,${test.Samplepic}`
                : null,
              Content: test.EditContent || "",
            })),
          };
        });

        setsumma(reportsData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [
    testDetails,
    urllink,
    capturedatas?.Visit_Id,
    capturedatas?.Billing_Invoice,
  ]);

  const handleeditdocs = () => {
    let sapObj = {
      editword: content,
      patientid: capturedatas.Patient_Id,
      visitid: capturedatas?.Visit_Id,
      invno: capturedatas?.Billing_Invoice,
    };

    dispatchvalue({ type: "foreditcontent", value: sapObj });
    navigate("/Home/SrLabreportpreview");
    // setisedited('Edited')
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_for_pending_result_department?invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas?.Billing_Invoice, urllink]);
  // console.log('summaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa,', testDetails);
  const handleSubmitAnalyseReport = () => {
    const formData1 = new FormData();
  
    // Append regular data
    formData1.append("Billing_Invoice", capturedatas.Billing_Invoice);
    formData1.append("Visit_Id", capturedatas.Visit_Id);
    formData1.append("Patient_Id", capturedatas.Patient_Id);
  
 // Define testCode here
  
    // Find and set testCode from format field
    
  
    // Iterate over formatData to append fields to formData1
    formatData.forEach((item) => {
      console.log("testCode:", editData);
  
      if (item.name === "imageData") {
        // If the item is an image, append it as a file
        formData1.append(`${editData}_imageData`, item.value); // Append the testCode to differentiate images
      }else if(item.format){
        formData1.append(`${editData}_format`, item.format)
      }
      
      else if (item.name !== "format") {
        // For other items, append their name and value with the testCode prefix (skip format itself)
        formData1.append(`${editData}_${item.name}`, item.value||'');
      }
    });
  
    // Send formData1 to your backend using Axios
    axios
      .post(`${urllink}Phelobotomist/insert_Histo_Template`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        // dispatchvalue({ type: "Navigationlab", value: "" });
        // navigate("/Home/AnalayseReportEntry");
        setopeneditpreview(!openeditpreview)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  
  
  const parseReferenceData = (referenceData) => {
    return referenceData.split(",").map((item) => {
      const [label, value] = item.split(":").map((str) => str.trim());
      let minValue = null;
      let maxValue = null;
      // console.log('value :', label)
      if (value?.includes("<")) {
        maxValue = parseFloat(value.replace("<", ""));
      } else if (value?.includes(">=")) {
        minValue = parseFloat(value.replace(">=", ""));
      } else if (value?.includes("-")) {
        [minValue, maxValue] = value
          .split("-")
          .map((str) => parseFloat(str.trim()));
      } else if (value?.includes(">")) {
        maxValue = value;
      }

      return { label, minValue, maxValue };
    });
  };

  const getStatus = (captureValue, referenceArray) => {
    // console.log(captureValue);
    // console.log(referenceArray);

    for (let ref of referenceArray) {
      // if (captureValue === 0) {
      //     return <span className='indicator' style={{ color: 'green' }}></span>;

      // } else {
      if (ref.label === "Normal") {
        // Normal range
        if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
          return (
            <span className="indicator" style={{ color: "green" }}>
              Normal
            </span>
          );
        } else if (captureValue <= ref.minValue) {
          return (
            <span className="indicator" style={{ color: "blue" }}>
              <TiArrowDownThick />
            </span>
          );
        }
      } else if (ref.label === "Borderline") {
        // Borderline range
        if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
          return (
            <span className="indicator" style={{ color: "blue" }}>
              <TiArrowDownThick />
            </span>
          );
        }
      } else if (ref.label === "High Risk") {
        let highRiskThreshold = null;
        // Determine the high risk threshold
        const minVal = ref.minValue;
        const maxVal = ref.maxValue;

        let minThreshold = null;
        let maxThreshold = null;

        // Parse minValue if it's a string with '>'
        if (typeof minVal === "string" && minVal.includes(">")) {
          minThreshold = parseFloat(minVal.split(">")[1]);
          // minThreshold = minThreshold - 1
        } else if (typeof minVal === "number") {
          minThreshold = minVal;
        } else if (typeof minVal === "string" && minVal.includes(">=")) {
          minThreshold = parseFloat(minVal.split(">=")[1]);
        }

        // Parse maxValue if it's a string with '>'
        if (typeof maxVal === "string" && maxVal.includes(">")) {
          maxThreshold = parseFloat(maxVal.split(">")[1]);
        } else if (typeof maxVal === "number") {
          maxThreshold = maxVal;
        }
        if (typeof maxVal === "string" && maxVal.includes(">=")) {
          maxThreshold = parseFloat(maxVal.split(">=")[1]);
        }

        // Set the highest threshold for high risk
        if (minThreshold !== null && maxThreshold !== null) {
          highRiskThreshold = Math.max(minThreshold, maxThreshold);
        } else if (minThreshold !== null) {
          highRiskThreshold = minThreshold;
        } else if (maxThreshold !== null) {
          highRiskThreshold = maxThreshold;
        }

        // Check if captureValue is above the high risk threshold
        if (highRiskThreshold !== null && captureValue > highRiskThreshold) {
          return (
            <span className="indicator" style={{ color: "red" }}>
              <TiArrowUpThick />
            </span>
          );
        }
        // }
      }
    }
    return "Unknown";
  };

  return (
    <>
      {console.log(department)}
      {console.log(testDetails)}
      {department.map((dept, deptIndex) => (
        <div key={deptIndex}>
          <h3>{dept}</h3>
          <div className="samplecapture_component">
            <div className="Selected-table-container">
              {testDetails.some(
                (p) => p.department === dept && p.Culturetest === "Yes" && p.Test_Code !== "RD0098"
              ) && (
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Specimen</th>
                      <th>Micro Number</th>

                      {!reportnogrowth && !reportot && <th>Colony Count</th>}
                      {!reportnogrowth && !reportot && (
                        <th>Capture Oraganism</th>
                      )}
                      {/* {!culturetests && !ishistopathology &&<th>Capture Value</th>} */}
                      {!reportnogrowth && !reportot && <th>Antibiotic</th>}
                      {/* {!culturetests && !ishistopathology &&<><th>Reference Range</th> */}

                      {reportot && <th>Preview</th>}

                      <th>Status</th>
                      <th>Verification</th>
                      {/* {
                    !hasRecollect?(
                    <th>Reflex</th>
                    ):null
                 }
                */}
                      {/* <th>
                      Trends
                    </th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {testDetails
                      .filter(
                        (p) => p.department === dept && p.Culturetest === "Yes"
                      )
                      .map((detail, index) => (
                        <tr key={index}>
                          <td>{detail.testname}</td>
                          <td>{detail.Sample_Specimen}</td>
                          <td>
                          <input
                                  type="text"
                                  className="Capture_Status_select1"
                                  value={detail.micronumber}
                                  onChange={(e) =>
                                    handleChangemicronumber(detail.testcode, e.target.value, dept)
                                  }
                                />
                          </td>

                          {!reportnogrowth && !reportot && (
                            <>
                              <td>{detail.colony_count}</td>

                              <td>{detail.Capture_Oragnism}</td>
                            </>
                          )}

                          {!reportnogrowth && !reportot && (
                            <td>
                              <span onClick={handleshowculturetest}>
                                <VisibilityIcon />
                              </span>
                            </td>
                          )}

                          {reportot && (
                            <td>
                              <span>
                                <VisibilityIcon onClick={handlepdfpreview1} />
                              </span>
                            </td>
                          )}
                          <td>{detail.Status}</td>
                          <td>
                            <select
                              name="status"
                              id="status"
                              className="Capture_Status_select"
                              value={detail.status}
                              onChange={(e) =>
                                handleStatusChange(index, e.target.value, dept)
                              }
                            >
                              {/* <option value="">Select Status</option> */}
                              <option value="Verified">Verified</option>
                              <option value="Approved">Approved</option>
                              <option value="Retest">Retest</option>
                              <option value="Recollect">Recollect</option>
                            </select>
                          </td>
                          {/* <td>
                        <span className='trends' onClick={() => handletrendview(detail.testname)}>
                          <TbDelta />
                        </span>
                      </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {(dept !== "HISTOPATHOLOGY" &&
!testDetails?.some(
  (p) =>
    p.department === dept &&
    p.Culturetest === "Yes"
) &&
!testDetails?.some(
  (p) =>
    p.department === "HEMATOLOGY" &&
    p.Test_Code === "RD0098"
)) &&  (
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Specimen</th>

                        <th>Capture Value</th>
                        <th>Reference Range</th>
                        <th>Indicator</th>
                        {/* {ishistopathology && <th>Preview</th>} */}
                        <th>
                          Remarks
                          <table className="remarktable">
                            <tr>
                              <th>Technical</th>
                              <th>Medical</th>
                            </tr>
                          </table>
                        </th>
                        <th>Status</th>
                        <th>Verification</th>
                        {/* {
                    !hasRecollect?(
                    <th>Reflex</th>
                    ):null
                 }
                */}
                        <th>Trends</th>
                      </tr>
                    </thead>

                    <tbody>
                      {testDetails
                        .filter((p) => p.department === dept)
                        .map((detail, index) => {
                          const referenceArray = detail.referencedata
                            ? parseReferenceData(detail.referencedata)
                            : [];

                          return (
                            <tr key={index}>
                              <td>{detail.testname}</td>
                              <td>{detail.Sample_Specimen}</td>
                              <td>
                                <input
                                  type="text"
                                  className="Capture_Status_select1"
                                  value={detail.Capture_Value}
                                  onChange={(e) =>
                                    handleChange(index, e.target.value, dept)
                                  }
                                />
                              </td>
                              {detail.reference !== "Yes" ? (
                                <td>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {detail.paniclow}-{detail.panichigh}
                                    {detail.uom}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <div
                                    style={{
                                      width: "90px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {referenceArray.map((ref, refIndex) => (
                                      <div key={refIndex}>
                                        {`${ref.label}: ${
                                          ref.minValue !== null
                                            ? ref.maxValue !== null
                                              ? `${ref.minValue}-${ref.maxValue}`
                                              : `>=${ref.minValue}`
                                            : `${ref.maxValue}`
                                        }`}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              )}
                              {detail.reference !== "Yes" ? (
                                <td>
                                  <span>
                                    {detail.inputtype === "Numeric" &&
                                      ((Number(detail.Capture_Value) >=
                                        detail.panichigh && (
                                        <span
                                          className="indicator"
                                          style={{ color: "red" }}
                                        >
                                          <TiArrowUpThick />
                                        </span>
                                      )) ||
                                        (Number(detail.Capture_Value) <=
                                          detail.paniclow && (
                                          <span
                                            className="indicatorlow"
                                            style={{ color: "blue" }}
                                          >
                                            <TiArrowDownThick />
                                          </span>
                                        )) || (
                                          <p
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "green",
                                            }}
                                          >
                                            Normal
                                          </p>
                                        ))}
                                    {detail.inputtype !== "Numeric" &&
                                      detail.Capture_Value}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span>
                                    {detail.inputtype === "Numeric" &&
                                      getStatus(
                                        Number(detail.Capture_Value),
                                        referenceArray
                                      )}
                                  </span>
                                </td>
                              )}
                              <td>
                                <table className="remarktable">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{detail.Technical_Remark}</p>
                                      </td>
                                      <td>
                                        <p>{detail.Medical_Remark}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>{detail.Status}</td>
                              <td>
                                <select
                                  name="status"
                                  id="status"
                                  className="Capture_Status_select"
                                  value={detail.status}
                                  onChange={(e) =>
                                    handleStatusChange(
                                      index,
                                      e.target.value,
                                      dept
                                    )
                                  }
                                >
                                  <option value="Verified">Verified</option>
                                  <option value="Approved">Approved</option>

                                  <option value="Retest">Retest</option>
                                  <option value="Recollect">Recollect</option>
                                </select>
                              </td>
                              <td>
                                <span
                                  className="trends"
                                  onClick={() =>
                                    handletrendview(detail.Test_Code)
                                  }
                                >
                                  <TbDelta />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              {console.log(dept)}
              {(dept === "HISTOPATHOLOGY" || 
  (dept === "HEMATOLOGY" && testDetails?.some((p) => p.Test_Code === "RD0098"))
) && (
  <table className="selected-medicine-table2">
    <thead>
      <tr>
        <th>Test Name</th>
        <th>Specimen</th>
        <th>Preview</th>
        <th>Edit</th>
        <th>Status</th>
        <th>Verification</th>
        <th>Trends</th>
      </tr>
    </thead>

    <tbody>
      {testDetails
        .filter((p) => p.department === dept)
        .map((detail, index) => (
          <tr key={index}>
            <td>{detail.testname}</td>
            <td>{detail.Sample_Specimen}</td>

            <td>
              <span>
                <VisibilityIcon onClick={() => handlepdfpreview(detail)} />
              </span>
            </td>
            <td>
              <span onClick={() => handleeditpreview(detail)}>
                <EditIcon />
              </span>
            </td>
            <td>{detail.Status}</td>
            <td>
              {detail.Status === "Retest" ? (
                "Retest"
              ) : (
                <select
                  name="status"
                  id="status"
                  className="Capture_Status_select"
                  value={detail.status}
                  onChange={(e) =>
                    handleStatusChange(index, e.target.value, dept)
                  }
                >
                  <option value="Verified">Verified</option>
                  <option value="Approved">Approved</option>
                  <option value="Retest">Retest</option>
                  <option value="Recollect">Recollect</option>
                </select>
              )}
            </td>
            <td>
              <span
                className="trends"
                onClick={() => handletrendview(detail.Test_Code)}
              >
                <TbDelta />
              </span>
            </td>
          </tr>
        ))}
    </tbody>
  </table>
)}
 
            </div>
          </div>
        </div>
      ))}

      {openModel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <LineChart
                width={380}
                height={200}
                data={dataline}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                className="chart_linechart"
                style={{ width: "100%", height: "100%" }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="visit" />
                <YAxis className="yaxis_linechart" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="data"
                  name="Delta Values"
                  stroke="var(--ProjectColor)"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showmodel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setshowmodel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="grid_1">
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={culturevalue.slice(
                    page * pageSize,
                    (page + 1) * pageSize
                  )}
                  columns={dynamicColumns}
                  pageSize={pageSize}
                  pageSizeOptions={[pageSize]}
                  onPageChange={(newPage) => setPage(newPage)}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className="data_grid"
                />
                {showdown > 0 && culturevalue.length > pageSize && (
                  <div className="grid_foot">
                    <button
                      onClick={() =>
                        setPage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page === 0}
                    >
                      Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                      onClick={() =>
                        setPage((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </ThemeProvider>
              <br />
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 style={{ color: "red" }}>Notes</h3>:{" "}
                  <p>{testDetails[0].Notes}</p>
                </div>
              }
              {culturevalue.length === 0 && (
                <div className="IP_norecords">
                  <span>No Records Found</span>
                </div>
              )}
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setshowmodel(false)}
              >
                Close
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={editMode ? handleupdatesencitivity : () => setEditMode(!editData) }
              >
              {editMode?'Update' : 'Edit'  }  
              </button>
            </div>
          </div>
        </div>
      )}

      {openpreview && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Report_master_preview" id={`reactprintcontent`}>
              <div className="Report_master report_preview">
                <div className="New_billlling_invoice_head new_report_con">
                  <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                  </div>
                  <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>
                      <span>{ClinicDetials.ClinicAddress},</span>
                      <span>
                        {ClinicDetials.ClinicCity +
                          "," +
                          ClinicDetials.ClinicState +
                          "," +
                          ClinicDetials.ClinicCode}
                      </span>
                    </div>
                    <div>
                      <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                      <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                      <span>{ClinicDetials.ClinicMailID}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="Register_btn_con"
                  style={{ color: "var(--labelcolor)", fontWeight: 600 }}
                >
                  Test Report
                </div>
                <div className="new_billing_address new_report_address">
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_1">
                          <Barcode
                            value={patinetbillingbarcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Patient Name <span>:</span>
                      </label>
                      <span>{capturedatas.Patient_Name}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Visit ID <span>:</span>
                      </label>
                      <span>{capturedatas.Visit_Id}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Age/Gender <span>:</span>
                      </label>
                      <span>
                        {capturedatas.Age}/{capturedatas.Gender}
                      </span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Phone <span>:</span>
                      </label>
                      <span>{capturedatas.Phone}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reference Doctor <span>:</span>
                      </label>
                      <span>{capturedatas.Refering_Doctor}</span>
                    </div>
                  </div>
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_2">
                          <Barcode
                            value={capturedatas.Barcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Invoice No <span>:</span>
                      </label>
                      <span>{capturedatas.Billing_Invoice}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Collected <span>:</span>
                      </label>
                      <span>{report.Collected}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Received <span>:</span>
                      </label>
                      <span>{report.Received}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reported <span>:</span>
                      </label>
                      <span>{report.Reported}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        {" "}
                        Barcode<span>:</span>
                      </label>
                      <span>{capturedatas.Barcode}</span>
                    </div>
                  </div>
                </div>

                <div className="report_table_oju7">
                  {formatData.length > 0 && formatData[0]?.format ? (
                    <h3>{formatHeading(formatData[0].format)}</h3>
                  ) : (
                    <h3>Report Details</h3>
                  )}

                  <br />

                  <table className="report_table_inner_p">
                    <tbody>
                      {formatData.length > 0 ? (
                        // Filter and map only the items with non-empty values
                        formatData
                          .filter(
                            (item) => item.value && item.value.trim() !== ""
                          )
                          .map((item, index) => (
                            <tr key={index}>
                              <h3>{item.format}</h3>

                              <div className="ewhgdew_o">
                                <div>
                                  <td>
                                    <strong>
                                      {item.name.replace(/_/g, " ")}
                                      <span>:</span>
                                    </strong>
                                  </td>
                                </div>

                                <div className="yudsd_4rdf">
                                  <td>
                                    {item.name
                                      .toLowerCase()
                                      .includes("imagedata") && item.value ? (
                                      // Clean the base64 string and display the image
                                      <img
                                        src={item.value}
                                        alt="Image data"
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    ) : (
                                      <p>{item.value}</p>
                                    )}
                                  </td>
                                </div>
                              </div>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="2">No report data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenpreview(false)}
                >
                  Close
                </button>
                {/* <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleeditdocs()}
                >
                  Edit
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {openpreview1 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview1(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason  hysdttsd_hy67"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Report_master_preview" id={`reactprintcontent`}>
              <div className="Report_master report_preview">
                <div className="New_billlling_invoice_head new_report_con">
                  <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                  </div>
                  <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>
                      <span>{ClinicDetials.ClinicAddress},</span>
                      <span>
                        {ClinicDetials.ClinicCity +
                          "," +
                          ClinicDetials.ClinicState +
                          "," +
                          ClinicDetials.ClinicCode}
                      </span>
                    </div>
                    <div>
                      <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                      <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                      <span>{ClinicDetials.ClinicMailID}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="Register_btn_con"
                  style={{ color: "var(--labelcolor)", fontWeight: 600 }}
                >
                  Test Report
                </div>
                <div className="new_billing_address new_report_address">
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_1">
                          <Barcode
                            value={patinetbillingbarcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Patient Name <span>:</span>
                      </label>
                      <span>{capturedatas.Patient_Name}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Visit ID <span>:</span>
                      </label>
                      <span>{capturedatas.Visit_Id}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Age/Gender <span>:</span>
                      </label>
                      <span>
                        {capturedatas.Age}/{capturedatas.Gender}
                      </span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Phone <span>:</span>
                      </label>
                      <span>{capturedatas.Phone}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reference Doctor <span>:</span>
                      </label>
                      <span>{capturedatas.Refering_Doctor}</span>
                    </div>
                  </div>
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_2">
                          <Barcode
                            value={capturedatas.Barcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Invoice No <span>:</span>
                      </label>
                      <span>{capturedatas.Billing_Invoice}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Collected <span>:</span>
                      </label>
                      <span>{report.Collected}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Received <span>:</span>
                      </label>
                      <span>{report.Received}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reported <span>:</span>
                      </label>
                      <span>{report.Reported}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        {" "}
                        Barcode<span>:</span>
                      </label>
                      <span>{capturedatas.Barcode}</span>
                    </div>
                  </div>
                </div>

                {summa
                  .filter((p) => p.Department === "MICROBIOLOGY")
                  .map((group, index) => (
                    <table
                      className="report_table report_table_for_micro"
                      key={index}
                    >
                      <tbody className="print_body Selected-table-container">
                        <tr>
                          <td>
                            <h3>{group.groupName}</h3>

                            <div className="completed_report_1111">
                              <div className="completed_report hhkk_99ij completed_report04948 gghbuy_o9">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: group.Tests[0]?.Content,
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenpreview1(false)}
                >
                  Close
                </button>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleeditdocs()}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

{openeditpreview && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopeneditpreview(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div ref={contentRef} className="editor_life editor_lkkife">
              <div className="Histo_new_css_div_head">
                <h3>HISTOPATHOLOGY</h3>
                {console.log(selectedTestCode)}

                <div className="hytt_9u76">
                  <div className="Histo_new_css_div_head_po90">
                    {formatData?.slice(0, 4).map((field) => (
                      <div
                        className="Histo_new_css_div first-four"
                        key={field.name}
                      >
                        {!field.format && (
                          <label>
                            {field.name.replace(/_/g, " ")} <span>:</span>
                          </label>
                        )}
                        {/* Only render textarea if field name is not 'format' */}
                        {!field.format ? (
                          <textarea
                            value={field.value || ""}
                            onChange={(e) =>
                              handleTextareaChange(e, field.name)
                            }
                            onInput={autoResizeTextarea}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="Histo_new_css_div_head_po90">
                  {formatData?.slice(4).map((field) => (
                    <div className="Histo_new_css_div" key={field.name}>
                      {!field.format && (
                        <label>
                          {field.name.replace(/_/g, " ")} <span>:</span>
                        </label>
                      )}
                      {field.name === "imageData" ? (
                        <div className="Histo_new_css_div_uHHHFH">
                      
                          
                          {field.value && (
                            <img
                              src={field.value}
                              alt="Preview"
                              accept="image/*"
                              style={{
                                marginTop: "10px",
                                maxWidth: "170px",
                                borderRadius: "4px",
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        !field.format && ( // Prevent rendering textarea for 'format'
                          <textarea
                            value={field.value || ""}
                            onChange={(e) =>
                              handleTextareaChange(e, field.name)
                            }
                            onInput={autoResizeTextarea}
                          />
                          
                        )
                      )}
                    </div>
                  ))}
                </div>

                <div className="Register_btn_con regster_btn_contsai">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => {
                      setopeneditpreview(false);
                    }}
                  >
                    Close
                  </button>
                   <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
          Save
        </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}


      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleverifydata}>
          Save
        </button>
      </div>
    </>
  );
}

export default Reportverify;
