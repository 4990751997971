
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import axios from "axios";
import './Neww.css';
import Button from '@mui/material/Button';
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const ExternalLabList = () => {

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    const [page, setPage] = useState(0);
    const pageSize = 10;
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const paginatedData = filteredRows.slice(page * pageSize, (page + 1) * pageSize);
    const urllink = useSelector(state => state.userRecord?.UrlLink)
    const dispatchValue = useDispatch();


    const fetch_externallab_list = useCallback(() => {
        axios.get(`${urllink}usercontrol/getexternallablist`)
            .then((response) => {
                console.log(response)
                const data = response.data.map((row) => ({
                    id: row.S_No,
                    ...row,
                }));
                setsumma(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [urllink])

    useEffect(() => {
        fetch_externallab_list()
    }, [fetch_externallab_list]);



    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.Lab_Name ? row.Lab_Name.toLowerCase() : '';
            return lowerCaseSupplierName.includes(lowerCaseQuery);
        });
        setFilteredRows(filteredData);
    }, [searchQuery, summa]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };



    const handlenavigate = () => {
        dispatchValue({ type: 'ExternalLabdata', value: [] })
        navigate('/Home/ExternalLabmaster')
    }


    const handleEditClick = (params) => {
        console.log(params);
        axios.get(`${urllink}usercontrol/get_external_labdata_forupdate?Labcode=${params.Labcode}`)
            .then((res) => {
                console.log(res)
                dispatchValue({ type: 'ExternalLabdata', value: res.data })
                navigate('/Home/ExternalLabmaster')

            })
            .catch((err) => {
                console.error(err);

            })
    };



    const hadleeditstatus = (params) => {
        console.log(params)

        let newstatus;
        if (params.Status === 'Active') {
            newstatus = 'Inactive'
        } else if (params.Status === 'Inactive') {
            newstatus = 'Active'
        }

        axios.post(`${urllink}usercontrol/update_externallabstatus?Labcode=${params.Labcode}&newstatus=${newstatus}`)
            .then((res) => {
                console.log(res)
                fetch_externallab_list()

            })

            .catch((err) => {
                console.error();

            })
    }


    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>Lab List</h3>
                </div>
                <div className="con_1 ">
                    <div className="inp_1">
                        <label htmlFor="input">Lab Name :</label>
                        <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter Lab Name' />
                    </div>
                    <button className='btn_1' type='submit' onClick={handlenavigate}>
                        <AddCircleOutlineIcon />
                    </button>
                </div>
                <div className="Selected-table-container">
                    <table className="selected-medicine-table2">
                        <thead>
                            <tr>
                                <th id="slectbill_ins">S.No</th>
                                <th id="slectbill_ins">Lab Name</th>
                                <th id="slectbill_ins">Register No</th>
                                <th id="slectbill_ins">Phone No</th>
                                <th id="slectbill_ins">Location</th>
                                <th id="slectbill_ins">Source</th>
                                <th id="slectbill_ins">Status</th>
                                <th id="slectbill_ins">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.map((lab, index) => (
                                <tr key={index}>
                                    <td>{lab.S_No}</td>
                                    <td>{lab.Lab_Name}</td>
                                    <td>{lab.Register_No}</td>
                                    <td>{lab.Phone_No}</td>
                                    <td>{lab.Location}</td>
                                    <td>{lab.SourceType}</td>
                                    <td>
                                        <button
                                            onClick={() => hadleeditstatus(lab)}
                                            className="Addnamebtn_pt2"
                                        >
                                            {lab.Status}
                                        </button>
                                    </td>
                                    <td>
                                        <Button
                                            size="small"
                                            onClick={() => handleEditClick(lab)}
                                            startIcon={<EditIcon />}
                                        >

                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <div className="grid_foot">
                        <button
                            onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                            }
                            disabled={page === 0}
                        >
                            Previous
                        </button>
                        Page {page + 1} of {totalPages}
                        <button
                            onClick={() =>
                                setPage((prevPage) =>
                                    Math.min(prevPage + 1, totalPages - 1)
                                )
                            }
                            disabled={page === totalPages - 1}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default ExternalLabList;
