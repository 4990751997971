import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from 'jspdf';
import 'jspdf-autotable';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});


const PaymentHistory = () => {
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const urllink = useSelector(state => state.userRecord?.UrlLink)

  // const [ClinicDetials, setClinicDetials] = useState({
  //   ClinicLogo: null,
  //   ClinicName: '',
  //   ClinicGST: '',
  //   ClinicAddress: '',
  //   ClinicCity: '',
  //   ClinicState: '',
  //   ClinicCode: '',
  //   ClinicMobileNo: '',
  //   ClinicLandLineNo: '',
  //   ClinicMailID: '',
  //   InvoiceNo: ''
  // });




  const dynamicColumns = [
    { field: 'id', headerName: 'S_NO', width: 130 },
    { field: 'Billing_Invoice', headerName: 'Billing Invoice', width: 130 },
    { field: 'Patient_Id', headerName: 'Patient Id', width: 120 },
    { field: 'Patient_Name', headerName: 'Patient Name', width: 150 },
    { field: 'Visit_Id', headerName: 'Visit Id', width: 100 },
    { field: 'Phone', headerName: 'Phone', width: 110 },
    { field: 'Refering_Doctor', headerName: 'Reference Doctor', width: 120 },
    { field: 'Total_Charge', headerName: 'Charge', width: 100 },
    { field: 'Discount_Type', headerName: 'Discount Type', width: 140 },
    { field: 'Discount', headerName: 'Discount', width: 140 },
    { field: 'Net_Amount', headerName: 'Net Amount', width: 140 },
    { field: 'Cash', headerName: 'Cash', width: 150 },
    { field: 'Card', headerName: 'Card', width: 150 },
    { field: 'OnlinePayment', headerName: 'Online Payment', width: 150 },
    { field: 'Status', headerName: 'Bill Status', width: 130 },

  ];
  const [serachBranch, setserachBranch] = useState([]);
  const [GETData, setGETData] = useState({
    Location: '',
    BilledBy: "ALL",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });
  useEffect(() => {
    // const username = userRecord.username;
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setserachBranch(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, [urllink]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setGETData({
          ...GETData,
          CurrentDate: format(new Date(), "yyyy-MM-dd"),
          [name]: value,
        });
      } else {
        setGETData({
          ...GETData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setGETData({
        ...GETData,
        [name]: value,
      });
    }
  };

  //   const forPrintData = () => {
  //     return (
  //         <div className="billing-invoice" id="reactprintcontent">
  //             <div className="New_billlling_invoice_head">
  //                 <div className="new_billing_logo_con">
  //                     <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
  //                 </div>
  //                 <div className="new_billing_address_1 ">
  //                     <span>{ClinicDetials.ClinicName}</span>
  //                     <div>

  //                         <span >{ClinicDetials.ClinicAddress},</span>
  //                         <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
  //                     </div>
  //                     <div>
  //                         <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
  //                         <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
  //                         <span>{ClinicDetials.ClinicMailID}</span>
  //                     </div>

  //                 </div>

  //             </div>
  //             <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
  //                 Billing Invoice
  //             </div>
  //             <div className='Register_btn_con_barcode'>
  //             <Barcode value={barcode || ''} lineColor="black" height={40} width={1.2} fontSize={12} />
  //           </div>

  //             <div className="new_billing_address">
  //                 <div className="new_billing_address_2">
  //                     <div className="new_billing_div">
  //                         <label>Patient Name <span>:</span></label>
  //                         <span>{patientdetails.patientname}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Visit ID <span>:</span></label>
  //                         <span>{visitid}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Age <span>:</span></label>
  //                         <span>{patientdetails.age}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Gender <span>:</span></label>
  //                         <span>{patientdetails.gender}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Address <span>:</span></label>
  //                         <span>{patientdetails.Address}</span>
  //                     </div>

  //                 </div>

  //                 <div className="new_billing_address_2">
  //                     <div className="new_billing_div">
  //                         <label>Invoice No <span>:</span></label>
  //                         <span>{invoiceno}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>GSTIN No <span>:</span></label>
  //                         <span>{ClinicDetials.ClinicGST}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Physician Name <span>:</span></label>
  //                         <span>{patientdetails.Referingdoctor}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label>Date <span>:</span></label>
  //                         <span>{Billing_date}</span>
  //                     </div>
  //                     <div className="new_billing_div">
  //                         <label> Patient Mobile No <span>:</span></label>
  //                         <span>{patientdetails.PhoneNo}</span>
  //                     </div>
  //                 </div>
  //             </div>


  //             <div className="new_billing_invoice_detials">
  //                 <table>
  //                     <thead>
  //                         <tr>
  //                             <th>S.No</th>
  //                             <th>Test Method</th>
  //                             <th>Service Name</th>
  //                             {/* <th>Unit</th> */}
  //                             <th>Amount</th>
  //                             {/* <th>Discount</th>
  //                             <th>Total</th> */}
  //                         </tr>
  //                     </thead>
  //                     <tbody>

  //                     {testdetails.map((row, index) => (
  //                       <tr key={index}>
  //                         <td>{index + 1}</td>{" "}
  //                         <td>{row.test_code}</td>
  //                         <td>{row.test_name}</td>

  //                         <td>{row.cost_per_reportable}</td>

  //                       </tr>
  //                     ))}
  //                     </tbody>
  //                 </table>
  //             </div>
  //             <div className="new_billing_invoice_detials " style={{ paddingBottom: '10px', height: 'auto' }}>

  //                 <div className="invoice_detials_total_1 neww_invoicedetials">
  //                     <div className="total_con_bill">
  //                         <div className="bill_body">
  //                             <label> Items Count <span>:</span></label>
  //                             <span>{testdetails.length}</span>
  //                         </div>
  //                         {/* <div className="bill_body">
  //                             <label> Unit <span>:</span></label>
  //                             <span>{orderdetails.totalUnits}</span>
  //                         </div> */}
  //                         <div className="bill_body">
  //                             <label> Amount <span>:</span></label>
  //                             <span>{orderdetails.grossAmount}</span>
  //                         </div>
  //                         <div className="bill_body">
  //                             <label> Discount <span>:</span></label>
  //                             <span>{initialState.totalDiscount}</span>
  //                         </div>

  //                     </div>
  //                     <div className="total_con_bill">
  //                         <div className="bill_body">
  //                             <label> Billed By <span>:</span></label>
  //                             <span>admin</span>
  //                         </div>




  //                         <div className="bill_body">
  //                             <label>Net Amount <span>:</span></label>
  //                             <span>{orderdetails.netAmount}</span>
  //                         </div>
  //                         <div className="bill_body">
  //                             <label> Payment type <span>:</span></label>
  //                             <span>{orderdetails.paymentMethod} </span>
  //                         </div>
  //                         <div className="bill_body">
  //                             <label> Recived <span>:</span></label>
  //                             <span>{(initialState.totalrecived)} </span>
  //                         </div>


  //                     </div>

  //                 </div>
  //                 <div className="invoice_detials_total_1 neww_invoicedetials">
  //                     <div className='total_con_bill'>
  //                         <div className="bill_body" style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px' }}>
  //                             <label> Amount In Words<span>:</span></label>
  //                             <span style={{ color: 'grey' }}>{numberToWords(+orderdetails.netAmount)} </span>
  //                         </div>
  //                     </div>
  //                 </div>

  //                 <div className="signature-section909">
  //                     <p className='disclaimer23'>
  //                         This page is created automatically without a signature.
  //                     </p>
  //                 </div>
  //             </div>

  //         </div>
  //     );
  // }


  const handletoSearch = () => {
    let req = ['Location', 'BilledBy', 'DateType']
    if (GETData.DateType === 'Customize') {
      req = ['FromDate', 'ToDate']
    }
    const exist = req.filter((f) => !GETData[f])
    if (exist.length === 0) {

      axios.get(`${urllink}Billing/getpatienthistorydata?DateType=${GETData.DateType}&FromDate=${GETData.FromDate}&ToDate=${GETData.ToDate}&CurrentDate=${GETData.CurrentDate}&BilledBy=${GETData.BilledBy}&Location=${GETData.Location}`)
        .then((response) => {
          if (response.data?.error) {
            alert(response.data.error)
            setFilteredRows([])
          } else {
            const data = response.data || []
            console.log(data);
            setFilteredRows(data.map((row, index) => ({
              id: index + 1,
              ...row,
            })));

          }

        })
        .catch((error) => {
          console.error('Error fetching payment history data:', error);
        });
    } else {
      alert(`plese Fill the required Fields : ${exist.join(',')}`)
    }

  }
  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "S.NO", dataKey: "id" },
        { header: "Billing Invoice", dataKey: "Billing_Invoice" },
        { header: "Patient Id", dataKey: "Patient_Id" },
        { header: "Patient Name", dataKey: "Patient_Name" },
        { header: "Visit Id", dataKey: "Visit_Id" },
        { header: "Phone", dataKey: "Phone" },
        { header: "Reference Doctor", dataKey: "Refering_Doctor" },
        { dataKey: 'Discount_Type', header: 'Discount Type' },
        { dataKey: 'Discount', header: 'Discount' },
        { dataKey: 'Net_Amount', header: 'Net Amount' },
        { dataKey: 'Cash', header: 'Cash' },
        { dataKey: 'Card', header: 'Card' },
        { dataKey: 'OnlinePayment', header: 'Online Payment' },
        { dataKey: 'Status', header: 'Bill Status' },
        { dataKey: 'Total_Charge', header: 'Charge' },
      ]

      const header = columns.map(col => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map(row => columns.map(col => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, `${format(new Date(), "yyyy-MM-dd")}Report.csv`);
    } else {
      alert("No Data to Save");
    }
  };


  // const handlePDF = () => {
  //   if (filteredRows.length !== 0) {
  //     const doc = new jsPDF();
  //     const columns = [
  //       { header: "S.NO", dataKey: "id" },
  //       { header: "Billing Invoice", dataKey: "Billing_Invoice" },
  //       { header: "Patient Id", dataKey: "Patient_Id" },
  //       { header: "Patient Name", dataKey: "Patient_Name" },
  //       { header: "Visit Id", dataKey: "Visit_Id" },
  //       { header: "Phone", dataKey: "Phone" },
  //       { header: "Reference Doctor", dataKey: "Refering_Doctor" },
  //       { dataKey: 'Discount_Type', header: 'Discount Type' },
  //       { dataKey: 'Discount', header: 'Discount' },
  //       { dataKey: 'Net_Amount', header: 'Net Amount' },
  //       { dataKey: 'Cash', header: 'Cash' },
  //       { dataKey: 'Card', header: 'Card' },
  //       { dataKey: 'OnlinePayment', header: 'Online Payment' },
  //       { dataKey: 'Status', header: 'Bill Status' },
  //       { dataKey: 'Total_Charge', header: 'Charge' },
  //     ];


  //     const columnStyles = {
  //       id: { width: 10 },
  //       Billing_Invoice: { width: 80 },
  //       Patient_Id: { width: 70 },
  //       Patient_Name: { width: 70 },
  //       Visit_Id: { width: 70 },
  //       Phone: { width: 90 },
  //       Refering_Doctor: { width: 90 },
  //       Discount_Type: { width: 100 },
  //       Discount: { width: 50 },
  //       Net_Amount: { width: 80 },
  //       Cash: { width: 50 },
  //       Card: { width: 50 },
  //       OnlinePayment: { width: 100 },
  //       Status: { width: 60 },
  //       Total_Charge: { width: 70 },
  //     };



  //     const pageWidth = doc.internal.pageSize.getWidth();
  //     doc.setFontSize(12);




  //     doc.autoTable({ columns, body: filteredRows, startY: 30, styles: columnStyles });

  //     const pdfBlob = doc.output('blob');
  //     saveAs(pdfBlob, `${format(new Date(), "yyyy-MM-dd")}data.pdf`);
  //   } else {
  //     alert('No Data to save')
  //   }
  // };


  const handlePDF = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "S.NO", dataKey: "id" },
        { header: "Billing Invoice", dataKey: "Billing_Invoice" },
        { header: "Patient Id", dataKey: "Patient_Id" },
        { header: "Patient Name", dataKey: "Patient_Name" },
        { header: "Visit Id", dataKey: "Visit_Id" },
        { header: "Phone", dataKey: "Phone" },
        { header: "Reference Doctor", dataKey: "Refering_Doctor" },
        { dataKey: 'Discount_Type', header: 'Discount Type' },
        { dataKey: 'Discount', header: 'Discount' },
        { dataKey: 'Net_Amount', header: 'Net Amount' },
        { dataKey: 'Cash', header: 'Cash' },
        { dataKey: 'Card', header: 'Card' },
        { dataKey: 'OnlinePayment', header: 'Online Payment' },
        { dataKey: 'Status', header: 'Bill Status' },
        { dataKey: 'Total_Charge', header: 'Charge' },
      ];


      const doc = new jsPDF({ orientation: 'landscape' });

      // Title
      doc.setFontSize(18);
      doc.text("Payment History", 14, 22);

      const totalNetSalary = filteredRows.reduce((sum, row) => {
        return sum + (row.Total_Charge ? parseFloat(row.Total_Charge) : 0);
      }, 0);

      // Define autoTable options
      const autoTableOptions = {
        startY: 30,
        head: [columns.map(col => col.header)],
        body: [...filteredRows.map(row => columns.map(col => (row[col.dataKey] != null && row[col.dataKey] !== '') ? row[col.dataKey].toString() : '-')),
        columns.map((col, index) => index === 14 ? { content: `Total: ${totalNetSalary.toFixed(2)}`, styles: { fontStyle: 'bold', fontSize: 14 } } : '') // Add the total row with bold style and increased font size

        ],
        theme: 'grid',
        styles: { fontSize: 10 },
        headStyles: { fillColor: [173, 216, 230],textColor: [0, 0, 0] },
        columnStyles: columns.reduce((styles, col, index) => {
          styles[index] = { cellWidth: 'auto' };
          return styles;
        }, {})
      };

      // Create table
      doc.autoTable(autoTableOptions);

      // Convert to Blob and use saveAs
      const blob = doc.output('blob');
      saveAs(blob, `Paymenthistory_${format(new Date(), "yyyy-MM-dd")}.pdf`);
    } else {
      alert('No Data to Save');
    }
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Payment History</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="Location">
              Location <span>:</span>
            </label>
            <select
              name="Location"
              id="Location"
              value={GETData.Location}
              onChange={handleChange}
            >
              <option value="">Select</option>{" "}
              {/* Optional: add a default "select" option */}
              {serachBranch.map((loc) => (
                <option key={loc.location_id} value={loc.location_name}>
                  {loc.location_name}
                </option>
              ))}
            </select>
          </div>



          <div className="RegisForm_1">
            <label htmlFor="">
              Date Type <span>:</span>
            </label>
            <select
              name="DateType"
              value={GETData.DateType}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="Current">Current Date</option>
              <option value="Customize">Customize</option>
            </select>
          </div>

          {GETData.DateType === "Customize" && (
            <div className="RegisForm_1">
              <label htmlFor="">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={GETData.FromDate}
                onChange={handleChange}
              />
            </div>
          )}

          {GETData.DateType === "Customize" && (
            <div className="RegisForm_1">
              <label htmlFor="ToDate">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={GETData.ToDate}
                onChange={handleChange}
              />
            </div>
          )}
        </div>

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handletoSearch}>
            Search
          </button>
        </div>
        <div className='grid_1'>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns}
              pageSize={pageSize}
              pageSizeOptions={[pageSize]}
              onPageChange={(newPage) => setPage(newPage)}
              hideFooterPagination
              hideFooterSelectedRowCount
              className='data_grid'
            />
            {filteredRows.length > pageSize && (
              <div className='grid_foot'>
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {Math.ceil(filteredRows.length / pageSize)}
                <button
                  onClick={() => setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                  disabled={page === Math.ceil(filteredRows.length / pageSize) - 1}
                >
                  Next
                </button>
              </div>
            )}
          </ThemeProvider>
          {filteredRows.length === 0 && (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
        </div>

        {filteredRows.length !== 0 && (
          <div className="PrintExelPdf">
            <button onClick={handleExportToExcel}>Save Excel</button>
            <button onClick={handlePDF}>Save PDF</button>

          </div>
        )}
      </div>
    </>
  );
}

export default PaymentHistory;
