
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";

function AntibioticMaster() {
  const urllink = useSelector(state => state.userRecord?.UrlLink)
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [antibioticData, setAntibioticData] = useState([]);
  const [antibioticGroupCode, setAntibioticGroupCode] = useState('');
  const [antibioticDes, setAntibioticDes] = useState('');
  const [antibioticCode, setAntibioticCode] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const [SelectedFile, setSelectedFile] = useState(null);


  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };


  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const handleSubmitAntibiotic = async () => {
    if (!antibioticGroupCode.trim() || !antibioticCode.trim() || !antibioticDes.trim()) {
      alert(' AntibioticGroupCode,AntibioticCode and AntibioticDes are required.');
      return; // Exit the function early if validation fails
    }
    try {

      const response = await axios.post(`${urllink}usercontrol/insertantibioticdata`, {
        antibioticGroupCode,
        antibioticCode,
        antibioticDes
      });

      console.log(response.data);
      setAntibioticGroupCode('');
      setAntibioticCode('');
      setAntibioticDes('');
      fetchAntibioticData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchAntibioticData = () => {
    axios.get(`${urllink}usercontrol/getantibioticdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setAntibioticData(data);
      })
      .catch((error) => {
        console.error('Error fetching antibioticgroup data:', error);
        setAntibioticData([]); // Reset data in case of an error
      });
  };

  const handleEdit = (row) => {
    console.log(row)
    setAntibioticGroupCode(row.anti_biotic_group_code);
    setAntibioticCode(row.anti_biotic_code);
    setAntibioticDes(row.anti_biotic_des);
    setIsEditMode(true);
    setSelectedMethodId(row.anti_biotic_group_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(`${urllink}usercontrol/updateantibioticmaster`, {
        method_id: selectedMethodId,
        method_name: antibioticGroupCode,
        method_code: antibioticCode,
        method_des: antibioticDes
      });

      console.log(response.data);
      setAntibioticGroupCode('');
      setAntibioticCode('');
      setAntibioticDes('');
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchAntibioticData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Documents") {
        axios
          .post(
            `${urllink}usercontrol/insert_csv_file_for_antibiotic`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            // errmsg(error);
          });
      }
    }
  };

  useEffect(() => {
    fetchAntibioticData();
  }, []);

  const totalPages = Math.ceil(antibioticData.length / pageSize);


  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Antibiotic Matser</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">

            <label htmlFor="input" >Antibiotic Group Code:</label>
            <input
              type="text"
              id="antibioticGroupCode"
              name="antibioticGroupCode"
              value={antibioticGroupCode}
              onChange={(e) => setAntibioticGroupCode(e.target.value)}
            // placeholder="Enter Antibiotic Group Code"
            // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="input" >Antibiotic Code:</label>
            <input
              type="text"
              id="antibioticCode"
              name="antibioticCode"
              value={antibioticCode}
              onChange={(e) => setAntibioticCode(e.target.value)}
            // placeholder="Enter Antibiotic Code"
            // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="input" >Antibiotic Description:</label>
            <input
              type="text"
              id="antibioticDes"
              name="antibioticDes"
              value={antibioticDes}
              onChange={(e) => setAntibioticDes(e.target.value)}
            // placeholder="Enter Antibiotic Description"
            // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label>
              {" "}
              Upload CSV File <span>:</span>{" "}
            </label>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              id="Servicechoose"
              required
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label
              htmlFor="Servicechoose"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
            <button
              className="RegisterForm_1_btns choose_file_update"
              onClick={() => handleCsvupload("Documents")}
            >
              Upload
            </button>
          </div>


        </div>
        <div className="Register_btn_con">
          <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitAntibiotic}>
            {isEditMode ? 'Update' : <AddIcon />}
          </button>
        </div>
        <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
          <h4>Table</h4>
          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Antibiotic Group Code</th>
                  <th>Antibiotic Code</th>
                  <th>Antibiotic Description</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(antibioticData) && antibioticData.length > 0 ? (
                  antibioticData.slice(page * pageSize, (page + 1) * pageSize).map((row, index) => (
                    <tr key={index}>
                      <td>{row.anti_biotic_group_id}</td>
                      <td>{row.anti_biotic_group_code}</td>
                      <td>{row.anti_biotic_code}</td>
                      <td>{row.anti_biotic_des}</td>
                      <td><button onClick={() => handleEdit(row)}><EditIcon /></button></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>No data available</td>
                  </tr>
                )}
              </tbody>
            </table>

            {antibioticData.length > pageSize && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        <ToastContainer />

      </div>
    </>
  );
}

export default AntibioticMaster;

