import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: " var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid   var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PurchaseReturnLocMaster = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);
  const [GRNinvoiceData, setGRNinvoiceData] = useState([]);

  const [isedit, setisedit] = useState(null);

  const yourStyles = {
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }

  const [PurchaseReturnForms, setPurchaseReturnForms] = useState({
    ProductCategory: "",
    GRN_Invoice_NO: "",
    GRN_Date: "",
    SupplierCode: "",
    SupplierName: "",
    Supplier_Bill_No: "",
    Supplier_Bill_Date: "",
    Bill_Document: "",
    Total_Discount_Type: "",
    Total_Discount: "",
    TotalAmount: "",
    Reason: "",
    RoundOff:"",
  });

  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Name: "",
    Generic_Name: "",
    Strength: "",
    UOM: "",
    Pack_type: "",
    Pack_Quantity: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    Purchase_Rate: "",
    Return_Quantity: "",
    TotalAmount: "",
  });
  const [PurchaseRecieveFormData1, setPurchaseRecieveFormData1] = useState({
    Item_Name: "",
    Pack_type: "",
    Pack_Quantity: "",
    Purchase_Rate: "",
    Return_Quantity: "",
    TotalAmount: "",
  });

  const [datalist1, setdatalist1] = useState([]);
  const [billdoc, setBillDoc] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [productInvoice, setproductInvoice] = useState("");
  const [ItemCode, setItemCode] = useState("");
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown1 = datalist1.length;
  const totalPages1 = Math.ceil(datalist1.length / 10);

  
  
  const dynamicColumns1 = () => {
    if (
      PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
      PurchaseReturnForms.ProductCategory === "MedicalNonConsumable"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 100 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 150 },
        { field: "Purchase_Rate", headerName: "PurchaseRate", width: 150 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 150 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else if (
      PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
      PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
      PurchaseReturnForms.ProductCategory === "Surgical"
    ) {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 150 },
        { field: "Purchase_Rate", headerName: "PurchaseRate", width: 150 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 150 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Pack_Quantity", headerName: "Pack Quantity", width: 170 },
        { field: "Purchase_Rate", headerName: "PurchaseRate", width: 170 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };


  
  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }
  
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;
  
    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };
  

  

  useEffect(() => {
    let TotalAmount = 0;

    datalist1.forEach((p) => {
      TotalAmount += +p.TotalAmount;
    });
    console.log(TotalAmount);

    let roundedAmount = Math.round(TotalAmount).toFixed(2)

    let difference = roundedAmount - TotalAmount


    setPurchaseReturnForms((prev) => ({
      ...prev,
      TotalAmount:roundedAmount,
      RoundOff:difference.toFixed(2)
    }));
  }, [datalist1, datalist1.length]);

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };

  const handleRequestEdit = (params) => {
    const { Item_Code, ...rest } = params;
    setPurchaseRecieveFormData({ ...rest });
    setItemCode(Item_Code);
    setisedit(params);
  };
  const handleRequestEdit1 = (params) => {
    const { Item_Code, ...rest } = params;
    setPurchaseRecieveFormData1({ ...rest });
    setItemCode(Item_Code);
    setisedit(params);
  };

  const handleSubmit = () => {
    const requiredfields = [
      "ProductCategory",
      "GRN_Invoice_NO",
      "GRN_Date",
      "SupplierCode",
      "SupplierName",
      "Supplier_Bill_No",
      "Supplier_Bill_Date",
      "TotalAmount",
      "Reason",
    ];
    const missingFields = requiredfields.filter(
      (field) => !PurchaseReturnForms[field]
    );

    if (missingFields.length === 0) {
      axios
        .get(
          `${urllink}SupplierMaster/get_purchase_Return_invoice_no?productType=${PurchaseReturnForms.ProductCategory}`
        )
        .then((response) => {
          // console.log(response.data);
          const ResponseInvoiceNo = response.data.nextInvoiceNo;

          console.log("222", ResponseInvoiceNo);

          if (ResponseInvoiceNo !== "") {
            const {
              Bill_Document,
              Total_Discount_Type,
              Total_Discount,
              ...rest
            } = PurchaseReturnForms;
            const data = {
              ...rest,
              InvoiceNo: ResponseInvoiceNo,
              Created_By: userRecord?.username,
              Location: 'CentralStore',
              Approved_By: userRecord?.username,
              Items: [...datalist1],
            };

            axios
              .post(
                `${urllink}SupplierMaster/Insert_Purchase_return_detials`,
                data
              )
              .then((response) => {
                console.log(response.data);
                handleclear();
                setPurchaseReturnForms({
                  ProductCategory: "",
                  GRN_Invoice_NO: "",
                  GRN_Date: "",
                  SupplierCode: "",
                  SupplierName: "",
                  Supplier_Bill_No: "",
                  Supplier_Bill_Date: "",
                  Bill_Document: "",
                  Total_Discount_Type: "",
                  Total_Discount: "",
                  TotalAmount: "",
                  Reason: "",
                  RoundOff:"",
                });
                setdatalist([]);
                setdatalist1([]);
                setGRNinvoiceData([]);
                setproductInvoice("");
              })
              .catch((error) => {
                console.log(error);
              });

            console.log(data);
          } else {
            // Handle the case where the invoice is empty
            console.log("Invoice is empty. Waiting for a valid invoice.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleclear = () => {
    setPurchaseRecieveFormData({
      Item_Name: "",
      Generic_Name: "",
      Strength: "",
      UOM: "",
      Pack_type: "",
      Pack_Quantity: "",
      HSNCode: "",
      BatchNo: "",
      ManufactureDate: "",
      ExpiryDate: "",
      Purchase_Rate: "",
      Return_Quantity: "",
      TotalAmount: "",
    });
    setPurchaseRecieveFormData1({
      Item_Name: "",
      Pack_type: "",
      Pack_Quantity: "",
      Purchase_Rate: "",
      Return_Quantity: "",
      TotalAmount: "",
    });
    setItemCode("");
  };
  const handleAdd = () => {
    if (PurchaseReturnForms.ProductCategory) {
      if (
        PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
        PurchaseReturnForms.ProductCategory === "MedicalNonConsumable"
      ) {
        const requiredfields = [
          "Item_Name",
          "Generic_Name",
          "Strength",
          "UOM",
          "Pack_type",
          "Pack_Quantity",
          "HSNCode",
          "BatchNo",
          "ManufactureDate",
          "ExpiryDate",
          "Purchase_Rate",
          "TotalAmount",
        ];
        const missingFields = requiredfields.filter(
          (field) => !PurchaseRecieveFormData[field]
        );

        if (missingFields.length === 0) {
          const data = {
            id: datalist1.length + 1,
            Item_Code: ItemCode,
            ...PurchaseRecieveFormData,
          };
          console.log(data);
          // Check if Item_Code already exists in the datalist1
          const itemAlreadyExists = datalist1.some(
            (item) =>
              item.Item_Code === data.Item_Code && item.BatchNo === data.BatchNo
          );
          console.log(itemAlreadyExists);
          if (!itemAlreadyExists) {
            setdatalist1((prev) => [...prev, data]);
          } else {
            alert("Item Already Exists");
          }
          handleclear();
        } else {
          alert(
            `Please fill out all required fields: ${missingFields.join(", ")}`
          );
        }
      } else if (
        PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
        PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
        PurchaseReturnForms.ProductCategory === "Surgical"
      ) {
        const requiredfields = [
          "Item_Name",
          "Generic_Name",
          "Pack_type",
          "Pack_Quantity",
          "HSNCode",
          "BatchNo",
          "ManufactureDate",
          "ExpiryDate",
          "Purchase_Rate",
          "TotalAmount",
        ];
        const missingFields = requiredfields.filter(
          (field) => !PurchaseRecieveFormData[field]
        );

        if (missingFields.length === 0) {
          const data = {
            id: datalist1.length + 1,
            Item_Code: ItemCode,
            ...PurchaseRecieveFormData,
          };
          console.log(data);
          // Check if Item_Code already exists in the datalist1
          const itemAlreadyExists = datalist1.some(
            (item) =>
              item.Item_Code === data.Item_Code && item.BatchNo === data.BatchNo
          );
          console.log(itemAlreadyExists);
          if (!itemAlreadyExists) {
            setdatalist1((prev) => [...prev, data]);
          } else {
            alert("Item Already Exists");
          }
          handleclear();
        } else {
          alert(
            `Please fill out all required fields: ${missingFields.join(", ")}`
          );
        }
      } else {
        const requiredfields = [
          "Item_Name",
          "Pack_type",
          "Pack_Quantity",
          "Purchase_Rate",
          "TotalAmount",
        ];
        const missingFields = requiredfields.filter(
          (field) => !PurchaseRecieveFormData1[field]
        );

        if (missingFields.length === 0) {
          const data = {
            id: datalist1.length + 1,
            Item_Code: ItemCode,
            ...PurchaseRecieveFormData1,
          };

          // Check if Item_Code already exists in the datalist1
          const itemAlreadyExists = datalist1.some(
            (item) => item.Item_Code === data.Item_Code
          );

          if (!itemAlreadyExists) {
            setdatalist1((prev) => [...prev, data]);
          } else {
            alert("Item Already Exists");
          }
          handleclear();
        } else {
          alert(
            `Please fill out all required fields: ${missingFields.join(", ")}`
          );
        }
      }
    }
  };
  const handleUpdate = () => {
    if (PurchaseReturnForms.ProductCategory) {
      const BatchNo =
        PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "MedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "Surgical"
          ? PurchaseRecieveFormData.BatchNo
          : "";

      // Find the index of the item with the same Item_Code in datalist1
      const itemIndex = datalist1.findIndex((item) =>
        PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "MedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "Surgical"
          ? item.Item_Code === ItemCode && item.BatchNo === BatchNo
          : item.Item_Code === ItemCode
      );

      if (itemIndex !== -1) {
        // Check if the item exists in datalist1

        let requiredfields;
        if (
          PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "MedicalNonConsumable"
        ) {
          requiredfields = [
            "Item_Name",
            "Generic_Name",
            "Strength",
            "UOM",
            "Pack_type",
            "Pack_Quantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "Purchase_Rate",
            "TotalAmount",
          ];
        } else if (
          PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "Surgical"
        ) {
          requiredfields = [
            "Item_Name",
            "Generic_Name",
            "Pack_type",
            "Pack_Quantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "Purchase_Rate",
            "TotalAmount",
          ];
        } else {
          requiredfields = [
            "Item_Name",
            "Pack_type",
            "Pack_Quantity",
            "Purchase_Rate",
            "TotalAmount",
          ];
        }

        const missingFields = requiredfields.filter((field) =>
          PurchaseReturnForms.ProductCategory !== "MedicalConsumable" &&
            PurchaseReturnForms.ProductCategory !== "MedicalNonConsumable" &&
            PurchaseReturnForms.ProductCategory !== "NonMedicalConsumable" &&
            PurchaseReturnForms.ProductCategory !== "NonMedicalNonConsumable" &&
            PurchaseReturnForms.ProductCategory !== "Surgical"
            ? PurchaseRecieveFormData[field]
            : PurchaseRecieveFormData1[field]
        );

        if (missingFields.length === 0) {
          const updatedData =
            PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
              PurchaseReturnForms.ProductCategory === "MedicalNonConsumable" ||
              PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
              PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
              PurchaseReturnForms.ProductCategory === "Surgical"
              ? {
                id: datalist1[itemIndex].id,
                Item_Code: ItemCode,
                ...PurchaseRecieveFormData,
              }
              : {
                id: datalist1[itemIndex].id,
                Item_Code: ItemCode,
                ...PurchaseRecieveFormData1,
              };

          // Update the item in datalist1
          setdatalist1((prev) => [
            ...prev.slice(0, itemIndex),
            updatedData,
            ...prev.slice(itemIndex + 1),
          ]);
          alert("Item Updated Successfully");
          handleclear();
          setisedit(null);
        } else {
          alert(
            `Please fill out all required fields: ${missingFields.join(", ")}`
          );
        }
      } else {
        alert("Item not found ");
      }
    }
  };

  useEffect(() => {
    if (PurchaseReturnForms.ProductCategory) {
      console.log(PurchaseReturnForms.ProductCategory);
      axios
        .get(
          `${urllink}SupplierMaster/Get_GRN_invoice_by_search?ProductType=${PurchaseReturnForms.ProductCategory
          }&Invoice=${productInvoice}&location=${'CentralStore'}`
        )
        .then((response) => {
          const data = response.data;
          console.log(data);
          if (data) {
            setGRNinvoiceData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    productInvoice,
    productInvoice.length,
    PurchaseReturnForms.ProductCategory,
  ]);

  useEffect(() => {
    if (GRNinvoiceData.length === 1) {
      const newdata = GRNinvoiceData.find((p) => p.Invoice === productInvoice);
      if (newdata) {
        setproductInvoice(newdata?.Invoice);
        if (PurchaseReturnForms.ProductCategory) {
          axios
            .get(
              `${urllink}SupplierMaster/Get_GRN_invoice_Detials_by_search?ProductType=${PurchaseReturnForms.ProductCategory}&Invoice=${newdata.Invoice}&location=${'CentralStore'}`
            )
            .then((response) => {
              console.log(response);
              const data = response.data;

              const file = base64toFile(data.Bill_Document, data.DecoFile1name, data.DecoFile1type)

              setBillDoc(file)


              const { Items, ...rest } = data;

              setPurchaseReturnForms((prev) => ({
                ...prev,
                ...rest,
              }));

              setdatalist([...Items]);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        setPurchaseReturnForms((prev) => ({
          ...prev,
          ProductCategory: prev.ProductCategory,
          GRN_Invoice_NO: "",
          GRN_Date: "",
          SupplierCode: "",
          SupplierName: "",
          Supplier_Bill_No: "",
          Supplier_Bill_Date: "",
          Bill_Document: "",
          Total_Discount_Type: "",
          Total_Discount: "",
        }));
        setPurchaseRecieveFormData({
          Item_Name: "",
          Generic_Name: "",
          Strength: "",
          UOM: "",
          Pack_type: "",
          Pack_Quantity: "",
          HSNCode: "",
          BatchNo: "",
          ManufactureDate: "",
          ExpiryDate: "",
          Purchase_Rate: "",
          Return_Quantity: "",
          TotalAmount: "",
        });
        setPurchaseRecieveFormData1({
          Item_Name: "",
          Pack_type: "",
          Pack_Quantity: "",
          Purchase_Rate: "",
          Return_Quantity: "",
          TotalAmount: "",
        });
        setItemCode("");
      }
    }
  }, [
    GRNinvoiceData.length,
    GRNinvoiceData,
    productInvoice,
    productInvoice.length,
  ]);

  // --------------

  const handleVisibilityClick = async () => {
    if (billdoc === null) {
        alert("No Data");
    } else {
        console.log('billdoc', billdoc.type);

        if (billdoc.type !== "application/pdf") {
            const fileURL = URL.createObjectURL(billdoc);
            setModalContent(fileURL);
            setModalIsOpen(true);
        } else {
            // Convert the PDF file to a base64 string
            const base64String = await readFileAsBase64(billdoc);
            const Pdffile = `data:application/pdf;base64,${base64String}`;
            console.log('Pdffile', Pdffile);
            setModalContent(Pdffile);
            setModalIsOpen(true);
        }
    }
};
  
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };



  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Purchase Return</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Product Category<span>:</span>
            </label>
            <select
              onChange={(e) => {
                setPurchaseReturnForms({
                  ProductCategory: e.target.value,
                  GRN_Invoice_NO: "",
                  GRN_Date: "",
                  SupplierCode: "",
                  SupplierName: "",
                  Supplier_Bill_No: "",
                  Supplier_Bill_Date: "",
                  Bill_Document: "",
                  Total_Discount_Type: "",
                  Total_Discount: "",
                  TotalAmount: "",
                  Reason: "",
                  RoundOff:"",
                });
                setPurchaseRecieveFormData({
                  Item_Name: "",
                  Generic_Name: "",
                  Strength: "",
                  UOM: "",
                  Pack_type: "",
                  Pack_Quantity: "",
                  HSNCode: "",
                  BatchNo: "",
                  ManufactureDate: "",
                  ExpiryDate: "",
                  Purchase_Rate: "",
                  Return_Quantity: "",
                  TotalAmount: "",
                });

                setPurchaseRecieveFormData1({
                  Item_Name: "",
                  Pack_type: "",
                  Pack_Quantity: "",
                  Purchase_Rate: "",
                  Return_Quantity: "",
                  TotalAmount: "",
                });
                setGRNinvoiceData([]);
                setproductInvoice("");
                setItemCode("");
                setdatalist1([]);
              }}
              value={PurchaseReturnForms.ProductCategory || ""}
            >
              <option value="">Select</option>
              <option value="MedicalConsumable">Medical Consumable</option>
              {/* <option value="MedicalNonConsumable">
                Medical Non-Consumable
              </option> */}
              <option value="NonMedicalConsumable">
                Non MedicalConsumable
              </option>
              {/* <option value="NonMedicalNonConsumable">
                Non MedicalNon-Consumable
              </option> */}
              <option value="Surgical">Surgical</option>
              <option value="Assets">Assets</option>
              <option value="Stationary">Stationary</option>
              <option value="Non_Stationary">Non Stationary</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              GRN Invoice No <span>:</span>
            </label>
            <input
              id="GRN_Invoice_NO_id"
              list="GRN_Invoice_NO"
              value={productInvoice || ""}
              autoComplete="off"
              onChange={(e) => {
                setproductInvoice(e.target.value);

                if ((e.target.value = "")) {
                  setGRNinvoiceData([]);
                }
              }}
            />
            {GRNinvoiceData.length > 0 && (
              <datalist id="GRN_Invoice_NO">
                {GRNinvoiceData.map((item, index) => (
                  <option key={index} value={item.Invoice} />
                ))}
              </datalist>
            )}
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              GRN Date <span>:</span>
            </label>
            <input
              type="date"
              value={PurchaseReturnForms.GRN_Date || ""}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Supplier Code <span>:</span>
            </label>
            <input
              type="text"
              value={PurchaseReturnForms.SupplierCode || ""}
              readOnly
            />
          </div>


          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Supplier Name <span>:</span>
            </label>
            <input
              type="text"
              value={PurchaseReturnForms.SupplierName || ""}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Supplier Bill No <span>:</span>
            </label>
            <input
              type="text"
              value={PurchaseReturnForms.Supplier_Bill_No || ""}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Supplier Bill Date <span>:</span>
            </label>
            <input
              type="date"
              value={PurchaseReturnForms.Supplier_Bill_Date || ""}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Item_Name">
              Reason <span>:</span>
            </label>
            <select
              value={PurchaseReturnForms.Reason || ""}
              onChange={(e) => {
                setPurchaseReturnForms((prev) => ({
                  ...prev,
                  Reason: e.target.value,
                }));
              }}
            >
              <option value="">Select</option>
              {(PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
                PurchaseReturnForms.ProductCategory === "MedicalNonConsumable" ||
                PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
                PurchaseReturnForms.ProductCategory ===
                "NonMedicalNonConsumable" ||
                PurchaseReturnForms.ProductCategory === "Surgical") && (
                  <option value="Expired">Expired</option>
                )}
              <option value="Quality">Quality</option>
              <option value="Damage">Damage</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              RoundOff <span>:</span>
            </label>
            <input
              type="number"
              value={PurchaseReturnForms.RoundOff || ""}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="ProductType">
              Total Amount <span>:</span>
            </label>
            <input
              type="text"
              value={PurchaseReturnForms.TotalAmount || ""}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="patientPhoto">
              Bill Document<span>:</span>
            </label>
            <Button
              className="cell_btn"
              onClick={handleVisibilityClick}>

              <VisibilityIcon />
            </Button>
          </div>
        </div>


        <div className="Add_items_Purchase_Master">
          <span>Add Items</span>
        </div>

        {(PurchaseReturnForms.ProductCategory === "MedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "MedicalNonConsumable") && (
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Item Code <span>:</span>
                </label>
                <input
                  type="text"
                  id="GRN_ItemCodes"
                  list="GRN_ItemCode"

                  value={ItemCode || ""}
                  onChange={(e) => {
                    const value = e.target.value.split(",");
                    console.log(value);
                    console.log(value[1]);
                    setItemCode(value[0]);
                    const newdata = datalist.find(
                      (row) =>
                        value[0] === row.Item_Code && row.BatchNo === value[1]
                    );
                    console.log(newdata);
                    if (newdata) {
                      setPurchaseRecieveFormData((prev) => ({
                        ...prev,
                        ...newdata,
                      }));
                    } else {
                      setPurchaseRecieveFormData({
                        Item_Name: "",
                        Generic_Name: "",
                        Strength: "",
                        UOM: "",
                        Pack_type: "",
                        Pack_Quantity: "",
                        HSNCode: "",
                        BatchNo: "",
                        ManufactureDate: "",
                        ExpiryDate: "",
                        Purchase_Rate: "",
                        Return_Quantity: "",
                        TotalAmount: "",
                      });
                    }
                  }}
                />
                {datalist.length > 0 && (
                  <datalist id="GRN_ItemCode">
                    {console.log(datalist)}
                    {datalist.map((item, index) => (
                      <option
                        key={index}
                        value={item.Item_Code + "," + item.BatchNo || ""}
                      >
                        {`Batch No : ${item.BatchNo}`}
                      </option>
                    ))}
                  </datalist>
                )}
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Item Name <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.Item_Name || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Generic Name <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.Generic_Name || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Strength <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData.Strength || ""}
                  readOnly
                />
              </div>


              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  UOM <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.UOM || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="code">
                  Pack Type <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.Pack_type || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Pack Quantity <span>:</span>
                </label>
                <input
                  type="number"
                  value={PurchaseRecieveFormData.Pack_Quantity || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Batch No <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.BatchNo || ""}
                  readOnly
                />
              </div>


              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  HSN Code <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.HSNCode || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="code">
                  Manufacture Date <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.ManufactureDate || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Expiry Date <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.ExpiryDate || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Purchase Rate <span>:</span>
                </label>
                <input
                  type="number"
                  value={PurchaseRecieveFormData.Purchase_Rate || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Return Quantity<span>:</span>
                </label>
                <input
                  type="number"
                  value={PurchaseRecieveFormData.Return_Quantity || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPurchaseRecieveFormData((prev) => ({
                      ...prev,
                      Return_Quantity: value,
                      TotalAmount: (+value * +prev.Purchase_Rate).toFixed(3),
                    }));
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Total Amount <span>:</span>
                </label>
                <input
                  type="text"
                  value={PurchaseRecieveFormData.TotalAmount || ""}
                  readOnly
                />
              </div>
            </div>
          )}

        {(PurchaseReturnForms.ProductCategory === "NonMedicalConsumable" ||
          PurchaseReturnForms.ProductCategory === "NonMedicalNonConsumable" ||
          PurchaseReturnForms.ProductCategory === "Surgical") && (

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Item Code <span>:</span>
                </label>
                <input
                  type="text"
                  id="GRN_ItemCodes"
                  list="GRN_ItemCode"

                  value={ItemCode || ""}
                  onChange={(e) => {
                    const value = e.target.value.split(",");
                    console.log(value);
                    console.log(value[1]);
                    setItemCode(value[0]);
                    const newdata = datalist.find(
                      (row) =>
                        value[0] === row.Item_Code && row.BatchNo === value[1]
                    );
                    console.log(newdata);
                    if (newdata) {
                      setPurchaseRecieveFormData((prev) => ({
                        ...prev,
                        ...newdata,
                      }));
                    } else {
                      setPurchaseRecieveFormData({
                        Item_Name: "",
                        Generic_Name: "",
                        Strength: "",
                        UOM: "",
                        Pack_type: "",
                        Pack_Quantity: "",
                        HSNCode: "",
                        BatchNo: "",
                        ManufactureDate: "",
                        ExpiryDate: "",
                        Purchase_Rate: "",
                        Return_Quantity: "",
                        TotalAmount: "",
                      });
                    }
                  }}
                />
                {datalist.length > 0 && (
                  <datalist id="GRN_ItemCode">
                    {console.log(datalist)}
                    {datalist.map((item, index) => (
                      <option
                        key={index}
                        value={item.Item_Code + "," + item.BatchNo || ""}
                      >
                        {`Batch No : ${item.BatchNo}`}
                      </option>
                    ))}
                  </datalist>
                )}
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Item Name <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.Item_Name || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Generic Name <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.Generic_Name || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="code">
                  Pack Type <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.Pack_type || ""}
                  readOnly
                />
              </div>


              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Pack Quantity <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData.Pack_Quantity || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Batch No <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.BatchNo || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  HSN Code <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.HSNCode || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="code">
                  Manufacture Date <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.ManufactureDate || ""}
                  readOnly
                />
              </div>


              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Expiry Date <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.ExpiryDate || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Purchase Rate <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData.Purchase_Rate || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Return Quantity / Piece<span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData.Return_Quantity || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPurchaseRecieveFormData((prev) => ({
                      ...prev,
                      Return_Quantity: value,
                      TotalAmount: (+value * +prev.Purchase_Rate).toFixed(3),
                    }));
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Total Amount <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData.TotalAmount || ""}
                  readOnly
                />
              </div>

            </div>
          )}

        {PurchaseReturnForms.ProductCategory !== "MedicalConsumable" &&
          PurchaseReturnForms.ProductCategory !== "MedicalNonConsumable" &&
          PurchaseReturnForms.ProductCategory !== "NonMedicalConsumable" &&
          PurchaseReturnForms.ProductCategory !== "NonMedicalNonConsumable" &&
          PurchaseReturnForms.ProductCategory !== "Surgical" && (

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="ProductType">
                  Item Code <span>:</span>
                </label>
                <input
                  type="text"

                  list="GRN_ItemCode1"
                  value={PurchaseRecieveFormData1.Item_Code || ""}
                  onChange={(e) => {
                    setItemCode(e.target.value);
                    const newdata = datalist.find(
                      (row) => e.target.value === row.Item_Code
                    );
                    if (newdata) {
                      setPurchaseRecieveFormData1((prev) => ({
                        ...prev,
                        ...newdata,
                      }));
                    } else {
                      setPurchaseRecieveFormData1({
                        Item_Name: "",
                        Pack_type: "",
                        Pack_Quantity: "",
                        Purchase_Rate: "",
                        TotalAmount: "",
                      });
                    }
                  }}
                />
                {datalist.length > 0 && (
                  <datalist id="GRN_ItemCode1">
                    {console.log(datalist)}
                    {datalist.map((item, index) => (
                      <option key={index} value={item.Item_Code} />
                    ))}
                  </datalist>
                )}
              </div>

              <div className="RegisForm_1">
                <label htmlFor="code">
                  Item Name <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData1.Item_Name || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="code">
                  Pack Type <span>:</span>
                </label>
                <input
                  type="text"

                  value={PurchaseRecieveFormData1.Pack_type || ""}
                  readOnly
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Pack Quantity <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData1.Pack_Quantity || ""}
                  readOnly
                />
              </div>



              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Purchase Rate <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData1.Purchase_Rate || ""}
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Return Quantity <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData1.Return_Quantity || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    setPurchaseRecieveFormData1((prev) => ({
                      ...prev,
                      Return_Quantity: value,
                      TotalAmount: (+value * +prev.Purchase_Rate).toFixed(
                        3
                      ),
                    }));
                  }}
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="Item_Name">
                  Total Amount <span>:</span>
                </label>
                <input
                  type="number"

                  value={PurchaseRecieveFormData1.TotalAmount || ""}
                />
              </div>

            </div>
          )}

        <div className="Register_btn_con">
          <button
            className=" RegisterForm_1_btns"
            onClick={isedit ? handleUpdate : handleAdd}
          >
            {isedit ? "Update" : "Add"}
          </button>
        </div>
        {datalist1.length !== 0 && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={datalist1.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages1 - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}

        {datalist1.length !== 0 && (
          <div className="Register_btn_con">
            <button
              className=" RegisterForm_1_btns"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        )}

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { ...yourStyles } }}>
          <div className="pdf_img_show">
            {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
              <iframe
                title="PDF Viewer"
                src={modalContent}
                style={{
                  width: "100%",
                  height: "402px",
                  border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                }}
              />
            ) : (
              <img
                src={modalContent}
                alt="Concern Form"
                style={{
                  width: "80%",
                  height: "75%",
                  marginTop: "20px",
                }}
              />
            )}
            <div className="jhuhhjh">
              <Button
                style={{ color: "white" }}
                className="clse_pdf_img"
                onClick={closeModal}
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "40px",
                    backgroundColor: "#54d854bf",
                    borderRadius: "40px",
                  }}
                />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PurchaseReturnLocMaster;
