import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

const theme = createTheme({
  
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const DueHistoryList = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [summa, setsumma] = useState([]);
    const dispatchvalue = useDispatch();
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);


  const handleRequestEdit = (params) => {
    const Data = summa.find((p) => p.id === params.id);
   
    console.log("Data",Data)

    dispatchvalue({type: 'SelectedPateintDeuBilling',value:Data})

    navigate("/Home/Deu-History");
   
  };




  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  useEffect(() => {
    if(Object.keys(userRecord).length !==0){
    axios
      .get(
        `${urllink}Billing/get_General_billing_due_list?location=${userRecord.location}`
      )
      .then((response) => {
      
        const Datas = response.data;

        console.log("Daaa",Datas)
      
        setsumma(
          Datas.map((row,ind) => ({
            id: ind+1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [userRecord.location,urllink]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "id", headerName: "S_No", width: 200 },
    { field: "Invoice_NO", headerName: "Invoice NO", width: 200 },
    { field: "PatientID", headerName: "Patient Id", width: 200 },
    { field: "PatientName", headerName: "Patient Name", width: 200 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 150 },
    { field: "VisitId", headerName: "Visit Id", width: 150 },
    { field: "Billing_Date", headerName: "Billing Date", width: 150 },
    { field: "NetAmount", headerName: "Net Amount", width: 150 },
    { field: "PaidAmount", headerName: "Paid Amount", width: 150 },
    { field: "BalanceAmount", headerName: "Balance Amount", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ];





  return (
    <>
      
      <div className="appointment">
        <div className="h_head">
          <h4>Due Billing List</h4>
      
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Item Name"
            />
          </div>
          <button className="btn_1" type="submit">
            <SearchIcon />
          </button>
          
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns = {dynamicColumns }

                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DueHistoryList;
