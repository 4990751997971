import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import 'jspdf-autotable';

function EmployeeReport() {
  // const dispatchvalue = useDispatch();
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const totalPages = Math.ceil(filteredRows.length / pageSize);
  const paginatedData = Array.isArray(filteredRows) && filteredRows?.slice(page * pageSize, (page + 1) * pageSize);
  const [rows, setRows] = useState([]);
  const [dateQueries, setDateQueries] = useState({});
  const currentMonth = new Date().toISOString().slice(0, 7); // Get current month in YYYY-MM format


  const handledownloadreport = (employee, dateQuery) => {
    const currentMonth = new Date().toISOString().slice(0, 7); // Get current month in YYYY-MM format
    const newdate = dateQuery || currentMonth;

    axios.get(`${urllink}HRmanagement/get_employee_monthly_report?date=${newdate}&EmployeeID=${employee?.EmployeeID}`)
      .then((response) => {
        const data = response.data;
        console.log(data);

        // Create a new jsPDF instance with landscape orientation
        const doc = new jsPDF({ orientation: 'landscape' });
        doc.setFont('times', 'normal');
        const margin = 14;
        let currentY = 20;

        // Title
        doc.setFontSize(18);
        doc.text(`Employee Report of ${newdate}`, margin, currentY);
        currentY += 15;

        // Employee Personal Details
        doc.setFontSize(16);
        doc.text("Employee Personal Details", margin, currentY);
        currentY += 10;
        doc.setFontSize(12);
        const details = [
          `Employee ID    : ${employee.EmployeeID}`,
          `Employee Name  : ${employee.EmployeeName}`,
          `Designation    : ${employee.Designation}`,
          `Present Days    : ${data.present_days} Days`,
          `Salary    : ${data.paid_salary} /-`
        ];
        details.forEach((detail, index) => {
          doc.text(detail, margin, currentY + (index * 10));
        });
        currentY += (details.length * 10) + 10;

        // Advance Details Table
        doc.setFontSize(16);
        doc.text("Advance Details", margin, currentY);
        currentY += 10;

        if (data.advances?.length > 0) {
          doc.autoTable({
            startY: currentY,
            head: [
              ["Sl_No", "IssuedDate", "ApprovedAmount", "Due Months", "AmountDeductPerMonth", "Installment_Status", "No of Month Paid", "PaidAmount"]
            ],
            body: data.advances.map(item => [
              item.Sl_No, item.IssuedDate, item.RequestAmount, item.RepaymentDue, item.AmountDeductPerMonth, item.Installment_Status, item.No_of_MonthPaid, item.PaidAmount
            ]),
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [173, 216, 230], textColor: [0, 0, 0] },
          });
          currentY = doc.autoTable.previous.finalY + 10;
        }

        // Casual Leave Table
        doc.setFontSize(16);
        doc.text("Casual Leave Details", margin, currentY);
        currentY += 10;

        const casualLeaves = data.leaves.filter(item => item.LeaveType === "casual");
        if (casualLeaves.length > 0) {
          doc.autoTable({
            startY: currentY,
            head: [
              ["Sl_No", "LeaveType", "FromDate", "ToDate", "DaysCount", "Reason", "Status", "Reject_Reason"]
            ],
            body: casualLeaves.map(item => [
              item.Sl_No, item.LeaveType, item.FromDate, item.ToDate, item.DaysCount, item.Reason, item.LeaveStatus, item.Reject_Reason || '-'
            ]),
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [173, 216, 230], textColor: [0, 0, 0] },
          });
          currentY = doc.autoTable.previous.finalY + 10;
        }

        // Permission Leave Table
        doc.setFontSize(16);
        doc.text("Permission Leave Details", margin, currentY);
        currentY += 10;

        const permissionLeaves = data.leaves.filter(item => item.LeaveType === "permission");
        if (permissionLeaves.length > 0) {
          doc.autoTable({
            startY: currentY,
            head: [
              ["Sl_No", "LeaveType", "PermissionDate", "FromTime", "ToTime", "HoursCount", "Reason", "Status", "Reject_Reason"]
            ],
            body: permissionLeaves.map(item => [
              item.Sl_No, item.LeaveType, item.PermissionDate, item.FromTime, item.ToTime, item.HoursCount, item.Reason, item.LeaveStatus, item.Reject_Reason || '-'
            ]),
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [173, 216, 230], textColor: [0, 0, 0] },
          });
          currentY = doc.autoTable.previous.finalY + 10;
        }

        // Shift Details Table
        doc.setFontSize(16);
        doc.text("Shift Details", margin, currentY);
        currentY += 10;

        if (data.ShiftDetails?.length > 0) {
          doc.autoTable({
            startY: currentY,
            head: [
              ["Sl_No", "Shift StartDate", "Shift EndDate", "Shift StartTime", "Shift EndTime", "ShiftHours", "Status", "ShiftName"]
            ],
            body: data.ShiftDetails.map(item => [
              item.Sl_No, item.Shift_StartDate, item.Shift_EndDate, item.Shift_StartTime, item.Shift_EndTime, item.ShiftHours, item.Status, item.ShiftName
            ]),
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: [173, 216, 230], textColor: [0, 0, 0] },
          });
          currentY = doc.autoTable.previous.finalY + 10;
        }

        // Save PDF
        const blob = doc.output('blob');
        saveAs(blob, `Employee_Report_${newdate}.pdf`);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  };



  const handleSearchChange = (event) => {
    const { id, value } = event.target;
    if (id === "FirstName") {
      setSearchQuery(value);
    }
  };

  const handleDateChange = (event, employeeId) => {
    const { value } = event.target;
    setDateQueries((prev) => ({
      ...prev,
      [employeeId]: value
    }));
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/get_employee_personaldetails_forlist?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setRows(
          data.map((row) => ({
            id: row.EmployeeID,
            ...row
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, userRecord?.location]);

  useEffect(() => {
    const filteredData = Array.isArray(rows) && rows.filter((row) => {
      const lowerCaseEmployeeName = row.EmployeeName.toLowerCase();
      const matchesFirstName = lowerCaseEmployeeName.includes(searchQuery.toLowerCase());
      return matchesFirstName || !searchQuery;
    });

    setFilteredRows(filteredData);
    setPage(0);
  }, [searchQuery, rows]);

  return (
    <div className='appointment'>
      <div className='h_head'>
        <h4>Employee Monthly Report</h4>
      </div>
      <div className="con_1">
        <div className="inp_1">
          <label htmlFor="FirstName">
            Employee Name <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Enter the First Name"
          />
        </div>
      </div>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Employee Photo</th>
              <th>Employee Name</th>
              <th>Phone No</th>
              <th>Designation</th>
              <th>Date</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(paginatedData) && paginatedData.map((employee, index) => (
              <tr key={index}>
                <td>{employee.EmployeeID}</td>
                <td>
                  <img
                    src={employee.EmployeePhoto}
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "100px",
                    }}
                    alt={employee.EmployeeName}
                  />
                </td>
                <td>{employee.EmployeeName}</td>
                <td>{employee.PhoneNumber}</td>
                <td>{employee.Designation}</td>
                <td>
                  <div className="foremployeereportdonw">
                    <label htmlFor="firstName">
                      <span></span>
                    </label>
                    <input
                      type="month"
                      value={dateQueries[employee.EmployeeID] || currentMonth}
                      onChange={(e) => handleDateChange(e, employee.EmployeeID)}
                    />
                  </div>
                </td>
                <td>

                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handledownloadreport(employee, dateQueries[employee.EmployeeID])}
                    startIcon={<DownloadIcon />}
                  >
                    {/* Download */}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <div className="grid_foot">
          <button
            onClick={() =>
              setPage((prevPage) => Math.max(prevPage - 1, 0))
            }
            disabled={page === 0}
          >
            Previous
          </button>
          Page {page + 1} of {totalPages}
          <button
            onClick={() =>
              setPage((prevPage) =>
                Math.min(prevPage + 1, totalPages - 1)
              )
            }
            disabled={page === totalPages - 1}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default EmployeeReport;
