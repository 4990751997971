
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
function OrganismMaster() {
  const [organismData, setOrganismData] = useState([]);
  const [organismCode, setOrganismCode] = useState('');
  console.log(organismCode)
  const [organismName, setOrganismName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const urllink = useSelector(state => state.userRecord?.UrlLink)

  const handleSubmitOrganismMaster = async () => {
    if (!organismCode.trim() || !organismName.trim()) {
      alert(' Both OrganismCode And  OrganismName  are required.');
      return; // Exit the function early if validation fails
    }
    try {
      const response = await axios.post(`${urllink}usercontrol/insertorganismmaster`, {
        organismCode,
        organismName,
      });

      console.log(response.data);
      setOrganismCode('');
      setOrganismName('');
      fetchOrganismMasterData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchOrganismMasterData = () => {
    axios.get(`${urllink}usercontrol/getorganismmaster`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setOrganismData(data);
      })
      .catch((error) => {
        console.error('Error fetching organismmaster data:', error);
        setOrganismData([]); // Reset data in case of an error
      });
  };

  const handleEdit = (row) => {
    setOrganismCode(row.organism_code);
    setOrganismName(row.organism_name);
    setIsEditMode(true);
    setSelectedMethodId(row.organism_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(`${urllink}usercontrol/updateorganismmaster`, {
        method_id: selectedMethodId,
        method_name: organismCode,
        method_code: organismName,
      });

      console.log(response.data);
      setOrganismCode('');
      setOrganismName('');
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchOrganismMasterData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    fetchOrganismMasterData();
  }, []);

  return (
    <>
      <div className='ShiftClosing_over'>
        <div className="ShiftClosing_Container">
          <div className="ShiftClosing_header">
            <h3>Organism Master</h3>
          </div>
        </div>
        <div className="ShiftClosing_Container">
          <div className='FirstpartOFExpensesMaster'>
            <div className="con_1">
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Organism Code:</label>
                <input
                  type="text"
                  id="organismCode"
                  name="organismCode"
                  value={organismCode}
                  onChange={(e) => setOrganismCode(e.target.value)}
                  placeholder="Enter Organism Code"
                  style={{ width: "150px" }}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input" style={{ whiteSpace: "nowrap" }}>Organism Name:</label>
                <input
                  type="text"
                  id="organismName"
                  name="organismName"
                  value={organismName}
                  onChange={(e) => setOrganismName(e.target.value)}
                  placeholder="Enter Organism Name"
                  style={{ width: "150px" }}
                />
              </div>
              <button className="btn_1" onClick={isEditMode ? handleUpdateMethod : handleSubmitOrganismMaster}>
                {isEditMode ? 'Update' : <AddIcon />}
              </button>
            </div>

            <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
              <h4>Table</h4>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Organism Code</th>
                      <th>Organism Name</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(organismData) && organismData.length > 0 ? (
                      organismData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.organism_id}</td>
                          <td>{row.organism_code}</td>
                          <td>{row.organism_name}</td>
                          <td><button onClick={() => handleEdit(row)}><EditIcon /></button></td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganismMaster;

