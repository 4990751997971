import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import { Helmet } from 'react-helmet';
// import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
// import './Neww.css';
import Button from '@mui/material/Button';
import ForwadIcon from "@mui/icons-material/Forward";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

// import  faForward  from '@fortawesome/free-solid-svg-icons';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});


const Analyzereport= () => {
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [searchQuery2, setSearchQuery2] = useState('');
    const [searchQuery3, setSearchQuery3] = useState('');
    const [summa, setsumma] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedrow,setselectedrow]  = useState([])
    const pageSize = 10;
    const totalPages = Math.ceil(filteredRows.length / pageSize);
    const showdown = filteredRows.length;

  const urllink = useSelector(state => state.userRecord?.UrlLink)

    useEffect(() => {
      axios.get(`${urllink}Billing/getanalysislist?Location=${userRecord?.location}&Department=${userRecord?.Department}`)
          .then((response) => {
            console.log(response.data)
              const data = response.data.map((row,index) => ({
                  id: index, // Assuming you want to use Patient_Id as id
                  ...row,
              }));
              data.sort((a, b) => {
                const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ''), 10);
                const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ''), 10);
                return invoiceB - invoiceA;
      });
              setsumma(data);
          })
          .catch((error) => {
              console.error('Error fetching data:', error);
          });
  }, [urllink, userRecord.location, userRecord?.Department]);
  
    


  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const lowerCaseBarcodeQuery = searchQuery2.toLowerCase();
    const lowerCaseinvQuery = searchQuery3.toLowerCase();

    // Filter data based on all search queries
    const filteredData = summa.filter((row) => {
        const lowerCasePatientName = row.Patient_Name ? row.Patient_Name.toLowerCase() : '';
        const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : '';
        const lowerCaseBarcode = row.Barcode ? row.Barcode.toLowerCase() : '';
        const lowerCaseinvoiceno = row.Billing_Invoice ? row.Billing_Invoice.toLowerCase() : '';
        
        return (
            lowerCasePatientName.includes(lowerCaseNameQuery) &&
            lowerCasePhone.includes(lowerCasePhoneQuery) &&
            lowerCaseBarcode.includes(lowerCaseBarcodeQuery) &&
            lowerCaseinvoiceno.includes(lowerCaseinvQuery)

        );
    });

    setFilteredRows(filteredData);
}, [searchQuery, searchQuery1, searchQuery2,searchQuery3, summa]);


    
const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === 'name') {
        setSearchQuery(value);
    } else if (type === 'phone') {
        setSearchQuery1(value);
    }else if (type==='barcode'){
        setSearchQuery2(value)
    }else if(type === 'invoice'){
        setSearchQuery3(value)
}
};


    const dispatchvalue = useDispatch()
    
    const handleSampleCapture = (params) => {
        // Directly setting selectedrow to the new value
        setselectedrow(params.row);
    

 

        dispatchvalue({type: 'Capturesample', value: params.row});
    
        
        console.log(dispatchvalue);
        navigate('/Home/NavigationLabtechnician')
    };
    
    console.log('selectedrow', selectedrow);
    const dynamicColumns = [
        { field: 'Barcode', headerName: 'Sample Id', width: 150 },   
        { field: 'Patient_Id', headerName: 'Patient Id', width: 80 },
        { field: 'UpdatedAt', headerName: 'Date', width: 100 },
        { field: 'Billing_Invoice', headerName: 'Invoice', width: 100 },
        { field: 'Patient_Name', headerName: 'Patient Name', width: 130 },
        { field: 'Age', headerName: 'Age', width: 70 },
        {
            field: 'actions',
            headerName: 'Options',
            width: 150,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={()=>handleSampleCapture(params)}>
                        <ForwadIcon/>
                    </Button>
                </>
            ),
        },
        { field: 'Gender', headerName: 'Gender', width: 100 },
        // { field: 'Address', headerName: 'Address', width: 150 },
        { field: 'Phone', headerName: 'Phone', width: 130 },
        // { field: 'Patient_Group', headerName: 'Patient Group', width: 150 },
        { field: 'Refering_Doctor', headerName: 'Reference Doctor', width: 150 },
        // { field: 'Test_Name', headerName: 'Test Name', width: 150 },
        // { field: 'Test_value', headerName: 'Test Value', width: 150 },

        
       
    ];


    return (
        <>
            <div className="appointment">
                <div className="h_head">
                    <h3>Sample Queue List</h3>
                </div>
                <div className="con_1 ">
                <div className="inp_1">
            <label htmlFor="input">Barcode :</label>
            <input
                id="input"
                type="text"
                value={searchQuery2}
                onChange={(e) => handleSearchChange(e, 'barcode')}
                placeholder='Enter Barcode Number'
            />
        </div>
                    <div className="inp_1">
                        <label htmlFor="input">Patient Name :</label>
                        <input type="text" value={searchQuery} onChange={(e) => handleSearchChange(e, 'name')} placeholder='Enter the Patient Name' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Phone :</label>
                        <input type="text" value={searchQuery1} onChange={(e) => handleSearchChange(e, 'phone')} placeholder='Enter the Phone Number' />
                    </div>
                    <div className="inp_1">
                        <label htmlFor="input">Invoice No :</label>
                        <input type="text" value={searchQuery3} onChange={(e) => handleSearchChange(e, 'invoice')} placeholder='Enter Invoice Number' />
                    </div>
                  
                    
                </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Analyzereport;
