import React, { useState, useEffect, useCallback } from "react";
import "./Organization.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
function AgeSetUp() {
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [SelectedFile, setSelectedFile] = useState(null);
  const [ageData, setAgeData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [testname, setTestName] = useState();
  const [TestNameList, setTestNameList] = useState([])


  const [ageSetupData, setAgeSetupData] = useState({
    testname: '',
    gender: "",
    fromAge: "",
    ageType: "day",
    toAge: "",
    toAgeType: "day",
    fromvalue: "",
    tovalue: "",
    panicLow: "",
    panicHigh: "",
    referencerange: '',
    testcode: '',
  });

  const dispatchvale = useDispatch();

  dispatchvale({ type: "Agesetup", value: ageSetupData });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the input is for test name and find the corresponding test code
    if (name === 'testname') {
      const selectedTest = TestNameList.find(test => test.TestName === value);
      const testCode = selectedTest ? selectedTest.Test_Code : '';

      setAgeSetupData((prevData) => ({
        ...prevData,
        testname: value,
        testcode: testCode,
      }));
    } else {
      setAgeSetupData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === 'name') {
      setSearchQuery(value);
    }
  };

 

  const handleEdit = (row) => {
    setAgeSetupData({
      // method_id: selectedid,
      gender: row.Gender,
      fromAge: row.FromAge,
      ageType: row.FromAgeType,
      toAge: row.Toage,
      toAgeType: row.ToAgeType,
      fromvalue: row.From_Value,
      tovalue: row.To_Value,
      panicLow: row.PanicLow,
      panicHigh: row.PanicHigh,
      testname: row.TestName,
      referencerange: row.Reference
      // referencerange
    });
    console.log(row)
    setTestName(row.TestName);
    setIsEditMode(true);
    setSelectedid(row.S_No); // Assuming `id` is the identifier
  };



  const handleUpdateMethod = () => {
    const updatedata = {
      method_id: selectedid,
      gender: ageSetupData.gender,
      fromAge: ageSetupData.fromAge,
      ageType: ageSetupData.ageType,
      toAge: ageSetupData.toAge,
      toAgeType: ageSetupData.toAgeType,
      fromvalue: ageSetupData.fromvalue,
      tovalue: ageSetupData.tovalue,
      panicLow: ageSetupData.panicLow,
      panicHigh: ageSetupData.panicHigh,
      testname: testname,
      referencerange: ageSetupData.referencerange,
      Test_Code: ageSetupData.testcode,
    };

    axios
      .post(`${urllink}usercontrol/updateagesetup`, updatedata)
      .then((response) => {
        console.log(response.data);
        setIsEditMode(false);
        setSelectedid(null);
        setAgeSetupData({
          gender: "",
          fromAge: "",
          ageType: "day",
          toAge: "",
          toAgeType: "day",
          fromvalue: "",
          tovalue: "",
          panicLow: "",
          panicHigh: "",
        });
        setTestName("");
        fetchAgeSetUpData();
      })

      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const fetchAgeSetUpData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/get_agesetup_data`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setAgeData(data);
      })
      .catch((error) => {
        console.error("Error fetching agesetup data:", error);
      });
  },[urllink])

  // useEffect(() => {
  //   axios
  //     .get(`${urllink}usercontrol/gettestname`)
  //     .then((response) => {
  //       console.log(response.data);
  //       const data = response.data.test_name;
  //       const testcode = response.data.test_code
  //       setAgeSetupData(prev => ({
  //         ...prev,
  //         testname: data,
  //         testcode: testcode

  //       }))
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [urllink,]);


  useEffect(() => {
    axios.get(`${urllink}Billing/gettestnames`)
      .then((res) => {
        console.log('yrioouroiureoiriourei', res.data)
        setTestNameList(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [urllink])


  React.useEffect(() => {
    fetchAgeSetUpData();
  },[fetchAgeSetUpData]);


  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  useEffect(() => {
    if (Array.isArray(ageData)) {
      const lowerCaseNameQuery = searchQuery.toLowerCase();
      const filteredData = ageData.filter((row) => {
        const lowerCasePatientName = row?.TestName ? row.TestName.toLowerCase() : '';
        return lowerCasePatientName.includes(lowerCaseNameQuery);
      });
      setFilteredRows(filteredData);
    } else {
      // Handle the case where ageData is not an array
      setFilteredRows([]);
    }
  }, [searchQuery, ageData]);

  const handleSubmit = () => {
    // Basic validation check for mandatory fields
    console.log(ageSetupData)
    if (
      !ageSetupData.testname
      // ||
      // !ageSetupData.referencerange
    ) {
      console.log("Mandatory fields are missing");
      // Consider using a more user-friendly way to notify about the error, such as setting an error state and displaying it in the UI
      return;
    } else {
      const postData = {
        ...ageSetupData,
        // testname, // Ensure this matches the backend expected field name, adjust if necessary
      };
      console.log(postData)
      axios
        .post(`${urllink}usercontrol/insertagesetup`, postData)
        .then((response) => {
          console.log(response.data);
          successMsg(response.data.message);
          setAgeSetupData({
            gender: "",
            fromAge: "",
            ageType: "day",
            toAge: "",
            toAgeType: "day",
            fromvalue: "",
            tovalue: "",
            panicLow: "",
            panicHigh: "",
          });
          fetchAgeSetUpData();
        })
        .catch((error) => {
          errmsg(error)
          console.log(error);
        });
    }


  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Documents") {
        axios
          .post(
            `${urllink}usercontrol/insert_csv_file_for_referencerange`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            // errmsg(error);
          });
      }
    }
  };



  const totalPages = Math.ceil(ageData.length / pageSize);



  return (
    <>
      <div className="appointment">
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label className="" htmlFor="selectTest">
              Test Name<span>:</span>
            </label>
            <input
              type="text"
              id="testname"
              name="testname"
              list="testnamelist"
              placeholder="Enter Test Name"
              className=""
              required
              value={ageSetupData.testname}
              onChange={handleInputChange}
            />
            <datalist id="testnamelist">
              {TestNameList?.map((item, index) => (
                <option
                  key={index}
                  value={item.TestName}
                >
                  {item.TestName}
                </option>
              ))}
            </datalist>
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="selectTest">
              Test Code<span>:</span>
            </label>
            <input
              type="text"
              id="testcode"
              name="testcode"
              placeholder="Enter Test Name"
              className=""
              required
              value={ageSetupData.testcode}
              onChange={handleInputChange}
            />

          </div>


          <div className="RegisForm_1">
            <label htmlFor="referencerange">
              Reference Range <span>:</span>
            </label>
            <textarea
              id="referencerange"
              name="referencerange"
              value={ageSetupData.referencerange}
              onChange={handleInputChange}
              className="custom-textarea"
            ></textarea>
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="selectTest">
              Gender<span>:</span>
            </label>
            <select
              id="gender"
              name="gender"
              required
              className=""
              value={ageSetupData.gender}
              onChange={handleInputChange}
            >
              <option value="">Select</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Baby">Baby</option>
              <option value="Both">Both</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="fromAge">
              From Age<span>:</span>
            </label>
            <input
              type="number"
              id="fromAge"
              name="fromAge"
              placeholder="Enter Age"
              className=""
              required
              value={ageSetupData.fromAge}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="ageType">
              Type<span>:</span>
            </label>
            <select
              id="ageType"
              name="ageType"
              required
              className=""
              value={ageSetupData.ageType}
              onChange={handleInputChange}
            >
              <option value="day">Day(s)</option>
              <option value="month">Month(s)</option>
              <option value="year">Year(s)</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="toAge">
              To Age<span>:</span>
            </label>
            <input
              type="number"
              id="toAge"
              name="toAge"
              placeholder="Enter Age"
              className=""
              required
              value={ageSetupData.toAge}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="toAgeType">
              Type<span>:</span>
            </label>
            <select
              id="toAgeType"
              name="toAgeType"
              required
              className=""
              value={ageSetupData.toAgeType}
              onChange={handleInputChange}
            >
              <option value="day">Day(s)</option>
              <option value="month">Month(s)</option>
              <option value="year">Year(s)</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="panicLow">
              From Value<span>:</span>
            </label>
            <input
              type="text"
              id="fromvalue"
              name="fromvalue"
              placeholder="Enter From Value"
              className=""
              required
              value={ageSetupData.fromvalue}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="panicHigh">
              To Value<span>:</span>
            </label>
            <input
              type="text"
              id="tovalue"
              name="tovalue"
              placeholder="Enter To Value"
              className=""
              required
              value={ageSetupData.tovalue}
              onChange={handleInputChange}
            />
          </div>

          <div className="RegisForm_1">
            <label className="" htmlFor="panicLow">
              Panic Low<span>:</span>
            </label>
            <input
              type="text"
              id="panicLow"
              name="panicLow"
              placeholder="Enter Panic Low"
              className=""
              required
              value={ageSetupData.panicLow}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label className="" htmlFor="panicHigh">
              Panic High<span>:</span>
            </label>
            <input
              type="text"
              id="panicHigh"
              name="panicHigh"
              placeholder="Enter Panic High"
              className=""
              required
              value={ageSetupData.panicHigh}
              onChange={handleInputChange}
            />
          </div>
          <div className="RegisForm_1">
            <label>
              {" "}
              Upload CSV File <span>:</span>{" "}
            </label>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              id="Servicechoose"
              required
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label
              htmlFor="Servicechoose"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
            <button
              className="RegisterForm_1_btns choose_file_update"
              onClick={() => handleCsvupload("Documents")}
            >
              Upload
            </button>
          </div>

        </div>

        <div className="Register_btn_con">
          <button
            className="btn_1"
            onClick={isEditMode ? handleUpdateMethod : handleSubmit}
          >
            {isEditMode ? "Update" : <AddIcon />}
          </button>
        </div>

        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">Test Name :</label>
              <input type="text" value={searchQuery} onChange={(e) => handleSearchChange(e, 'name')} placeholder='Enter the Test Name' />
            </div>


          </div>
          {ageData.length > 0 ? (
            <div className="Selected-table-container">
              <table className="selected-medicine-table2">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Test Name</th>
                    <th>Gender</th>
                    <th>From Age</th>
                    <th>Age Type</th>
                    <th>To Age</th>
                    <th>Age Type</th>
                    <th>From Value</th>
                    <th>To Value</th>
                    <th>Panic Low</th>
                    <th>Panic High</th>
                    <th>Reference Range</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRows
                    .slice(page * pageSize, (page + 1) * pageSize)
                    .map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1 + page * pageSize}</td>
                        <td>{row.TestName}</td>
                        <td>{row.Gender}</td>
                        <td>{row.FromAge}</td>
                        <td>{row.FromAgeType}</td>
                        <td>{row.Toage}</td>
                        <td>{row.ToAgeType}</td>
                        <td>{row.From_Value}</td>
                        <td>{row.To_Value}</td>
                        <td>{row.PanicLow}</td>
                        <td>{row.PanicHigh}</td>
                        <td>{row.Reference}</td>
                        <td>
                          <button onClick={() => handleEdit(row)}>
                            <EditIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="IP_norecords">
              <p>No data available</p>
            </div>
          )}
        </div>

        {ageData.length > pageSize && (
          <div className="grid_foot">
            <button
              onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
              disabled={page === 0}
            >
              Previous
            </button>
            Page {page + 1} of {totalPages}
            <button
              onClick={() =>
                setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
              }
              disabled={page === totalPages - 1}
            >
              Next
            </button>
          </div>
        )}

        <ToastContainer />
      </div>
    </>
  );
}

export default AgeSetUp;
